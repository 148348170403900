.level-selectorTrigger
{
	position: absolute;
	right: 16px;
	top: 16px;
	width: 180px;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
	border-radius: 2px;
	align-items: center;
	font-size: 0.875em;
	cursor: pointer;
	pointer-events: all;
	overflow: hidden;
	user-select: none;                  /* prevent copy paste */

	transition: all 200ms;

	> .primary
	{
		padding: 8px 8px 8px 12px;
		display: flex;

		> .textLabel
		{
			white-space: nowrap;
			flex: 1;
			min-width: 0;  /* Allows widths to shrink less than text. see https://css-tricks.com/flexbox-truncated-text/ */

			> .-titletext
			{
				font-size: 1.143em;
				font-weight: 400;
				margin-bottom: 2px;
			}

			> div
			{
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 1.2;
			}
		}

		> .icon
		{
			width: 3em;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex: none;
			transition: transform 300ms;

			polygon
			{
				transition: all 200ms;
			}
		}
	}

	> .secondary
	{
		padding: 6px 12px;
		font-weight: 400;
	}

	&.-open
	{
		> .secondary
		{
			display: none;
		}
	}

	&.-open .icon
	{
		transform: rotate(90deg);
	}
}

.LocusLabs.-poiSelected .level-selectorTrigger
{
	@media (max-width: 414px)
	{
		display: none;
	}
}

.LocusLabs.-mobile .level-selectorTrigger_mobile
{
	/* midpoint = 8 + 25 = 33px from right edge */
	position: absolute;
	bottom: 16px;
	right: 8px;
	width: 50px;
	height: 50px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50px;

	> .icon
	{
		height: 100%;
	}
}

body.-LLfullpage .LocusLabs.-mobile
{
	.level-selectorTrigger_mobile
	{
		position: fixed;
	}
}
