.LocusLabs
{
	.loadingIndicatorWrapper
	{
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		font-size: 1px;
	}

	.spinner
	{
		height: 50px;
		width: 50px;
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.spinner:before,
	.spinner:after
	{
		content: "";
		display: block;
		border: solid currentColor;
		border-radius: 100%;
		position: absolute;
		box-sizing: border-box;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.spinner:before
	{
		border-bottom-color: transparent;
		border-width: 2em;
		animation: spin 2s infinite linear;
		height: 100%;
		width: 100%;
		margin: 0;
	}

	.spinner:after
	{
		border-right-color: transparent;
		border-width: 1em;
		animation: spin reverse 2s infinite linear;
		height: 75%;
		width: 75%;
		margin: 12.5%;
	}
}