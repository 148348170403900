/* This css is pre-processed via PostCSS - it mostly follows the "rscss" ideas detailed at http://rscss.io/index.html */

@import "./fonts.pcss";
@import "./colors.pcss";
@import "./nonThemeColorClasses.pcss";
@import "./themeClasses.pcss";
@import "./animations.pcss";

.LocusLabs *
{
	box-sizing: border-box;
}

/* this needs to be re-thunk based on how this is housed */
.LocusLabs,
#map,
.map-container,
.wholeMapOverlay
{
	width: 100%;
	height: 100%;
	font-size: 16px;
	overflow: hidden;
	min-height: 200px;
	text-rendering: geometricPrecision;
}

.wholeMapOverlay
{
	position: absolute;
	z-index: 2;
	pointer-events: none;

	.noMouse
	{
		pointer-events: none;
	}

	.centered
	{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -50px;
		margin-top: -50px;
	}

	.info
	{
		background: white;
		color: #333333;
		text-align: center;
		border: 1px solid gray;
		padding: 4px;
		bottom: 0;
		position: absolute;
		left: 0;
		pointer-events: all;

		> *
		{
			display: flex;

			> *
			{
				margin: 0 10px;
				width: 80px;

				&.-wider
				{
					width: 200px;
				}
			}
		}
	}
}

.LocusLabs input
{
	font-size: 1em;
}

/* This variant helps work on design */
.LocusLabs.-designMode
{
	div:nth-child(even)
	{
		outline: 1px dotted red;
	}

	div:nth-child(odd)
	{
		outline: 1px dotted blue;
	}
}

#map
{
	cursor: move;
}

.LocusLabs
{
	position: relative;
	font-family: Roboto, sans-serif;
	font-weight: 300;

	.-floatingContainer
	{
		position: absolute;
		z-index: 10;
		box-shadow: 0 0 1em rgba(0, 0, 0, 0.3);
		border-radius: 2px;

		&.-animateDown
		{
			transform: translateY(-50px);
			opacity: 0;
			transition: all 100ms;
			pointer-events: none;
		}

		&.-animateUp
		{
			transform: translateY(110px);
			opacity: 0;
			transition: all 100ms;
			pointer-events: none;
		}

		&.-show
		{
			transform: translateY(0);
			opacity: 1;
			pointer-events: all;
		}
	}

	/* The big dialog on the left side.  Used for search-container, poi-container, navigation-dialog, etc. */
	.-mainDialog
	{
		display: flex;
		flex-direction: column;
		width: 19.375em;
		max-height: calc(100% - 100px);
		left: 24px;
		top: 24px;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
		{
			max-height: none;
		} /* IE10+ */

		&.-extraWide
		{
			width: 376px;
		}
	}

	.-titletext
	{
		font-size: 1.125em;
		font-weight: 400;
	}

	.-rippleList > *
	{
		position: relative;
		overflow: hidden;
		transition: background 2s;
	}

	.-ripple
	{
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		pointer-events: none;
		animation: ripple-animation 1200ms forwards;
	}

	button
	{
		padding: initial;
		margin: initial;
		border: initial;
	}

	.button
	{
		display: flex;
		font-weight: 500;
		font-size: 0.875em;
		text-transform: uppercase;
		text-align: center;
		padding: 0.5em;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		-webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		height: 42px;

		> .icon
		{
			margin-right: 0.75em;
		}

		&.-full
		{
			width: 100%;
			padding: 1em;
		}
	}

	.liveDot
	{
		margin-right: 0.5em;
	}

	.liveDot.-roundLabelStarter
	{
		margin-left: -4px;
	}

	.liveDot.-inline
	{
		display: inline-block;
	}

	.liveDot circle:nth-child(1)
	{
		animation: pulse 2s infinite;
	}

	.liveDot circle:nth-child(2)
	{
		animation: pulse 2s infinite 1s;
	}

	@keyframes pulseCircle
	{
		0%
		{
			transform: scale(0.25);
			opacity: 1;
		}

		100%
		{
			transform: scale(1);
			opacity: 0.25;
		}
	}

	.dialog-mask
	{
		position: fixed;
		background: rgba(0, 0, 0, 0.3);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 800;
	}

	.dialog-box
	{
		position: fixed;
		top: 20%;
		left: 50%;
		margin-left: -200px;
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
		width: 400px;
		z-index: 900;
		padding: 1em;
		border-radius: 2px;

		&.-wide
		{
			width: 600px;
			margin-left: -300px;
		}
	}

	.dialog-close
	{
		position: absolute;
		right: -16px;
		top: -17px;
		padding: 0.75em 1em;
		font-size: 0.75em;
		border-radius: 99px;
		cursor: pointer;
	}

	._textOneLine
	{
		white-space: nowrap;
	}

	.-bm1em
	{
		margin-bottom: 1em;
	}

	.-bmp5em
	{
		margin-bottom: 0.5em;
	}
}

.LocusLabs.-mobile
{
	/* Full screen mobile - Top to bottom - scrolls if necessary */
	/* Note: You will need to set the background color if you wish to obscure content below it */
	.-mobileFull
	{
		position: absolute;
		top: 0 !important;
		left: 0 !important;
		width: 100% !important;
		height: 100% !important;
		max-height: none;
		overflow: auto;
		z-index: 10;
	}
}

body.-LLfullpage
{
	height: 100%;
	overflow: hidden;
	padding: 0;
	margin: 0;

	.locusmaps
	{
		height: 100%; /* can be overridden when header exists, etc. */
		width: 100%; /* generally not necessary, but when fixed, absolute, float, etc */
	}

	.LocusLabs.-mobile
	{
		position: fixed;
		top: 0;

		.-mobileFull
		{
			position: fixed;
		}
	}
}

/* This is here because the header element appears BEFORE the LocusLabs element */
@media screen and (max-width: 900px)
{
	body.-LLfullpage
	{
		header
		{
			visibility: hidden !important;
			height: 50px !important;
			border: none !important;
		}

		.locusmaps
		{
			height: calc(100% - 50px) !important;
		}
	}
}

