.notification-container
{
	position: absolute;
	z-index: 20;
	height: 30px;
	min-width: 240px;
	top: 1em;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	padding: 1.5rem 3em;
	text-align: center;
	pointer-events: none;
	text-transform: uppercase;
	font-weight: 900;
	align-items: center;

	.icon
	{
		display: inline-block;
		margin-right: 0.5em;
		vertical-align: baseline;
		flex: none;
	}
}

.LocusLabs.-mobile .notification-container
{
	position: absolute;
	top: 75px;
	bottom: auto;
}

