.LocusLabs
{
	/*
		This expresses specific classes used in the application which are tied to themes.
		Custom themes should NOT override these classes, but should override the theme
		names these refer to. The complete list is identified in colors.pcss
	*/

	&.text-primary                      { color: var(--primaryFG); } /* Used directly on LocusLabs div */

	.text-primary                      { color: var(--primaryFG); }
	.text-primary-button               { color: var(--primaryBG); }
	.text-primary-container            { color: var(--primaryBG); }
	.text-primary-expandable-list-item { color: var(--primaryBG); }
	.text-secondary                    { color: var(--primaryBG); }
	.text-secondary-button             { color: var(--primaryBG); }
	.text-alt                          { color: var(--primaryBG); }
	.text-info                         { color: var(--accent); }
	.text-accent                       { color: var(--accent); }
	.text-accent2                      { color: var(--accent2); }
	.text-accent-hover:hover           { color: var(--accent); }
	.text-muted                        { color: var(--muted); }
	.text-subdued                      { color: var(--primaryCopy); }
	.text-active-expandable-list-item  { color: var(--primaryBG); }
	.text-active-button                { color: var(--primaryBG); }
	.text-disabled-button              { color: var(--ghostedPrimary); }
	.text-for-emphasizedBG             { color: var(--emphasizedFG); }
	.text-important                    { color: var(--white); }

	/* To color the placeholder, give the input (or a parent of input) this class and then
		set the primary color via the text-* classes above. That will colorize the placeholder.
		Of course, it also effects the text of input, so if you want them different, you will need
		to change the text-* class based on text being entered */
	.placeholderCurrentColor
	{
		&::placeholder { color: currentColor }
		&::-webkit-input-placeholder { color: currentColor }
		&:-ms-input-placeholder { color: currentColor }
	}

	.stroke-primary                    { stroke: var(--mutedAccent); }
	.stroke-secondary                  { stroke: var(--accent); }
	.fill-primary                      { fill: var(--mutedAccent); }
	.fill-secondary                    { fill: var(--accent); }

	.bg-primary                        { background-color: var(--primaryBG); }
	.bg-primary-button                 { background-color: var(--muted); }
	.bg-primary-container              { background-color: var(--inverseBG); }
	.bg-primary-expandable-list-item   { background-color: var(--expandableListItemBG); }

	.bg-secondary                      { background-color: var(--primaryFG); }
	.bg-secondary-button               { background-color: var(--strongButtonBG); }
	.bg-secondary-container            { background-color: var(--primaryBG); }
	.bg-secondary-expandable-list-item { background-color: var(--primaryFG); }

	.bg-alt                            { background-color: var(--veryDarken); }
	.bg-alt-hover:hover                { background-color: var(--veryDarken); }
	.bg-info                           { background-color: var(--accent); }
	.bg-accent                         { background-color: var(--accent); }
	.bg-accent2                        { background-color: var(--accent2); }
	.bg-accent-hover:hover             { background-color: var(--accent); }
	.bg-ghosted                        { background-color: var(--ghostedPrimary); }
	.bg-faded                          { background-color: var(--faded); }
	.bg-faded-hover:hover              { background-color: var(--faded); }
	.bg-muted                          { background-color: var(--muted); }
	.bg-subdued                        { background-color: var(--primaryCopy); }
	.bg-active                         { background-color: var(--accent); }
	.bg-active-expandable-list-item    { background-color: var(--activeExpandableListItemBG); }
	.bg-active-button                  { background-color: var(--accent); }
	.bg-disabled                       { background-color: var(--faded); }
	.bg-disabled-button                { background-color: var(--faded); }
	.bg-mobile-header                  { background-color: var(--accent); }
	.bg-emphasized                     { background-color: var(--emphasizedBG); }
	.bg-important                      { background-color: var(--pureRed); }
	.bg-status								{ background-color: var(--pureGreen); }

	.bg-green 	{ background-color: var(--pureGreen) }
	.bg-gray 	{ background-color: var(--darkGray) }
	.bg-orange 	{ background-color: var(--pureOrange) }
	.bg-red 	{ background-color: var(--pureRed) }

	.border-primary                    { border-color: var(--faded) ; }
	.border-alt                        { border-color: var(--veryDarken) ; }
	.border-info                       { border-color: var(--accent) ; }
	.border-accent                     { border-color: var(--accent) ; }
	.border-accent2                    { border-color: var(--accent2) ; }
	.border-inverse                    { border-color: var(--faded) ; }
	.border-faded                      { border-color: var(--faded) ; }
	.border-ghosted                    { border-color: var(--ghostedPrimary) ; }
	.border-muted                      { border-color: var(--muted) ; }
	.border-active                     { border-color: var(--accent) ; }
	.border-disabled                   { border-color: var(--faded) ; }
	.border-important                  { border-color: var(--white); }

	/* TODO: We should not be encouraging others to adjust the border-X-width and border-X-style. Instead we should demonstrate using of setting border-X-color */
	.list-item {
				background-color: var(--primaryBG);
				border-bottom: 1px solid var(--faded);

				&:hover { background-color: var(--faded); }

				&.-current {
					background-color: var(--activeItem);
					border-left: 2px solid var(--accent);
				}
			}

	.divider {
		color: var(--divider);
		border: var(--divider);
	}

	.dir-pin-start {
		fill: var(--mapPinStart);
	}

	.dir-pin-end {
		fill: var(--mapPinEnd);
	}
}
