.bluedot-button
{
	right: 60px;
	bottom: 1em;
	position: absolute !important;
	height: auto;
	background: #475bb1;
	border-radius: 2px;
	color: white;
	font-weight: 200;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}