.bluedot-button{
	right:60px;
	bottom:1em;
	position:absolute !important;
	height:auto;
	background:#475bb1;
	border-radius:2px;
	color:white;
	font-weight:200;
	-webkit-box-shadow:1px 1px 5px rgba(0, 0, 0, 0.5);
	        box-shadow:1px 1px 5px rgba(0, 0, 0, 0.5);
}
.LocusLabs .level-selector{
	position:absolute;
	right:16px;
	top:85px;
	max-height:calc(100% - 110px - 24px);
	width:180px;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
	-webkit-backdrop-filter:blur(10px);
	        backdrop-filter:blur(10px);
	overflow:hidden;
} .LocusLabs .level-selector > .areaGroups{
		overflow-y:auto;
	} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper{
			display:block;
			border-bottom-width:1px;
			border-bottom-style:solid;
		} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .areaGroup{
				display:block;
				-ms-flex-align:center;
				    align-items:center;
				padding:8px 8px 8px 12px;
				width:100%;
				cursor:pointer;
			} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .areaGroup > .icon{
					margin-right:1em;
				} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .areaGroup > .textLabel{
					-ms-flex:1 1;
					    flex:1 1;
					min-width:0;
				} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .areaGroup > .textLabel > .name{
						font-weight:400;
						line-height:1.4;
					} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .areaGroup > .textLabel > div{
						line-height:1.2;
						overflow:hidden;
						-o-text-overflow:ellipsis;
						   text-overflow:ellipsis;
						white-space:nowrap;
					} @media screen and (max-width: 900px){ .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .areaGroup{
					display:-ms-flexbox;
					display:flex
			}
				} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper:last-child{
				border-bottom:none;
			} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .area{
				opacity:0.95;
				-ms-flex-align:center;
				    align-items:center;
				-ms-flex-pack:justify;
				    justify-content:space-between;
				padding:8px 8px 8px 12px;
				width:100%;
				display:none;
				cursor:pointer;
			} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .area > .textLabel > .name{
						font-weight:400;
						margin-bottom:2px;
					} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .area > .textLabel > .title{
						font-size:0.875em;
						font-weight:300;
					} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .area > .textLabel > div{
						line-height:1.2;
						overflow:hidden;
						-o-text-overflow:ellipsis;
						   text-overflow:ellipsis;
						white-space:nowrap;
					} @media screen and (max-width: 900px){ .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper > .area{
					display:-ms-flexbox !important;
					display:flex !important
			}
				} .LocusLabs .level-selector > .areaGroups > .areaGroupWrapper.-selected > .area{
			display:block;
		}

.LocusLabs.-mobile .level-selector{
	position:absolute;
	right:auto;
	left:0;
	top:auto;
	bottom:0;
	width:100%;
	max-height:100%;
	border-radius:0;
}

.LocusLabs.-mobile .level-selector > .mask-mobile{
		height:100vh;
		width:100vw;
		opacity:0.65;
		background-color:#333333;
		top:-100vh;
		bottom:0;
		left:0;
		right:0;
		position:absolute;
		z-index:1;
		margin:0;
	}

.LocusLabs.-mobile .level-selector > .closeMobile{
		padding:5.5px;
	}

.LocusLabs.-mobile .level-selector > .areaGroups > .areaGroupWrapper > .area,
	.LocusLabs.-mobile .level-selector > .areaGroups > .areaGroupWrapper > .areaGroup{
		height:62px;
	}

.LocusLabs.-mobile .level-selector > .areaGroups > .areaGroupWrapper > .area > .textLabel > .name{
		line-height:1.286;
	}

body.-LLfullpage .LocusLabs.-mobile .level-selector{
		position:fixed;
	}

body.-LLfullpage .LocusLabs.-mobile .level-selector > .mask-mobile{
			position:fixed;
		}


.LocusLabs .icon{
		text-align:center;
		line-height:0;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-align:center;
		    align-items:center;
		-ms-flex-pack:distribute;
		    justify-content:space-around;
	}

		.LocusLabs .icon.inline{
			height:1.5em;
		}

		.LocusLabs .icon.inline > svg{
				height:100%;
			}
.round-label{
	border-radius:100px;
	font-size:0.875em;
	font-weight:600;
	text-align:center;
	padding:6px 12px;
	position:relative;
	display:-ms-inline-flexbox;
	display:inline-flex;
	margin-top:4px;
	text-transform:uppercase;
}

.level-selectorTrigger{
	position:absolute;
	right:16px;
	top:16px;
	width:180px;
	-webkit-box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	        box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	border-radius:2px;
	-ms-flex-align:center;
	    align-items:center;
	font-size:0.875em;
	cursor:pointer;
	pointer-events:all;
	overflow:hidden;
	-webkit-user-select:none;
	   -moz-user-select:none;
	    -ms-user-select:none;
	        user-select:none;

	-webkit-transition:all 200ms;

	-o-transition:all 200ms;

	transition:all 200ms;
}

	.level-selectorTrigger > .primary{
		padding:8px 8px 8px 12px;
		display:-ms-flexbox;
		display:flex;
	}

	.level-selectorTrigger > .primary > .textLabel{
			white-space:nowrap;
			-ms-flex:1 1;
			    flex:1 1;
			min-width:0;
		}

	.level-selectorTrigger > .primary > .textLabel > .-titletext{
				font-size:1.143em;
				font-weight:400;
				margin-bottom:2px;
			}

	.level-selectorTrigger > .primary > .textLabel > div{
				overflow:hidden;
				-o-text-overflow:ellipsis;
				   text-overflow:ellipsis;
				line-height:1.2;
			}

	.level-selectorTrigger > .primary > .icon{
			width:3em;
			display:-ms-flexbox;
			display:flex;
			-ms-flex-align:center;
			    align-items:center;
			-ms-flex-pack:distribute;
			    justify-content:space-around;
			-ms-flex:none;
			    flex:none;
			-webkit-transition:-webkit-transform 300ms;
			transition:-webkit-transform 300ms;
			-o-transition:transform 300ms;
			transition:transform 300ms;
			transition:transform 300ms, -webkit-transform 300ms;
		}

	.level-selectorTrigger > .primary > .icon polygon{
				-webkit-transition:all 200ms;
				-o-transition:all 200ms;
				transition:all 200ms;
			}

	.level-selectorTrigger > .secondary{
		padding:6px 12px;
		font-weight:400;
	}

	.level-selectorTrigger.-open > .secondary{
			display:none;
		}

	.level-selectorTrigger.-open .icon{
		-webkit-transform:rotate(90deg);
		        transform:rotate(90deg);
	}

@media (max-width: 414px){

.LocusLabs.-poiSelected .level-selectorTrigger{
		display:none
}
	}

.LocusLabs.-mobile .level-selectorTrigger_mobile{
	position:absolute;
	bottom:16px;
	right:8px;
	width:50px;
	height:50px;
	-webkit-box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.3);
	        box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.3);
	border-radius:50px;
}

.LocusLabs.-mobile .level-selectorTrigger_mobile > .icon{
		height:100%;
	}

body.-LLfullpage .LocusLabs.-mobile .level-selectorTrigger_mobile{
		position:fixed;
	}


.zoom-selectorContainer{
	height:40px;
	border-radius:2px;
	position:absolute;
	bottom:2em;
	left:calc(50% - 258px / 2);
	display:-ms-inline-flexbox;
	display:inline-flex;
	z-index:10;
	-webkit-box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	        box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
}

	.zoom-selectorContainer > .icon{
		-ms-flex:none;
		    flex:none;
		width:47px;
	}

	.zoom-selectorContainer .zoom-selector{
		height:40px;
		display:-ms-inline-flexbox;
		display:inline-flex;
		width:164px;
	}

	.zoom-selectorContainer .zoom-selector input{
			-webkit-appearance:none;
			height:4px;
			width:100%;
			margin:auto;
		}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){

	.zoom-selectorContainer .zoom-selector input{
				width:110px;
				padding:10px;
				height:40px
		}
			}

	.zoom-selectorContainer .zoom-selector input:focus{
			outline:none;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-webkit-slider-thumb{
			border:none;
			height:16px;
			width:16px;
			margin-top:-5.333px;
			border-radius:100%;
			background:#333333;
			-webkit-appearance:none;
		}

	.zoom-selectorContainer .zoom-selector input::-webkit-slider-runnable-track{
			border-radius:0;
			height:4px;
			cursor:pointer;
			background:#cccccc;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-moz-range-thumb{
			border:none;
			height:16px;
			width:16px;
			margin-top:-5.333px;
			border-radius:100%;
			background:#333333;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-moz-range-track{
			border-radius:0;
			height:4px;
			cursor:pointer;
			background:#cccccc;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-moz-focus-outer{
			border:0;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-ms-thumb{
			border:none;
			height:16px;
			width:16px;
			margin-top:0;
			border-radius:100%;
			background:#333333;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-ms-track{
			border-radius:0;
			height:4px;
			cursor:pointer;
			width:300px;
			border:0;
			background:transparent;
			color:transparent;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-ms-fill-lower{
			background:#999999;
			border-radius:10px;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-ms-fill-upper{
			background:#999999;
			border-radius:10px;
		}

	.zoom-selectorContainer .zoom-selector input[type=range]::-ms-tooltip{
			display:none;
		}

	.zoom-selectorContainer .zoom-selector > .icon{
			margin:4px 2px 0;
			cursor:pointer;
			-ms-flex:none;
			    flex:none;
		}

.LocusLabs .zoom-related-mobile-icons > .reposition{
		width:50px;
		height:50px;
		right:8px;
		position:absolute;
		bottom:80px;
		border-radius:99px;
		-webkit-box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.3);
		        box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.3);
	}

.LocusLabs .zoom-related-mobile-icons > .reposition > svg{
			width:24px;
			height:24px;
			margin:auto;
		}

.LocusLabs .zoom-related-mobile-icons > .control-icon-compassMobile{
		position:absolute;
		bottom:16px;
		left:8px;
		-webkit-box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.3);
		        box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.3);
		border-radius:15px;
	}

.move-icon-up{
	bottom:8em;
}

.LocusLabs .control-icon-compass{
	height:47px;
	width:47px;
	margin:0;
	top:-3px;
	position:relative;
}

body.-LLfullpage .LocusLabs .zoom-related-mobile-icons > .reposition,
	body.-LLfullpage .LocusLabs .zoom-related-mobile-icons > .control-icon-compassMobile{
		position:fixed;
	}
.LocusLabs .loadingIndicatorWrapper{
		height:100%;
		width:100%;
		position:absolute;
		top:0;
		font-size:1px;
	}
	.LocusLabs .spinner{
		height:50px;
		width:50px;
		position:absolute;
		margin:auto;
		top:0;
		bottom:0;
		left:0;
		right:0;
	}
	.LocusLabs .spinner:before,
	.LocusLabs .spinner:after{
		content:"";
		display:block;
		border:solid currentColor;
		border-radius:100%;
		position:absolute;
		-webkit-box-sizing:border-box;
		        box-sizing:border-box;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}
	.LocusLabs .spinner:before{
		border-bottom-color:transparent;
		border-width:2em;
		-webkit-animation:spin 2s infinite linear;
		        animation:spin 2s infinite linear;
		height:100%;
		width:100%;
		margin:0;
	}
	.LocusLabs .spinner:after{
		border-right-color:transparent;
		border-width:1em;
		-webkit-animation:spin reverse 2s infinite linear;
		        animation:spin reverse 2s infinite linear;
		height:75%;
		width:75%;
		margin:12.5%;
	}
.context-menu{
	position:absolute;
	width:210px;
	z-index:100;
	-webkit-box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	        box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	border-radius:2px;
}

	.context-menu > .menuItem{
		display:-ms-flexbox;
		display:flex;
		font-size:0.875em;
		padding:1em;
		-ms-flex-align:center;
		    align-items:center;
	}

	.context-menu > .menuItem > .icon{
			padding:0 1em 0 0;
		}

	.context-menu > .menuItem:first-child{
			border-radius:2px;
		}

	.context-menu > .menuItem:last-child{
			border-radius:2px;
			border:none;
		}

	.context-menu.-zoombarTrigger{
		left:calc(50% - 200px);
		bottom:calc(2em + 40px + 12px);
	}

	.context-menu.-zoombarTrigger::after{
			position:absolute;
			content:"";
			bottom:-4px;
			left:80px;
			width:30px;
			height:30px;
			background:white;
			z-index:-10;
			-webkit-transform:rotate(45deg);
			        transform:rotate(45deg);
		}

	.context-menu.-mobileSubmenu{
		position:absolute;
		top:50px;
		right:0;
	}

	.context-menu.-mobileSubmenu::after{
			position:absolute;
			content:"";
			top:-4px;
			right:8px;
			width:30px;
			height:30px;
			background:white;
			z-index:-10;
			-webkit-transform:rotate(45deg);
			        transform:rotate(45deg);
		}

	.context-menu .copyMenuFooter{
		font-size:0.6875em;
		text-align:center;
		padding:0.5em;
		border-radius:0 0 2px 2px;
	}

.LocusLabs .fullMask{
	z-index:90;
	outline:red;
	position:fixed;
	left:0;
	width:100%;
	top:0;
	height:100vh;
}

.LocusLabs.-mobile .context-menu > .menuItem:nth-last-of-type(2){
				border:none;
			}

.LocusLabs.-mobile .context-menu > .menuItem:last-child{
				border-radius:0;
			}
.navigation-segments{
	-ms-flex:1 1 auto;
	    flex:1 1 auto;
	overflow:auto;
}

	.navigation-segments > .segment{
		padding:0.875em;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-align:center;
		    align-items:center;
		cursor:pointer;
	}

	.navigation-segments > .segment:last-child{
			border:none;
		}

	.navigation-segments > .segment.-current > .count{
				opacity:1;
				-webkit-transform:scale(1);
				        transform:scale(1);
			}

	.navigation-segments > .segment > .icon{
			width:30px;
		}

	.navigation-segments > .segment > .textLabel{
			-ms-flex:1 1;
			    flex:1 1;
			margin-left:1.14em;
			margin-right:0.5em;
		}

	.navigation-segments > .segment > .textLabel > .secondaryText{
				font-size:0.875em;
			}

	.navigation-segments > .segment > .textLabel > .waypoint{
				font-weight:500;
			}

	.navigation-segments > .segment > .count{
			opacity:0;
			-webkit-transform:scale(0.5);
			        transform:scale(0.5);
			font-size:0.875em;
			-webkit-transition:-webkit-transform 140ms;
			transition:-webkit-transform 140ms;
			-o-transition:transform 140ms;
			transition:transform 140ms;
			transition:transform 140ms, -webkit-transform 140ms;
		}

.security-time{
	font-size:11px;
	font-weight:400;
	text-align:center;
	color:#ffffff;
	border-radius:100px;
	background-color:#ea3232;
	display:inline-block;
	padding:1px 8px;
	margin-right:5px;
	text-transform:uppercase;
}

.LocusLabs.-mobile .count{
		font-weight:bold;
		padding:3px;
	}
.LocusLabs .tooltip{
		font-size:12px;
		text-align:center;
		opacity:0.85;
		border-radius:2px;
		color:rgb(255, 255, 255);
		background-color:rgb(0, 0, 0);
		padding:7px 10px;
	}

.LocusLabs .minimizeButton{
		color:white;
		width:60px;
		height:23px;
		padding:0.5em;
		border-radius:0 0 2px 2px;
		-webkit-box-shadow:0 3px 12px 0 rgba(0, 0, 0, 0.5);
		        box-shadow:0 3px 12px 0 rgba(0, 0, 0, 0.5);
		position:absolute;
		left:calc(100% / 2 - 30px);
		bottom:-23px;
	}

		.LocusLabs .minimizeButton > .icon{
			margin:0;
		}

.LocusLabs.-mobile .minimizeButton{
		display:none;
	}

.keyboard{
	width:100%;
	max-width:1030px;
	margin:0 auto;
	background:#dadada;
	-webkit-box-shadow:0 2px 12px 0 rgba(0,0,0,0.5);
	        box-shadow:0 2px 12px 0 rgba(0,0,0,0.5);
}

.keyboard-wrapper{
	position:fixed;
	bottom:0;
	z-index:10000;
	width:700px;
	left:calc(50% - 350px);
}

.keyboard-wrapper .keyboard-row{
		display:-ms-flexbox;
		display:flex;
	}

.keyboard-button{
	display:-ms-flexbox;
	display:flex;
	-ms-flex-pack:distribute;
	    justify-content:space-around;
	-ms-flex-align:center;
	    align-items:center;
	-ms-flex-preferred-size:100px;
	    flex-basis:100px;
	font-size:18px;
	height:60px !important;
	border-radius:4px;
	background-color:#F5F5F5 !important;
	border:1px solid #CECECE !important;
	font-family:'Roboto', sans-serif;
	font-weight:300;
}

.keyboard-button:focus{
		outline:none;
	}

.keyboard-button:disabled{
		opacity:0.4;
		cursor:default;
	}

.keyboard-button:disabled{
		opacity:0.4;
		cursor:default;
	}

.keyboard-button:active{
		background-color:#cccccc;
	}

.keyboard-button.shift-symbols{
		-ms-flex-preferred-size:210px;
		    flex-basis:210px;
	}

.keyboard-button.keyboard-numberButton{
		-ms-flex-positive:1;
		    flex-grow:1;
	}

.keyboard-button.keyboard-halfButton{
		-ms-flex-preferred-size:56px;
		    flex-basis:56px;
	}

.keyboard-button.keyboard-space{
		-ms-flex-positive:1;
		    flex-grow:1;
	}

.keyboard-button.keyboard-utilButton{
		-ms-flex-positive:2;
		    flex-grow:2;
		max-width:55px;
	}

.keyboard-button.keyboard-additionalButton{
		-ms-flex-preferred-size:128px;
		    flex-basis:128px;
	}

.keyboard-button.keyboard-submitButton, .keyboard-button.keyboard-submit-button{
		-ms-flex-preferred-size:95px;
		    flex-basis:95px;
		background-color:#63b324 !important;
		border-color:#63b324 !important;
		color:#ffffff !important;
	}

.LocusLabs .flight-status-container .flightsList{
		background-color:#ebebeb;
	}
	.LocusLabs .flight-status-container .separator-box{
		position:relative;
		height:16px;
		margin:15px 0;
	}
	.LocusLabs .flight-status-container .separator-box::before{
		content:"";
		position:absolute;
		z-index:1;
		top:50%;
		left:0;
		right:0;
		height:2px;
		background:#f5f5f5;
	}
	.LocusLabs .flight-status-container .statusFlight{
		font-size:11px;
		font-weight:600;
		background:#008000;
		border-radius:20px;
		padding:2px 7px;
		color:#ffffff;
		display:inline-block;
		text-align:center;
		text-transform:uppercase;
	}
	.LocusLabs .flight-status-container .statusFlight.-delayed, .LocusLabs .flight-status-container .statusFlight.-early{
			background:#bc6300;
		}
	.LocusLabs .flight-status-container .statusFlight.-cancelled{
			background:#dc2a2a;
		}
	.LocusLabs .flight-status-container .statusFlight.-departed, .LocusLabs .flight-status-container .statusFlight.-arrived, .LocusLabs .flight-status-container .statusFlight.-unknown{
			background:#666666;
		}
	.LocusLabs .flight-status-container .hourDeparture{
		font-size:16px;
		color:#333333;
		font-weight:normal;
	}
	.LocusLabs .flight-status-container .scrolling-container{
		overflow:auto;
		width:inherit;
		border-radius:2px;
	}
	.LocusLabs .flight-status-container .search-entry .inner .no-border{
		border-right-width:0px;
	}
	.LocusLabs .flight-status-container .getDirections{
		margin-right:8px;
	}
	.LocusLabs .flight-status-container .delayed{
		color:#f04a40;
	}
	.LocusLabs .flight-status-container .Xcanceled{
		opacity:0.2;
	}
	.LocusLabs .flight-status-container .hidden-block{
		display:none;
	}
	.LocusLabs .flight-status-container .flight-item .line-through time{
		text-decoration:line-through;
		color:#aaa;
		font-size:16px;
		font-weight:normal;
	}
	.LocusLabs .flight-status-container .line-through time{
		text-decoration:line-through;
		font-weight:normal;
	}
	.LocusLabs .flight-status-container .bagclaimnum{
		font-size:12px;
		font-weight:400;
		margin-top:6px;
	}
	.LocusLabs .flight-status-container .bagclaimnum .icon{
			float:left;
			-webkit-transform:scale(0.65);
			        transform:scale(0.65);
			position:relative;
			top:-8px;
		}
	@-webkit-keyframes spin{
		from{
			-webkit-transform:rotate(0deg);
			        transform:rotate(0deg);
		}

		to{
			-webkit-transform:rotate(360deg);
			        transform:rotate(360deg);
		}
	}
	@keyframes spin{
		from{
			-webkit-transform:rotate(0deg);
			        transform:rotate(0deg);
		}

		to{
			-webkit-transform:rotate(360deg);
			        transform:rotate(360deg);
		}
	}
	@-webkit-keyframes counterspin{
		from{
			-webkit-transform:rotate(0deg) scale(0.75);
			        transform:rotate(0deg) scale(0.75);
		}

		to{
			-webkit-transform:rotate(-360deg) scale(0.75);
			        transform:rotate(-360deg) scale(0.75);
		}
	}
	@keyframes counterspin{
		from{
			-webkit-transform:rotate(0deg) scale(0.75);
			        transform:rotate(0deg) scale(0.75);
		}

		to{
			-webkit-transform:rotate(-360deg) scale(0.75);
			        transform:rotate(-360deg) scale(0.75);
		}
	}
	.LocusLabs .flight-status-container .placeholder{
		height:124px;
		position:relative;
	}
	.LocusLabs .flight-status-container .placeholder:before,
		.LocusLabs .flight-status-container .placeholder:after{
			content:"";
			display:block;
			width:30px;
			height:30px;
			border:2px solid #4c6389;
			border-radius:100%;
			position:absolute;
			left:calc(50% - 15px);
			top:47px;
		}
	.LocusLabs .flight-status-container .placeholder:before{
			border-bottom-color:transparent;
			-webkit-animation:spin 2s infinite linear;
			        animation:spin 2s infinite linear;
		}
	.LocusLabs .flight-status-container .placeholder:after{
			border-right-color:transparent;
			border-width:1px;
			-webkit-animation:counterspin 2s infinite linear;
			        animation:counterspin 2s infinite linear;
		}
	.LocusLabs .flight-status-container .details-content .placeholder{
		height:262px;
		position:relative;
	}
	.LocusLabs .flight-status-container .details-content .placeholder:before,
		.LocusLabs .flight-status-container .details-content .placeholder:after{
			content:"";
			display:block;
			width:30px;
			height:30px;
			border:2px solid #4c6389;
			border-radius:100%;
			position:absolute;
			left:calc(50% - 15px);
			top:116px;
		}
	.LocusLabs .flight-status-container .details-content .placeholder:before{
			border-bottom-color:transparent;
			-webkit-animation:spin 2s infinite linear;
			        animation:spin 2s infinite linear;
		}
	.LocusLabs .flight-status-container .details-content .placeholder:after{
			border-right-color:transparent;
			border-width:1px;
			-webkit-animation:counterspin 2s infinite linear;
			        animation:counterspin 2s infinite linear;
		}
	.LocusLabs .flight-status-container .container-height{
		-webkit-transition:max-height 0.5s;
		-o-transition:max-height 0.5s;
		transition:max-height 0.5s;
		max-height:100vh;
	}
	.LocusLabs .flight-status-container .hidden-container{
		max-height:0px;
	}
	.LocusLabs .flight-status-container .LocusLabs.-mobile .mobile-flight-status{
		position:fixed;
		top:0 !important;
		left:0 !important;
		width:100% !important;
		min-width:100% !important;
		height:100% !important;
		max-height:none;
		overflow:auto;
		z-index:10;
	}
	.LocusLabs .flight-status-container .button-pointer{
		cursor:pointer;
	}
	.LocusLabs .flight-status-container .top-links,
	.LocusLabs .flight-status-container .flight-meta{
		margin:0;
		padding:0;
		list-style:none;
	}
	.LocusLabs .flight-status-container .clearfix:after{
		content:"";
		display:block;
		clear:both;
	}
	.LocusLabs .flight-status-container .ellipsis{
		white-space:nowrap;
		-o-text-overflow:ellipsis;
		   text-overflow:ellipsis;
		overflow:hidden;
	}
	.LocusLabs .flight-status-container html{
		-webkit-box-sizing:border-box;
		        box-sizing:border-box;
	}
	.LocusLabs .flight-status-container *,
	.LocusLabs .flight-status-container *:before,
	.LocusLabs .flight-status-container *:after{
		-webkit-box-sizing:inherit;
		        box-sizing:inherit;
	}
	.LocusLabs .flight-status-container *{
		max-height:1000000px;
	}
	.LocusLabs .flight-status-container body{
		color:#000000;
		background:#ffffff;
		font:16px/1.2 "Roboto", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
		min-width:320px;
		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing:grayscale;
	}
	.LocusLabs .flight-status-container img{
		max-width:100%;
		height:auto;
	}
	.LocusLabs .flight-status-container .gm-style img{
		max-width:none;
	}
	.LocusLabs .flight-status-container h1,
	.LocusLabs .flight-status-container .h1,
	.LocusLabs .flight-status-container h2,
	.LocusLabs .flight-status-container .h2,
	.LocusLabs .flight-status-container h3,
	.LocusLabs .flight-status-container .h3,
	.LocusLabs .flight-status-container h4,
	.LocusLabs .flight-status-container .h4,
	.LocusLabs .flight-status-container h5,
	.LocusLabs .flight-status-container .h5,
	.LocusLabs .flight-status-container h6,
	.LocusLabs .flight-status-container .h6,
	.LocusLabs .flight-status-container .h{
		font-family:inherit;
		font-weight:bold;
		margin:0 0 0.5em;
		color:inherit;
	}
	.LocusLabs .flight-status-container h1,
	.LocusLabs .flight-status-container .h1{
		font-size:30px;
	}
	.LocusLabs .flight-status-container h2,
	.LocusLabs .flight-status-container .h2{
		font-size:27px;
	}
	.LocusLabs .flight-status-container h3,
	.LocusLabs .flight-status-container .h3{
		font-size:24px;
	}
	.LocusLabs .flight-status-container h4,
	.LocusLabs .flight-status-container .h4{
		font-size:21px;
	}
	.LocusLabs .flight-status-container h5,
	.LocusLabs .flight-status-container .h5{
		font-size:17px;
	}
	.LocusLabs .flight-status-container h6,
	.LocusLabs .flight-status-container .h6{
		font-size:15px;
	}
	.LocusLabs .flight-status-container p{
		margin:0 0 1em;
	}
	.LocusLabs .flight-status-container a{
		color:#3399ff;
		text-decoration:none;
	}
	.LocusLabs .flight-status-container a:hover,
	.LocusLabs .flight-status-container a:focus{
		color:#000000;
	}
	.LocusLabs .flight-status-container input,
	.LocusLabs .flight-status-container fieldset{
		margin:0;
		padding:0;
		border-style:none;
	}
	.LocusLabs .flight-status-container input[type="text"],
	.LocusLabs .flight-status-container input[type="tel"],
	.LocusLabs .flight-status-container input[type="email"],
	.LocusLabs .flight-status-container input[type="search"],
	.LocusLabs .flight-status-container input[type="password"],
	.LocusLabs .flight-status-container textarea{
		-webkit-appearance:none;
		-webkit-border-radius:0;
		-webkit-box-sizing:border-box;
		        box-sizing:border-box;
		border:none;
		padding:0.4em 0.7em;
	}
	.LocusLabs .flight-status-container input[type="text"]:focus,
	.LocusLabs .flight-status-container input[type="tel"]:focus,
	.LocusLabs .flight-status-container input[type="email"]:focus,
	.LocusLabs .flight-status-container input[type="search"]:focus,
	.LocusLabs .flight-status-container input[type="password"]:focus,
	.LocusLabs .flight-status-container textarea:focus{
		border-color:#000000;
	}
	.LocusLabs .flight-status-container input[type="text"]::-webkit-input-placeholder,
	.LocusLabs .flight-status-container input[type="tel"]::-webkit-input-placeholder,
	.LocusLabs .flight-status-container input[type="email"]::-webkit-input-placeholder,
	.LocusLabs .flight-status-container input[type="search"]::-webkit-input-placeholder,
	.LocusLabs .flight-status-container input[type="password"]::-webkit-input-placeholder,
	.LocusLabs .flight-status-container textarea::-webkit-input-placeholder{
		color:#999999;
	}
	.LocusLabs .flight-status-container input[type="text"]::-moz-placeholder,
	.LocusLabs .flight-status-container input[type="tel"]::-moz-placeholder,
	.LocusLabs .flight-status-container input[type="email"]::-moz-placeholder,
	.LocusLabs .flight-status-container input[type="search"]::-moz-placeholder,
	.LocusLabs .flight-status-container input[type="password"]::-moz-placeholder,
	.LocusLabs .flight-status-container textarea::-moz-placeholder{
		opacity:1;
		color:#999999;
	}
	.LocusLabs .flight-status-container input[type="text"]:-moz-placeholder,
	.LocusLabs .flight-status-container input[type="tel"]:-moz-placeholder,
	.LocusLabs .flight-status-container input[type="email"]:-moz-placeholder,
	.LocusLabs .flight-status-container input[type="search"]:-moz-placeholder,
	.LocusLabs .flight-status-container input[type="password"]:-moz-placeholder,
	.LocusLabs .flight-status-container textarea:-moz-placeholder{
		color:#999999;
	}
	.LocusLabs .flight-status-container input[type="text"]:-ms-input-placeholder,
	.LocusLabs .flight-status-container input[type="tel"]:-ms-input-placeholder,
	.LocusLabs .flight-status-container input[type="email"]:-ms-input-placeholder,
	.LocusLabs .flight-status-container input[type="search"]:-ms-input-placeholder,
	.LocusLabs .flight-status-container input[type="password"]:-ms-input-placeholder,
	.LocusLabs .flight-status-container textarea:-ms-input-placeholder{
		color:#999999;
	}
	.LocusLabs .flight-status-container input[type="text"].placeholder,
	.LocusLabs .flight-status-container input[type="tel"].placeholder,
	.LocusLabs .flight-status-container input[type="email"].placeholder,
	.LocusLabs .flight-status-container input[type="search"].placeholder,
	.LocusLabs .flight-status-container input[type="password"].placeholder,
	.LocusLabs .flight-status-container textarea.placeholder{
		color:#999999;
	}
	.LocusLabs .flight-status-container select{
		-webkit-border-radius:0;
	}
	.LocusLabs .flight-status-container textarea{
		resize:vertical;
		vertical-align:top;
	}
	.LocusLabs .flight-status-container button,
	.LocusLabs .flight-status-container input[type="button"],
	.LocusLabs .flight-status-container input[type="reset"],
	.LocusLabs .flight-status-container input[type="file"],
	.LocusLabs .flight-status-container input[type="submit"]{
		-webkit-appearance:none;
		-webkit-border-radius:0;
		cursor:pointer;
	}
	.LocusLabs .flight-status-container #wrapper{
		position:relative;
		width:100%;
		overflow:hidden;
	}
	.LocusLabs .flight-status-container .widget-holder{
		-webkit-transition:width 0.3s ease-in-out;
		-o-transition:width 0.3s ease-in-out;
		transition:width 0.3s ease-in-out;
		position:relative;
		width:310px;
		margin:20px auto 65px;
		background:#f0f0f0;
	}
	.LocusLabs .flight-status-container .widget-frame{
		overflow:hidden;
		border-radius:2px;
	}
	.LocusLabs .flight-status-container .box-shadow{
		-webkit-box-shadow:0 2px 24px 0 rgba(0, 0, 0, 0.2);
		        box-shadow:0 2px 24px 0 rgba(0, 0, 0, 0.2);
	}
	.LocusLabs .flight-status-container .flightlist-head{
		z-index:1;
	}
	.LocusLabs .flight-status-container .flightlist-head > .flightlist-topline{
			display:-ms-flexbox;
			display:flex;
			padding:16px;
			-ms-flex-pack:justify;
			    justify-content:space-between;
		}
	.LocusLabs .flight-status-container .flightlist-head > .flightlist-topline > .title{
				font-size:20px;
				font-weight:500;
				line-height:40px;
				-ms-flex:1 1;
				    flex:1 1;
				margin:0 16px;
			}
	.LocusLabs .flight-status-container .form-box{
		display:-ms-flexbox;
		display:flex;
		padding:16px;
		-ms-flex-pack:justify;
		    justify-content:space-between;
	}
	.LocusLabs .flight-status-container .form-box > input{
			-ms-flex:1 1;
			    flex:1 1;
			padding:0;
			margin:0 16px;
		}
	.LocusLabs .flight-status-container .js-tab-hidden{
		display:block !important;
		left:-9999px !important;
		position:absolute !important;
		top:-9999px !important;
	}
	.LocusLabs .flight-status-container .tooltip-hover{
		-webkit-transition:opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
		-o-transition:opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
		transition:opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
		-webkit-transition-delay:0.25s;
		-o-transition-delay:0.25s;
		   transition-delay:0.25s;
		position:absolute;
		z-index:9999;
		top:100%;
		left:50%;
		-webkit-transform:translateX(-50%);
		transform:translateX(-50%);
		margin-top:7px;
		opacity:0;
		visibility:hidden;
	}
	.LocusLabs .flight-status-container .tooltip-hover.alt-position{
		margin-top:34px;
	}
	.LocusLabs .flight-status-container .tooltip-hover .tooltip-text{
		position:relative;
		display:inline-block;
		font-size:12px;
		line-height:1;
		font-weight:400;
		background:#232323;
		color:#ffffff;
		border-radius:2px;
		padding:7px 10px 4px;
		-webkit-box-shadow:1px 1px 6px 0 rgba(0, 0, 0, 0.35);
		        box-shadow:1px 1px 6px 0 rgba(0, 0, 0, 0.35);
	}
	.LocusLabs .flight-status-container .tooltip-hover .tooltip-text:before{
		width:14px;
		height:14px;
		content:"";
		position:absolute;
		z-index:1;
		top:-6px;
		left:50%;
		margin-left:-7px;
		background:#232323;
		-webkit-transform:rotate(45deg);
		transform:rotate(45deg);
		border-radius:2px;
		-webkit-box-shadow:1px 1px 6px 0 rgba(0, 0, 0, 0.35);
		        box-shadow:1px 1px 6px 0 rgba(0, 0, 0, 0.35);
	}
	.LocusLabs .flight-status-container .btn-back{
		position:relative;
		display:block;
		width:50px;
		height:30px;
		font-size:16px;
		line-height:30px;
		color:#a0a0a0;
		text-align:center;
	}
	.LocusLabs .flight-status-container .btn-back:hover{
		color:#a0a0a0;
	}
	.LocusLabs .flight-status-container .btn-back:hover .tooltip-hover{
		opacity:1;
		visibility:visible;
		-webkit-transition-delay:0.5s;
		-o-transition-delay:0.5s;
		   transition-delay:0.5s;
	}
	.LocusLabs .flight-status-container .minimize-button:hover{
		color:#a0a0a0;
	}
	.LocusLabs .flight-status-container .minimize-button:hover .tooltip-hover{
		opacity:1;
		visibility:visible;
		-webkit-transition-delay:0.5s;
		-o-transition-delay:0.5s;
		   transition-delay:0.5s;
	}
	.LocusLabs .flight-status-container .search-form{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		-ms-flex-align:center;
		align-items:center;
		width:calc(100% - 50px);
		height:30px;
	}
	.LocusLabs .flight-status-container .search-form.typing-off .icon-close{
		display:inline-block;
		vertical-align:middle;
	}
	.LocusLabs .flight-status-container .search-form.typing-off .icon-search{
		display:none;
	}
	.LocusLabs .flight-status-container .search-form.typing-off .tooltip-hover{
		display:none;
	}
	.LocusLabs .flight-status-container .search-form input[type="search"],
	.LocusLabs .flight-status-container .search-form input[type="text"]{
		display:block;
		width:100%;
		height:30px;
		border:0;
		outline:0;
		background:none;
		font-size:16px;
		line-height:1;
		font-weight:400;
		color:#333333;
		padding:0 6px;
	}
	.LocusLabs .flight-status-container .search-form input[type="search"]::-webkit-input-placeholder,
	.LocusLabs .flight-status-container .search-form input[type="text"]::-webkit-input-placeholder{
		font-style:italic;
		color:#999999;
	}
	.LocusLabs .flight-status-container .search-form input[type="search"]::-moz-placeholder,
	.LocusLabs .flight-status-container .search-form input[type="text"]::-moz-placeholder{
		opacity:1;
		font-style:italic;
		color:#999999;
	}
	.LocusLabs .flight-status-container .search-form input[type="search"]:-moz-placeholder,
	.LocusLabs .flight-status-container .search-form input[type="text"]:-moz-placeholder{
		font-style:italic;
		color:#999999;
	}
	.LocusLabs .flight-status-container .search-form input[type="search"]:-ms-input-placeholder,
	.LocusLabs .flight-status-container .search-form input[type="text"]:-ms-input-placeholder{
		font-style:italic;
		color:#999999;
	}
	.LocusLabs .flight-status-container .search-form input[type="search"].placeholder,
	.LocusLabs .flight-status-container .search-form input[type="text"].placeholder{
		font-style:italic;
		color:#999999;
	}
	.LocusLabs .flight-status-container .search-form button{
		-webkit-transition:color 0.3s ease-in-out;
		-o-transition:color 0.3s ease-in-out;
		transition:color 0.3s ease-in-out;
		position:relative;
		display:block;
		width:50px;
		height:30px;
		font-size:16px;
		line-height:28px;
		border:0;
		outline:0;
		background:none;
		text-align:center;
		color:#333333;
	}
	.LocusLabs .flight-status-container .search-form button:hover{
		color:#333333;
	}
	.LocusLabs .flight-status-container .search-form button:hover .tooltip-hover{
		opacity:1;
		visibility:visible;
		-webkit-transition-delay:0.5s;
		-o-transition-delay:0.5s;
		   transition-delay:0.5s;
	}
	.LocusLabs .flight-status-container .search-form button .icon-close{
		display:none;
		font-size:12px;
		line-height:1;
		margin-top:-1px;
	}
	.LocusLabs .flight-status-container .search-form button .icon-search{
		display:inline-block;
		vertical-align:middle;
	}
	.LocusLabs .flight-status-container .top-links{
		font-size:0;
		line-height:0;
		padding:0 9px;
	}
	.LocusLabs .flight-status-container .top-links li{
		border:1px solid #1e405a;
		cursor:pointer;
		display:inline-block;
		vertical-align:top;
		width:50%;
		font-size:12px;
		line-height:1.1;
		font-weight:700;
		text-transform:uppercase;
		text-align:center;
		border-radius:2px 0 0 2px;
		overflow:hidden;
	}
	.LocusLabs .flight-status-container .top-links li:last-child{
		border-radius:0 2px 2px 0;
	}
	.LocusLabs .flight-status-container .top-links li.active div{
		background:#1e405a;
		color:#ffffff;
	}
	.LocusLabs .flight-status-container .top-links div{
		-webkit-transition:background 0.3s ease-in-out, color 0.3s ease-in-out;
		-o-transition:background 0.3s ease-in-out, color 0.3s ease-in-out;
		transition:background 0.3s ease-in-out, color 0.3s ease-in-out;
		display:block;
		color:#1e405a;
		padding:9px 5px 8px;
		background:#fff;
	}
	.LocusLabs .flight-status-container .top-links div:hover{

		}
	.LocusLabs .flight-status-container .text-show{
		display:none;
	}
	.LocusLabs .flight-status-container .panel-intro{
		position:relative;
	}
	.LocusLabs .flight-status-container .panel-intro.child-active .child-opener:after{
		content:"\E906";
	}
	.LocusLabs .flight-status-container .panel-intro.child-active .child-opener:hover + .tooltip-hover{
		opacity:1;
		visibility:visible;
		-webkit-transition-delay:0.5s;
		-o-transition-delay:0.5s;
		   transition-delay:0.5s;
	}
	.LocusLabs .flight-status-container .panel-intro.child-active .child-opener:hover + .tooltip-hover .text-show{
		display:none;
	}
	.LocusLabs .flight-status-container .panel-intro.child-active .child-opener:hover + .tooltip-hover .text-hide{
		display:inline;
	}
	.LocusLabs .flight-status-container .panel-intro.child-active .panel-top-box{
		max-height:300px;
		-webkit-transition:max-height 0.5s;
		-o-transition:max-height 0.5s;
		transition:max-height 0.5s;
	}
	.LocusLabs .flight-status-container .panel-intro.child-active .panel-top-box.hidden-container{
		max-height:0px;
	}
	.LocusLabs .flight-status-container .category-title{
		display:block;
		font-size:12px;
		line-height:1.1;
		font-weight:400;
		text-transform:uppercase;
		background:#333333;
		color:#ffffff;
		padding:7px 25px 6px;
		position:relative;
		z-index:1;
	}
	.LocusLabs .flight-status-container .category-title.fixed{
		position:fixed;
		top:100;
		width:inherit;
		-webkit-box-sizing:border-box;
		        box-sizing:border-box;
		z-index:0;
	}
	.LocusLabs .flight-status-container .category-title.fixed.absolute{
		position:absolute;
	}
	.LocusLabs .flight-status-container .flight-box{
		padding:8px 10px;
	}
	.LocusLabs .flight-status-container .flight-item{
		-webkit-transition:background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
		-webkit-transition:background 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
		transition:background 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
		-o-transition:background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
		transition:background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
		transition:background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
		display:block;
		background:#ffffff;
		border-radius:2px;
		padding:14px 12px 12px 14px;
		margin-bottom:8px;
		color:#424242;
	}
	.LocusLabs .flight-status-container .flight-item:hover,
	.LocusLabs .flight-status-container .flight-item:focus{
		background:#f9f9f9;
		-webkit-box-shadow:0 0 12px 0 rgba(0, 0, 0, 0.2);
		        box-shadow:0 0 12px 0 rgba(0, 0, 0, 0.2);
	}
	.LocusLabs .flight-status-container .flight-item .row{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		-ms-flex-pack:justify;
		justify-content:space-between;
	}
	.LocusLabs .flight-status-container .flight-item .row:first-child{
		margin-bottom:8px;
	}
	.LocusLabs .flight-status-container .flight-item .left-col{
		width:100%;
	}
	.LocusLabs .flight-status-container .flight-item .right-col{
		width:20%;
		text-align:right;
		padding-left:10px;
	}
	.LocusLabs .flight-status-container .flight-item .flight-title{
		display:block;
		font-size:16px;
		line-height:1.1;
		font-weight:500;
		color:#424242;
		margin-bottom:3px;
	}
	.LocusLabs .flight-status-container .flight-item .flight-title mark{
		font-weight:400;
		background:none;
		color:#999999;
		margin-right:0.5ex;
	}
	.LocusLabs .flight-status-container .flight-item .flight-num{
		display:block;
		font-size:14px;
		line-height:1.1;
		font-weight:400;
		color:#424242;
		margin-bottom:2px;
	}
	.LocusLabs .flight-status-container .flight-item .col-title{
		display:block;
		font-size:11px;
		line-height:1.1;
		font-weight:400;
		color:#333;
		text-transform:uppercase;
		margin-bottom:3px;
	}
	.LocusLabs .flight-status-container .flight-item time,
	.LocusLabs .flight-status-container .flight-item .gate-num{
		font-size:16px;
		line-height:1.1;
		font-weight:400;
		color:#333333;
		text-transform:uppercase;
	}
	.LocusLabs .flight-status-container .flight-item time s,
	.LocusLabs .flight-status-container .flight-item .gate-num s{
		color:#cccccc;
	}
	.LocusLabs .flight-status-container .flight-item .img-box img{
		display:inline-block;
		vertical-align:top;
		max-width:100%;
		height:auto;
	}
	.LocusLabs .flight-status-container .on-time .flight-status{
		background:#8dd119;
	}
	.LocusLabs .flight-status-container .on-time .flight-status .on-time-text{
		display:block;
	}
	.LocusLabs .flight-status-container .on-time .flight-status .delay-text,
	.LocusLabs .flight-status-container .on-time .flight-status .cancel-text{
		display:none;
	}
	.LocusLabs .flight-status-container .delayed .flight-status{
		background:#e93232;
	}
	.LocusLabs .flight-status-container .delayed .flight-status .delay-text{
		display:block;
	}
	.LocusLabs .flight-status-container .delayed .flight-status .cancel-text,
	.LocusLabs .flight-status-container .delayed .flight-status .on-time-text{
		display:none;
	}
	.LocusLabs .flight-status-container .delayed .info-time{
		color:#f04a40;
	}
	.LocusLabs .flight-status-container .cancelled .flight-status{
		background:#e93232;
	}
	.LocusLabs .flight-status-container .cancelled .flight-status .cancel-text{
		display:block;
	}
	.LocusLabs .flight-status-container .cancelled .flight-status .delay-text,
	.LocusLabs .flight-status-container .cancelled .flight-status .on-time-text{
		display:none;
	}
	.LocusLabs .flight-status-container .cancelled .info-time{
		color:#f04a40;
	}
	.LocusLabs .flight-status-container .cancelled .time-item,
	.LocusLabs .flight-status-container .cancelled .gate-num{
		color:rgba(51, 51, 51, 0.3);
	}
	.LocusLabs .flight-status-container .cancelled .two-cols{
		opacity:0.5;
	}
	.LocusLabs .flight-status-container .flight-status{
		display:inline-block;
		vertical-align:top;
		font-size:11px;
		line-height:1.1;
		font-weight:400;
		color:#ffffff;
		padding:3px 9px 2px;
		text-align:center;
		border-radius:25px;
	}
	.LocusLabs .flight-status-container .flight-status.cancelled{
		background:#e93232;
	}
	.LocusLabs .flight-status-container .flight-status.cancelled .cancel-text{
		display:block;
	}
	.LocusLabs .flight-status-container .flight-status.cancelled .delay-text,
	.LocusLabs .flight-status-container .flight-status.cancelled .on-time-text{
		display:none;
	}
	.LocusLabs .flight-status-container .popup-holder.popup-active .popup{
		display:block;
	}
	.LocusLabs .flight-status-container .popup-holder .popup{
		position:absolute;
		z-index:999;
		top:0;
		left:0;
		right:0;
		display:none;
	}
	.LocusLabs .flight-status-container .panel-head{
		background:#ffffff;
		width:100%;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		-ms-flex-align:start;
		align-items:flex-start;
		-ms-flex-pack:justify;
		justify-content:space-between;
		padding:15px;
		border-radius:2px;
	}
	.LocusLabs .flight-status-container .panel-head .left-col{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		-ms-flex-align:center;
		align-items:center;
		width:calc(100% - 30px);
	}
	.LocusLabs .flight-status-container .panel-head .ico-holder{
		width:30px;
		height:30px;
		display:block;
		border-radius:2px;
		color:#ffffff;
		text-align:center;
		font-size:16px;
		line-height:32px;
	}
	.LocusLabs .flight-status-container .panel-head .ico-holder > .icon{ height:100%; }
	.LocusLabs .flight-status-container .panel-head .panel-title{
		display:block;
		color:#424242;
		font-size:18px;
		line-height:1.1;
		font-weight:500;
		padding-left:16px;
	}
	.LocusLabs .flight-status-container .panel-head .popup-close{
		position:relative;
		display:block;
		width:30px;
		height:30px;
		text-align:center;
		font-size:12px;
		line-height:30px;
		color:#999999;
	}
	.LocusLabs .flight-status-container .panel-head .popup-close:hover .tooltip-hover{
		opacity:1;
		visibility:visible;
		-webkit-transition-delay:0.5s;
		-o-transition-delay:0.5s;
		   transition-delay:0.5s;
	}
	.LocusLabs .flight-status-container .panel-body{
		background:#ebebeb;
		padding:0px 10px 10px;
		border-radius:2px;
	}
	.LocusLabs .flight-status-container .mobile-flight-status .panel-body{
		width:100% !important;
		min-width:100% !important;
		height:100% !important;
		max-height:none;
		overflow:hidden;
		z-index:10;
	}
	.LocusLabs .flight-status-container .details-top{
		width:100%;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		-ms-flex-align:center;
		align-items:center;
		-ms-flex-pack:justify;
		justify-content:space-between;
		padding:10px 10px;
		background:#ebebeb;
	}
	.LocusLabs .flight-status-container .details-top .left-col{
		width:85%;
	}
	.LocusLabs .flight-status-container .details-top .right-col{
		width:15%;
		text-align:right;
	}
	.LocusLabs .flight-status-container .details-top .right-col img{
		display:inline-block;
		vertical-align:top;
		max-width:100%;
		height:auto;
	}
	.LocusLabs .flight-status-container .details-top .flight-num{
		display:block;
		color:#424242;
		font-size:18px;
		line-height:1.1;
		font-weight:500;
		margin-bottom:3px;
	}
	.LocusLabs .flight-status-container .details-top .centered-box{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		-ms-flex-align:center;
		align-items:center;
	}
	.LocusLabs .flight-status-container .details-content{
		overflow:hidden;
	}
	.LocusLabs .flight-status-container .update-info{
		display:block;
		color:#808080;
		font-size:12px;
		line-height:1;
		padding-left:8px;
		font-weight:normal;
	}
	.LocusLabs .flight-status-container .details-inner{
		background:#ffffff;
		border-radius:2px;
		padding:15px;
		margin-bottom:10px;
	}
	.LocusLabs .flight-status-container .flight-meta{
		font-size:0;
		line-height:0;
		color:#424242;
		margin-bottom:16px;
	}
	.LocusLabs .flight-status-container .flight-meta li{
		display:inline-block;
		vertical-align:top;
		font-size:14px;
		line-height:1.1;
		font-weight:400;
	}
	.LocusLabs .flight-status-container .flight-meta li + li:before{
		content:"\2022";
		display:inline-block;
		vertical-align:top;
		margin:0 7px 0 8px;
	}
	.LocusLabs .flight-status-container .flight-meta mark{
		background:none;
		color:#999999;
	}
	.LocusLabs .flight-status-container .two-cols{
		width:100%;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		-ms-flex-align:start;
		align-items:flex-start;
		-ms-flex-pack:justify;
		justify-content:space-between;
	}
	.LocusLabs .flight-status-container .two-cols .left-col{
		width:50%;
		padding-right:10px;
	}
	.LocusLabs .flight-status-container .two-cols .right-col{
		width:50%;
	}
	.LocusLabs .flight-status-container .sm-title{
		display:block;
		font-size:11px;
		line-height:1.1;
		font-weight:400;
		text-transform:uppercase;
		color:#333;
		margin-bottom:5px;
	}
	.LocusLabs .flight-status-container.-canceled .sm-title, .LocusLabs .flight-status-container.-canceled .info-time, .LocusLabs .flight-status-container.-canceled .info-num, .LocusLabs .flight-status-container.-canceled .terminal, .LocusLabs .flight-status-container.-canceled .bagclaimnum{
			opacity:0.2;
		}
	.LocusLabs .flight-status-container .md-title{
		display:block;
		font-size:14px;
		line-height:1.1;
		font-weight:400;
		color:#a9a9a9;
		margin-bottom:5px;
	}
	.LocusLabs .flight-status-container .info-num{
		display:block;
		font-size:28px;
		line-height:1.1;
		font-weight:400;
		color:#424242;
		margin-bottom:5px;
		text-transform:uppercase;
	}
	.LocusLabs .flight-status-container .separator-box{
		position:relative;
		height:16px;
		margin:15px 0;
	}
	.LocusLabs .flight-status-container .separator-box:before{
		content:"";
		position:absolute;
		z-index:1;
		top:50%;
		left:0;
		right:0;
		height:2px;
		background:#f5f5f5;
	}
	.LocusLabs .flight-status-container .separator-box i{
		position:absolute;
		z-index:2;
		top:0;
		left:50%;
		margin-left:-15px;
		color:#999999;
		font-size:16px;
		line-height:1;
		background:#ffffff;
		width:30px;
		height:16px;
		text-align:center;
	}
	.LocusLabs .flight-status-container .panel-top-box{
		-webkit-transition:max-height 0.4s ease;
		-o-transition:max-height 0.4s ease;
		transition:max-height 0.4s ease;
		background:#333333;
		color:#ffffff;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		width:100%;
		max-height:0;
		overflow:hidden;
	}
	.LocusLabs .flight-status-container .panel-top-box .col{
		padding:18px 0;
		width:31%;
	}
	.LocusLabs .flight-status-container .panel-top-box .col:first-child{
		padding-left:25px;
	}
	.LocusLabs .flight-status-container .panel-top-box .col.center-col{
		text-align:center;
		padding:18px 10px;
	}
	.LocusLabs .flight-status-container .panel-top-box .col:last-child{
		width:38%;
		padding-left:10px;
		padding-right:5px;
	}
	.LocusLabs .flight-status-container .panel-top-box .title-row{
		display:block;
		font-size:11px;
		line-height:1.1;
		font-weight:400;
		color:#a9a9a9;
		margin-bottom:5px;
	}
	.LocusLabs .flight-status-container .panel-top-box .lg-title{
		display:block;
		font-size:40px;
		line-height:1;
		font-weight:400;
	}
	.LocusLabs .flight-status-container .panel-top-box .img-box{
		position:relative;
		overflow:hidden;
	}
	.LocusLabs .flight-status-container .panel-top-box .img-box:before{
		content:"";
		position:absolute;
		z-index:1;
		left:0;
		right:0;
		top:50%;
		height:40px;
		border:1px dashed #fff;
		border-radius:120px / 50px;
	}
	.LocusLabs .flight-status-container .panel-top-box .img-box i{
		display:block;
		position:relative;
		z-index:2;
		font-size:28px;
		line-height:1;
	}

.icon.flight-icon-location.icon-location{
	display:inline;
}

.icon.flight-icon-downarrow.icon-arrow-down > svg{
	z-index:2;
}

.icon.flight-icon-close.icon-close{
	margin:9px;
}

.icon.flight-icon-airplaneup.icon-plane-up > svg{
	margin:7px;
}
.message{
	width:100%;
	-ms-flex:1 1;
	    flex:1 1;
	display:-ms-flexbox;
	display:flex;
	overflow:hidden;
	-ms-flex-direction:column;
	    flex-direction:column;
	margin-top:0.1em;
}

	.message > .waitIndicator{
		margin:1em;
	}

	.message > .sorryMsg{
		display:-ms-flexbox;
		display:flex;
		margin:1.5em;
		-ms-flex-align:center;
		    align-items:center;
	}

	.message > .sorryMsg > .icon{
			margin-right:1em;
		}

	.message > .sorryMsg > .textLabel{
			line-height:1.5em;
		}

	.message > .sorryMsg > .textLabel > .title{
				font-size:1.125em;
			}

	.message > .sorryMsg > .textLabel > .desc{
				font-size:0.875em;
			}

.LocusLabs .search-entry{
	padding:0.5em;
	width:100%;
	-webkit-transition:all 250ms;
	-o-transition:all 250ms;
	transition:all 250ms;
	-ms-flex:none;
	    flex:none;
}

	.LocusLabs .search-entry.-active{
		border-bottom:1px solid;
	}

	.LocusLabs .search-entry .searchInputField{
		border-width:0;
		margin:0.25em;
		outline:none;
		border-right-width:1px;
		border-right-style:solid;
		border-radius:0;
		-ms-flex:1 1;
		    flex:1 1;
	}

	.LocusLabs .search-entry .searchInputField::-webkit-input-placeholder{
			color:#808080;
			font-style:italic;
		}

	.LocusLabs .search-entry .searchInputField::-moz-placeholder{
			color:#808080;
			font-style:italic;
		}

	.LocusLabs .search-entry .searchInputField:-ms-input-placeholder{
			color:#808080;
			font-style:italic;
		}

	.LocusLabs .search-entry .searchInputField::placeholder{
			color:#808080;
			font-style:italic;
		}

	.LocusLabs .search-entry > .inner{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-direction:row;
		    flex-direction:row;
		-ms-flex-align:center;
		    align-items:center;
		border-radius:0.125em;
	}

	.LocusLabs .search-entry > .inner > input{
			border-width:0;
			margin:0.25em;
			outline:none;
			border-right-width:1px;
			border-right-style:solid;
			border-radius:0;
			-ms-flex:1 1;
			    flex:1 1;
		}

	.LocusLabs .search-entry > .inner > input::-webkit-input-placeholder{
				color:#808080;
				font-style:italic;
			}

	.LocusLabs .search-entry > .inner > input::-moz-placeholder{
				color:#808080;
				font-style:italic;
			}

	.LocusLabs .search-entry > .inner > input:-ms-input-placeholder{
				color:#808080;
				font-style:italic;
			}

	.LocusLabs .search-entry > .inner > input::placeholder{
				color:#808080;
				font-style:italic;
			}

	.LocusLabs .search-entry > .inner > input::-ms-clear{
				display:none;
			}

	.LocusLabs .search-entry > .inner > .icon{
			width:1.875em;
			height:1.875em;
			margin:0.5em;
		}

	.LocusLabs .search-entry > .inner > .icon.getDirections{
			cursor:pointer;
		}

.LocusLabs.-mobile .search-entry{
	padding:0;
	height:50px;
}

.LocusLabs.-mobile .search-entry > .inner{
		-ms-flex-pack:end;
		    justify-content:flex-end;
	}

.LocusLabs.-mobile .search-entry > .inner > input{
			border-right:0;
		}


.landing-list{
	width:100%;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-pack:center;
	    justify-content:center;
	-ms-flex-wrap:wrap;
	    flex-wrap:wrap;
	padding:0 1em;
	width:100%;
}

	.landing-list > div{
		-ms-flex:1 0 30%;
		    flex:1 0 30%;
		margin:0.750em 0em 0.750em 0em;
		cursor:pointer;
	}

	.landing-list > div > div.caticon{
			margin-left:0.6em;
			margin-right:0.6em;
		}

	.landing-list > div .title{
			text-align:center;
			margin-top:0.500em;
			font-size:0.875em;
			font-weight:400;
		}

.LocusLabs.-mobile .full-screen{
	height:80vh;
	overflow:hidden;
}

.LocusLabs.-mobile .full-screen .landing-list{
		margin-top:28px;
	}

.suggested-searches{
	width:100%;
	-ms-flex:none;
	    flex:none;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
}

	.suggested-searches > .waitIndicator{
		padding:1em 1em 1em calc(1em + 30px);
	}

	.suggested-searches > .title{
		width:100%;
		font-size:0.75em;
		text-transform:uppercase;
		padding:0.5em 0;
		padding-left:calc(2em + 30px);
		-webkit-box-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);
		        box-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);
	}

	.suggested-searches > .suggestionsList{
		-ms-flex:none;
		    flex:none;
		overflow-y:auto;
	}

	.suggested-searches > .suggestionsList > .suggestion{
			display:-ms-flexbox;
			display:flex;
			-ms-flex-align:center;
			    align-items:center;
			padding:1em;
			border-bottom-width:1px;
			border-bottom-style:solid;
			cursor:pointer;
		}

	.suggested-searches > .suggestionsList > .suggestion:last-child{
				border:none;
			}

	.suggested-searches > .suggestionsList > .suggestion > div.icon{
				margin-right:1em;
				width:30px;
			}
.LocusLabs .suggested-locations{
	width:100%;
	-ms-flex:1 1 auto;
	    flex:1 1 auto;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
}

	.LocusLabs .suggested-locations > .waitIndicator{
		padding:1em 1em 1em calc(1em + 30px);
	}

	.LocusLabs .suggested-locations > .title{
		width:100%;
		font-size:0.75em;
		text-transform:uppercase;
		padding:0.5em 0;
		padding-left:calc(2em + 30px);
		-webkit-box-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);
		        box-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);
	}

	.LocusLabs .suggested-locations > .sorryMsg{
		display:-ms-flexbox;
		display:flex;
		margin:1.5em;
		-ms-flex-align:center;
		    align-items:center;
	}

	.LocusLabs .suggested-locations > .sorryMsg > .icon{
			margin-right:1em;
		}

	.LocusLabs .suggested-locations > .sorryMsg > .textLabel{
			line-height:1.5em;
		}

	.LocusLabs .suggested-locations > .sorryMsg > .textLabel > .title{
				font-size:1.125em;
			}

	.LocusLabs .suggested-locations > .sorryMsg > .textLabel > .desc{
				font-size:0.875em;
			}

	.LocusLabs .suggested-locations > .suggestionsList{
		-ms-flex:1 1 auto;
		    flex:1 1 auto;
		overflow-y:hidden;
		cursor:pointer;
	}

	.LocusLabs .suggested-locations > .suggestionsList > .suggestion{
			display:-ms-flexbox;
			display:flex;
			-ms-flex-align:center;
			    align-items:center;
			padding:1em;
			border-bottom-width:1px;
			border-bottom-style:solid;
		}

	.LocusLabs .suggested-locations > .suggestionsList > .suggestion:last-child{
				border:none;
			}

	.LocusLabs .suggested-locations > .suggestionsList > .suggestion > div.icon{
				margin-right:1em;
				width:30px;
			}

	.LocusLabs .suggested-locations > .suggestionsList > .suggestion > .textLabel > .title{
				font-weight:500;
			}

	.LocusLabs .suggested-locations > .suggestionsList > .suggestion > .textLabel > .desc{
				font-size:0.875em;
			}

.LocusLabs.-mobile .suggested-locations > .title{
	font-size:0.75em;
}

.poi-title{
	-webkit-transition:all 200ms;
	-o-transition:all 200ms;
	transition:all 200ms;
	display:-ms-flexbox;
	display:flex;
	-ms-flex:none;
	    flex:none;
	width:100%;
	-ms-flex-align:center;
	    align-items:center;
	height:50px;
}

	.poi-title > .title{
		-ms-flex:1 1;
		    flex:1 1;
		font-weight:500;
	}

	.poi-title > .icon{
		padding:1em;
	}

.poi-images{
	-webkit-transition:all 200ms;
	-o-transition:all 200ms;
	transition:all 200ms;
	width:100%;
	height:13.21em;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-align:center;
	    align-items:center;
	text-align:center;
	font-weight:500;
	-ms-flex:none;
	    flex:none;
	background-size:cover;
}

	.poi-images > div{
		height:100%;
		width:100%;
		position:relative;
	}

	.poi-images > div > div.poiImage{
			position:absolute;
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center;
			height:100%;
			width:100%;
		}

	.poi-images > div img{
			display:none;
		}

	.poi-images div.notAvailable{
		height:auto;
	}

.LocusLabs .tag{
		display:inline-block;
		border:1px solid;
		margin:0.25em 0.5em 0.25em 0;
		font-size:0.75em;
		padding:2px 8px;
		border-radius:6px;
		position:relative;
		overflow:hidden;
		cursor:pointer;
	}

.LocusLabs .poi-details{
	-webkit-transition:all 200ms;
	-o-transition:all 200ms;
	transition:all 200ms;
	-ms-flex-direction:column;
	    flex-direction:column;
	-ms-flex-align:center;
	    align-items:center;
	overflow-y:auto;
	-ms-flex-positive:1;
	    flex-grow:1;
	width:100%;
}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){.LocusLabs .poi-details{
		max-height:70vh
}
	}

	.LocusLabs .poi-details .poi-header-layout{
		margin-left:calc(2em + 30px);
	}

	.LocusLabs .poi-details .poi-header-layout > *{ margin:0.25em 0; }

	.LocusLabs .poi-details .poi-header-layout > .iconInfo > .icon{ width:14px; margin-right:0.5em; }

	.LocusLabs .poi-details .poi-header-layout > .iconInfo > .icon.poi-icon-walking, .LocusLabs .poi-details .poi-header-layout > .iconInfo > .icon.poi-icon-bus{ -webkit-transform:scale(1.5); transform:scale(1.5); }

	.LocusLabs .poi-details .iconInfo{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-align:center;
		    align-items:center;
	}

	.LocusLabs .poi-details .iconInfo > .info{
			-ms-flex:1 1;
			    flex:1 1;
			font-weight:normal;
			overflow:hidden;
			-o-text-overflow:ellipsis;
			   text-overflow:ellipsis;
		}

	.LocusLabs .poi-details .iconInfo > .info > div{
				display:-ms-flexbox;
				display:flex;
				-webkit-transition-property:opacity, max-height, visibility;
				-o-transition-property:opacity, max-height, visibility;
				transition-property:opacity, max-height, visibility;
				-webkit-transition-duration:0.2s;
				     -o-transition-duration:0.2s;
				        transition-duration:0.2s;
			}

	.LocusLabs .poi-details .iconInfo > .info > div:first-child{
				max-height:100vh;
				opacity:1;
				visibility:visible;
			}

	.LocusLabs .poi-details .iconInfo > .info > div:last-child{
				max-height:0;
				opacity:0;
				visibility:hidden;
			}

	.LocusLabs .poi-details .iconInfo > .info.-active > div:first-child{
					max-height:0;
					opacity:0;
					visibility:hidden;
					position:absolute;
				}

	.LocusLabs .poi-details .iconInfo > .info.-active > div:last-child{
					max-height:100vh;
					opacity:1;
					visibility:visible;
				}

	.LocusLabs .poi-details .iconInfo > .info ul{
				width:100%;
				list-style:none;
				padding:0;
				margin:0;
			}

	.LocusLabs .poi-details .iconInfo > .info ul li{
					font-size:0.875em;
					padding:6px 0;
				}

	.LocusLabs .poi-details .iconInfo > .info ul li.-current{
						font-weight:bold;
					}

	.LocusLabs .poi-details .iconInfo > .info .operating-hours-list-view{
				display:-ms-flexbox;
				display:flex;
			}

	.LocusLabs .poi-details .iconInfo > .info .operating-hours-list-view .icon.poi-icon-openinghours{
					width:30px;
					text-align:left;
					margin-right:1em;
					vertical-align:top;
					height:30px;
				}

	.LocusLabs .poi-details .iconInfo > .info .operating-hours-list-view span:nth-child(even){
					float:right;
					font-size:1.144em;
				}

	.LocusLabs .poi-details .iconInfo > .info .operating-hours-list-view .operating-hours-list-view > ul{
					-ms-flex:15 1;
					    flex:15 1;
					padding:0;
					margin:0;
				}

	.LocusLabs .poi-details .iconInfo > .info .operating-hours-header{
				display:-ms-flexbox;
				display:flex;
			}

	.LocusLabs .poi-details .iconInfo > .info .operating-hours-header div:last-child{
					width:0;
					height:0;
					border-left:8px solid transparent;
					border-right:8px solid transparent;
					border-top:8px solid;
					margin:auto;
				}

	.LocusLabs .poi-details .iconInfo > .info .operating-hours-header > .icon.poi-icon-openinghours{
					width:30px;
					text-align:left;
					margin-right:1em;
					vertical-align:top;
					height:30px;
				}

	.LocusLabs .poi-details .iconInfo > .info .operating-hours-header span{
					-ms-flex:3 1;
					    flex:3 1;
					line-height:1.875;
				}

	.LocusLabs .poi-details .iconInfo > .icon{
			width:30px;
			text-align:left;
			margin-right:1em;
		}

	.LocusLabs .poi-details .iconInfo:first-child{
			margin-top:0;
		}

	.LocusLabs .poi-details > .primaryInfo{
		padding:0.5rem 1em;
	}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){

	.LocusLabs .poi-details > .primaryInfo{
			width:376px
	}
		}

	.LocusLabs .poi-details > .primaryInfo > div{
			width:100%;
			margin:0.5rem 0;
		}

	.LocusLabs .poi-details > .primaryInfo > .iconInfoGroup{
			font-size:0.875em;
		}

	.LocusLabs .poi-details > .primaryInfo > .iconInfoGroup > .iconInfo{
				padding:0.5em 0;
			}

	.LocusLabs .poi-details > .primaryInfo > .-secondary{
			border-width:1px;
			border-style:solid;
		}

	.LocusLabs .poi-details > .primaryInfo > .description{
			white-space:pre-wrap;
			line-height:1.5;
		}

	.LocusLabs .poi-details > .primaryInfo > .amenitiesWrapper{
			padding:1em;
		}

	.LocusLabs .poi-details > .primaryInfo > .amenitiesWrapper > .amenities{
				border-radius:0.375em;
				padding:1em;
			}

	.LocusLabs .poi-details > .primaryInfo > .amenitiesWrapper > .amenities > .title{
					text-transform:uppercase;
					font-size:0.875em;
					font-weight:500;
				}

	.LocusLabs .poi-details > .primaryInfo > .amenitiesWrapper > .amenities .icon svg{
					width:1.4em;
					height:1.4em;
				}

	.LocusLabs .poi-details > .information > .informationTitle{
			font-size:0.750em;
			padding:0.42em 0 0.42em calc(2rem + 30px);
			text-transform:uppercase;
			letter-spacing:0.4px;
		}

	.LocusLabs .poi-details > .information > .iconInfo{
			border-bottom-style:solid;
			border-bottom-width:1px;
			padding:1em;
		}

	.LocusLabs .poi-details > .information > .iconInfo:last-child{
				border:none;
			}

	.LocusLabs .poi-details > .information > .iconInfo a{
				text-decoration:none;
			}

	.LocusLabs .poi-details > .information > .iconInfo a:hover{
					text-decoration:underline;
				}

	.LocusLabs .poi-details .live-container{
		width:100%;
		height:40px;
		color:white;
		font-weight:500;
		letter-spacing:0.1px;
		text-align:center;
		padding:10px 0;
		line-height:1.2;
		text-transform:uppercase;
		display:-ms-flexbox;
		display:flex;
	}

	.LocusLabs .poi-details .bg-transfer-button{
		background-color:rgba(0, 33, 87, 0.7);
		-webkit-box-shadow:0 2px 24px 0 rgba(255, 255, 255, 0.5);
		        box-shadow:0 2px 24px 0 rgba(255, 255, 255, 0.5);
		border-radius:2px;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-pack:center;
		    justify-content:center;
		height:42px;
		text-transform:uppercase;
	}

	.LocusLabs .poi-details .bg-transfer-button:hover{
		color:#ffffff;
		background:#0d263a;
	}

	.LocusLabs .poi-details .transferButton{
		padding:0 1em 0.5rem 1em;
	}

	.LocusLabs .poi-details .transferButton .text-label{
			padding-top:13px;
			padding-left:5px;
			font-weight:500;
			font-size:0.875em;
			text-align:center;
			color:#ffffff;
			text-transform:uppercase;
		}

	.LocusLabs .poi-details .transferButton > .button{
			padding:0.550em 2em 0.950em 1em;
		}

	.LocusLabs .poi-details .transferButton > .button > .icon{
				display:inline-block;
				margin-right:0.5em;
				position:relative;
			}

	.LocusLabs .poi-details .security-time{
		font-size:0.6875em;
		font-weight:400;
		text-align:center;
		color:#ffffff;
		border-radius:100px;
		background-color:#ea3232;
		display:inline-block;
		padding:1px 8px;
		margin-right:5px;
	}

	.LocusLabs .poi-details .subtitle-security-mobile{
		font-size:0.75em;
		font-style:italic;
		color:#666666;
		display:inline-block;
		margin-right:5px;
	}

.LocusLabs.-mobile .poi-details > div.primaryInfo > div.description{
	font-size:1.125em;
}
.LocusLabs .poi-container{
	-ms-flex-align:center;
	    align-items:center;
	border-radius:0.125em;
	-webkit-box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	        box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
	overflow:hidden;
	-webkit-transition:all 200ms;
	-o-transition:all 200ms;
	transition:all 200ms;
}

.LocusLabs .poi-showmap-return-header{
	position:fixed;
	top:0;
	left:0;
	width:100%;
}
.search-categories{
	width:100%;
	-ms-flex:none;
	    flex:none;
	display:-ms-flexbox;
	display:flex;
	overflow:hidden;
	-ms-flex-direction:column;
	    flex-direction:column;
}

	.search-categories .title{
		width:100%;
		font-size:0.875em;
		text-transform:uppercase;
		padding:0.5em 0;
		padding-left:calc(2em + 30px);
		-webkit-box-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);
		        box-shadow:1px 1px 3px rgba(0, 0, 0, 0.5);
	}

	.search-categories .suggestionsList{
		-ms-flex:none;
		    flex:none;
		overflow-y:auto;
	}

	.search-categories .suggestion{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-align:center;
		    align-items:center;
		padding:1em;
		border-bottom-width:1px;
		border-bottom-style:solid;
		cursor:pointer;
	}

	.search-categories .suggestion:last-child{
			border:none;
		}

	.search-categories .suggestion > div.icon{
			margin-right:1em;
			width:30px;
		}

.LocusLabs.-mobile .search-categories{
	display:none;
}
.LocusLabs .search-container .search{
		border-radius:0.125em;
		-webkit-box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
		        box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
		display:-ms-flexbox;
		display:flex;
		-ms-flex-direction:column;
		    flex-direction:column;
		overflow:hidden;
		-webkit-transition:all 200ms;
		-o-transition:all 200ms;
		transition:all 200ms;
	}
	.LocusLabs .search-container .scrolling-container{
		overflow:auto;
		-ms-flex-positive:1;
		    flex-grow:1;
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
	.LocusLabs .search-container .scrolling-container{
			max-height:70vh
	}
		}

.LocusLabs .bluedot-dialog{
	width:300px;
	margin-left:-150px;
}

.about-dialog{
	max-height:400px;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
	overflow:visible !important;
}

.about-dialog > h1{
		margin:0;
		padding:0.25em 0.5em;
		border-radius:0.25em;
		font-size:1.25em;
		font-weight:400;
	}

.about-dialog > div{
		overflow:auto;
	}

.about-dialog > div > h2{
			font-size:1.1em;
			margin-left:0.5em;
			color:#232d4f;
		}

.about-dialog > div table{
			padding:1em;
			width:100%;
		}

.about-dialog > div table tr > td:first-child{
				font-weight:bold;
			}

.about-dialog .error{
		color:red;
	}

.LocusLabs.-mobile .search-container{
	left:0;
	top:0;
	width:calc(100%);
	max-height:100%;
}

.LocusLabs.-mobile .search-container .button{
		height:42px;
		border-radius:0;
		padding:1.000em;
	}

.LocusLabs.-mobile .search-container .scrolling-container{
		max-height:100%;
	}

.LocusLabs.-mobile .search-container .scrolling-container.-hasTerm{
			padding-bottom:42px;
		}

.LocusLabs.-mobile .search-container .scrolling-container .scroll-hidden{
			overflow:hidden;
		}

.LocusLabs.-mobile .search-container .show-results-map-button{
		position:absolute;
		bottom:0;
		width:100%;
	}

.LocusLabs 	.mobileLevelIndicator{
	position:absolute;
	width:100%;
	font-size:0.75em;
	text-align:center;
	padding:0.375em;
}

body.-LLfullpage .LocusLabs.-mobile .search-container .show-results-map-button,
	body.-LLfullpage .LocusLabs.-mobile .mobileLevelIndicator{
		position:fixed;
	}

.LocusLabs .mobileSearch-Header{
	position:absolute;
	top:0;
	left:0;
	width:100%;
}

	.LocusLabs .mobileSearch-Header > .topHeader{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-pack:end;
		    justify-content:flex-end;
	}

	.LocusLabs .mobileSearch-Header > .topHeader > .global-icon-logo{
			max-width:150px;
			height:40px;
			margin:5px auto 5px 0.5em;
		}

	.LocusLabs .mobileSearch-Header > .topHeader > .global-icon-logo > svg{
				height:40px;
				width:auto;
			}

	.LocusLabs .mobileSearch-Header > .topHeader > input{
			border-right:0;
		}

	.LocusLabs .mobileSearch-Header > .topHeader > .icon{
			width:1.875em;
			height:1.875em;
			margin:0.5em;
		}

	.LocusLabs .mobileSearch-Header > .topHeader > .icon.global-icon-logo{
				width:150px;
				height:40px;
				margin:5px auto 5px 0.5em;
			}

body.-LLfullpage .LocusLabs .mobileSearch-Header{
		position:fixed;
	}
.LocusLabs .onsite-admin .-floatingContainer.-dialog{
		left:50%;
		top:20%;
		margin-left:-200px;
		width:400px;
		padding:3em;
	}
	.LocusLabs .onsite-admin .dialog-entry-field label{
			text-transform:uppercase;
			font-size:0.65em;
			font-weight:500;
			display:block;
			margin-bottom:0.25em;
		}
	.LocusLabs .onsite-admin .dialog-entry-field .input-wrapper{
			display:-ms-flexbox;
			display:flex;
			-ms-flex-pack:start;
			    justify-content:flex-start;
			border-width:1px;
			border-style:solid;
			border-radius:2px;
		}
	.LocusLabs .onsite-admin .dialog-entry-field .input-wrapper .icon{
				padding:4px 6px;
			}
	.LocusLabs .onsite-admin .dialog-entry-field .input-wrapper .keyboard-input{
				display:-ms-flexbox;
				display:flex;
				width:100%;
			}
	.LocusLabs .onsite-admin .dialog-entry-field .input-wrapper input{
				border:none;
				width:100%;
				background:transparent;
				outline:none;
				padding:4px 8px;
			}
	.LocusLabs .onsite-admin .exit-button,
	.LocusLabs .onsite-admin .back-button{
		position:absolute;
		bottom:1em;
		left:1em;
		z-index:10;
	}
	.LocusLabs .onsite-admin .button{
		display:-ms-flexbox;
		display:flex;
		margin-top:1em;
		padding:0.5em 1em;
		height:48px;
		font-size:1em;
		font-weight:700;
		-webkit-box-shadow:0 2px 6px 0 rgba(0, 0, 0, 0.3);
		        box-shadow:0 2px 6px 0 rgba(0, 0, 0, 0.3);
		-ms-flex-align:center;
		    align-items:center;
		text-rendering:geometricPrecision;
	}
	.LocusLabs .onsite-admin .editDeleteButtons{
		position:absolute;
		bottom:1em;
		right:1em;
		z-index:10;
		display:-ms-flexbox;
		display:flex;
	}
	.LocusLabs .onsite-admin .editDeleteButtons > div.delete-button{
			margin-left:1em;
			background:#dc4747;
		}
	.LocusLabs .onsite-admin .directions{
		top:1em;
		left:1em;
		width:400px;
		padding:1.5em;
	}
	.LocusLabs .onsite-admin .directions .title{
			font-size:1.5em;
			font-weight:900;
			margin-bottom:0.75em;
		}
	.LocusLabs .onsite-admin .directions .body{
			line-height:1.7;
		}
	.LocusLabs .onsite-admin .kioskPin{
		position:absolute;
		left:50%;
		top:50%;
		margin-left:calc(225px / -2);
		margin-top:-104px;
		z-index:20;
		height:44px;
	}
	.LocusLabs .onsite-admin .kioskPin > .icon{
			pointer-events:none;
		}
.LocusLabs .overlay-container{
	z-index:100;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
	background-color:rgba(0, 0, 0, 0.5);
}

	.LocusLabs .overlay-container .overlay-content{
		width:440px;
		height:auto;
		border-radius:2px;
		position:absolute;
		top:25%;
		left:0;
		right:0;
		opacity:0;
		margin:auto;
		font-weight:500;
		-webkit-animation:fade-in-animation 0.5s ease-out forwards;
		        animation:fade-in-animation 0.5s ease-out forwards;
		-webkit-animation-delay:0.1s;
		        animation-delay:0.1s;
	}

	.LocusLabs .overlay-container .overlay-content.-narrow{
			width:288px;
		}

	.LocusLabs .overlay-container .overlay-content > .muchPadding{
			padding:2em 2em 1.5rem 2em;
		}

	.LocusLabs .overlay-container .overlay-content > .overlay-title{
			height:54px;
			padding:18px 0;
			border-radius:2px 2px 0 0;
			vertical-align:middle;
			text-align:center;
		}

	.LocusLabs .overlay-container .overlay-content .dialogQuestion{
			font-weight:900;
			font-size:1.5em;
		}

	.LocusLabs .overlay-container .overlay-content .buttons{
			display:-ms-flexbox;
			display:flex;
			margin-top:1em;
		}

	.LocusLabs .overlay-container .overlay-content .buttons > .rightSide{
				margin-left:auto;
			}

	.LocusLabs .overlay-container .overlay-content .button{
			height:48px;
			padding:16px;
			border-radius:2px;
			border-width:1px;
			border-style:solid;
			border-color:#ebebeb;
			font-weight:bold;
			min-width:150px;
		}

	.LocusLabs .overlay-container .overlay-content .button.-destructive{
				background:#dc4747;
				color:white;
			}
.LocusLabs .navigation-dialog{
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
	padding-bottom:4px;
}

	.LocusLabs .navigation-dialog .use-current-location-div .use-current-location{
			display:-ms-flexbox;
			display:flex;
			-ms-flex-align:center;
			    align-items:center;
			margin:1em;
			-ms-flex-pack:center;
			    justify-content:center;
		}

	.LocusLabs .navigation-dialog .use-current-location-div .use-current-location .icon{
				padding:0.375em;
				width:30px;
				height:30px;
			}

	.LocusLabs .navigation-dialog .use-current-location-div .use-current-location .text-label{
				font-size:0.750em;
			}

	.LocusLabs .navigation-dialog .icon.global-icon-close{
		padding-right:0.5em;
	}

	.LocusLabs .navigation-dialog .navigation-title{
		width:100%;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-direction:row;
		    flex-direction:row;
		padding:1em;
		-ms-flex-align:center;
		    align-items:center;
		-ms-flex:none;
		    flex:none;
	}

	.LocusLabs .navigation-dialog .navigation-title > .title{
			-ms-flex:1 1;
			    flex:1 1;
			font-size:1.125em;
			font-weight:500;
			margin-left:1em;
		}

	.LocusLabs .navigation-dialog .navigation-title > .icon:first-child{
				border-radius:0.125em;
			}

	.LocusLabs .navigation-dialog .navigation-title > .icon:first-child svg path{
					fill:undefined;
				}

	.LocusLabs .navigation-dialog .scrolling-container{
		-ms-flex-positive:1;
		    flex-grow:1;
		overflow:auto;
		max-height:40vh;
	}

	.LocusLabs .navigation-dialog .routeSelection{
		display:-ms-flexbox;
		display:flex;
		-ms-flex:none;
		    flex:none;
	}

	.LocusLabs .navigation-dialog .routeSelection > div{
			-ms-flex:1 1;
			    flex:1 1;
			margin:1em;
			padding:0.75em;
			border-radius:999px;
			display:-ms-flexbox;
			display:flex;
			-ms-flex-pack:center;
			    justify-content:center;
		}

	.LocusLabs .navigation-dialog .routeSelection > div > .icon{
				margin-right:0.5em;
			}

	.LocusLabs .navigation-dialog .routeSelection > div .textLabel{
				position:relative;
				top:0.2em;
			}

	.LocusLabs .navigation-dialog .summary{
		padding:1em;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-align:center;
		    align-items:center;
		-ms-flex:none;
		    flex:none;
	}

	.LocusLabs .navigation-dialog .summary > .icon{
			width:30px;
		}

	.LocusLabs .navigation-dialog .summary > .textLabel{
			margin-left:1.14em;
			font-size:0.875em;
		}

	.LocusLabs .navigation-dialog .summary > .textLabel > .timeAndFloors{
				font-style:italic;
			}

	.LocusLabs .navigation-dialog .summary > .textLabel > .timeAndFloors > .time{
					font-style:normal;
					font-size:1.143em;
					font-weight:500;
					margin-right:0.5em;
				}

	.LocusLabs .navigation-dialog .prevNext{
		display:-ms-flexbox;
		display:flex;
		-ms-flex:none;
		    flex:none;
		-ms-flex-align:center;
		    align-items:center;
		text-transform:uppercase;
		border-radius:0 !important;
		padding:0 !important;
	}

	.LocusLabs .navigation-dialog .prevNext > div{
			-ms-flex:1 1;
			    flex:1 1;
			display:-ms-flexbox;
			display:flex;
			-ms-flex-pack:center;
			    justify-content:center;
			-ms-flex-align:center;
			    align-items:center;
			padding:0.25em;
			cursor:pointer;
		}

	.LocusLabs .navigation-dialog .prevNext > div:first-child{
				border-right:1px solid;
			}

	.LocusLabs .navigation-dialog .prevNext > div > .icon{
				margin:0.5em;
			}

	.LocusLabs .navigation-dialog .prevNext > div.-ghosted{
				cursor:default;
			}

	.LocusLabs .navigation-dialog .icon.dir-vertical-line svg{
		width:4px;
		height:10px;
	}

	.LocusLabs .navigation-dialog .resetSearch{
		padding:0em 2em 1.5em 1em;
	}

	.LocusLabs .navigation-dialog .pois-selected{
		-ms-flex:none;
		    flex:none;
	}

	.LocusLabs .navigation-dialog .pois-selected > .poi{
			display:-ms-flexbox;
			display:flex;
			-ms-flex-align:center;
			    align-items:center;
			padding:1em;
		}

	.LocusLabs .navigation-dialog .pois-selected > .poi > div.icon{
				margin-right:1em;
				width:30px;
			}

	.LocusLabs .navigation-dialog .pois-selected > .poi > div.icon._pull-right{
					margin-right:0;
				}

	.LocusLabs .navigation-dialog .pois-selected > .poi > .textLabel > .title{
				font-weight:500;
				font-size:1em;
			}

	.LocusLabs .navigation-dialog .pois-selected > .poi > .textLabel > .desc{
				font-size:0.85em;
			}

	.LocusLabs .navigation-dialog .computingNotification{
		position:relative;
		height:180px;
		display:-ms-flexbox;
		display:flex;
		-ms-flex-direction:column;
		    flex-direction:column;
	}

	.LocusLabs .navigation-dialog .computingNotification > .loadingIndicatorWrapper{
			-ms-flex-direction:column;
			    flex-direction:column;
			position:static;
			background-color:inherit;
		}

	.LocusLabs .navigation-dialog .computingNotification > .loadingIndicatorWrapper > .icon{
				margin-bottom:0;
			}

	.LocusLabs .navigation-dialog .computingNotification > .loadingIndicatorWrapper > .textMessage{
				background-color:inherit;
				text-align:center;
				padding:20px 0 40px;
				font-size:0.75em;
				-ms-flex:1 1;
				    flex:1 1;
				width:auto;
			}

	.LocusLabs .navigation-dialog ._pull-right{
		margin-left:auto;
	}

	.LocusLabs .navigation-dialog ._pull-left{
		margin-left:4.000em;
		-ms-flex-pack:justify !important;
		    justify-content:space-between !important;
	}

.LocusLabs.-mobile .navigation-dialog{
	padding:0;
}

.LocusLabs.-mobile .navigation-dialog.-mobileFull{
		pointer-events:none;
	}

.LocusLabs.-mobile .navigation-dialog.-mobileFull > .navigation-stepByStepMobile,
		.LocusLabs.-mobile .navigation-dialog.-mobileFull > .navigation-entry{
			pointer-events:all;
		}

.LocusLabs.-mobile .navigation-dialog .summary{
		top:0;
		position:absolute;
		width:100%;
		padding:0;
	}

.LocusLabs.-mobile .navigation-dialog .summary > .icon{
			-ms-flex:none;
			    flex:none;
			margin:10px 16px;
		}

.LocusLabs.-mobile .navigation-dialog .summary > .summary-info{
			-ms-flex:1 1;
			    flex:1 1;
			overflow:hidden;
		}

.LocusLabs.-mobile .navigation-dialog .summary > .summary-info > span{
				overflow:hidden;
				white-space:nowrap;
				-o-text-overflow:ellipsis;
				   text-overflow:ellipsis;
			}

.LocusLabs.-mobile .navigation-dialog .summary .title{
			display:block;
			font-weight:500;
		}

.LocusLabs.-mobile .navigation-dialog .summary .subtitle{
			display:block;
			font-size:0.75em;
			font-style:italic;
			color:#666666;
		}

.LocusLabs .navigation-stepByStep{
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
	-ms-flex-positive:1;
	    flex-grow:1;
	overflow:hidden;
}

.LocusLabs .navigation-stepByStep > .transferButton{
		padding:0.5em;
	}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){

.LocusLabs .navigation-stepByStep{
		max-height:50vh
}
	}
.LocusLabs .qrcode-dialog{
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
}

	.LocusLabs .qrcode-dialog > p{
		margin:1em;
		text-align:center;
	}

	.LocusLabs .qrcode-dialog > p.secondaryText{
			font-weight:300;
		}

	.LocusLabs .qrcode-dialog > p.url{
			font-size:0.8em;
			margin-bottom:0;
		}

	.LocusLabs .qrcode-dialog > .codeImage{
		position:relative;
		height:160px;
		text-align:center;
	}

	.LocusLabs .qrcode-dialog > .codeImage > .qrcode{
			background-blend-mode:lighten;
			background-repeat-x:no-repeat;
			background-repeat-y:no-repeat;
			width:160px;
			height:160px;
			margin:auto;
		}

	.LocusLabs .qrcode-dialog > .codeImage > .error{
			font-weight:normal;
			margin-top:60px;
		}
.LocusLabs .navigation-entry{
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	    flex-direction:column;
	-ms-flex-positive:1;
	    flex-grow:1;
}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){.LocusLabs .navigation-entry{
		height:80vh
}
	}

	.LocusLabs .navigation-entry .dividerLine{
		position:absolute;
		width:1px;
		height:52px;
		border-right:1px solid currentColor;
		top:46px;
		left:15px;
	}

	.LocusLabs .navigation-entry > .navInputs-section{
		padding:12px;
		-ms-flex:none;
		    flex:none;
	}

	.LocusLabs .navigation-entry > .navInputs-section > .navInputs-top{
			display:-ms-flexbox;
			display:flex;
			-ms-flex-align:center;
			    align-items:center;
			position:relative;
		}

	.LocusLabs .navigation-entry > .navInputs-section > .navInputs-top > div{
				-ms-flex:1 1;
				    flex:1 1;
			}

	.LocusLabs .navigation-entry > .navInputs-section > .navInputs-top .navInputs-swap{
				-ms-flex:none;
				    flex:none;
				margin:0 0.5em;
			}

.LocusLabs.-mobile .navigation-entry{
	overflow:visible;
	-ms-flex:1 1;
	    flex:1 1;
}

.LocusLabs.-mobile .navigation-entry > .navInputs-section{
		padding:0 0 8px 0;
	}

.LocusLabs.-mobile .navigation-entry > .navInputs-section .dividerLine{
			top:36px;
			height:22px;
		}

.LocusLabs.-mobile .navigation-entry > .navInputs-section .navInputs-swap > .icon{
				padding-bottom:20px;
			}

.LocusLabs.-mobile .navigation-entry > .navInputs-section .navInputs-swap > .dir-icon-swap{
				padding-bottom:10px;
			}
.poi-input{
	margin:10px 0 30px 0;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-align:end;
	    align-items:flex-end;
	width:100%;
	height:42px;
}

	.poi-input > .icon.starting-point,
	.poi-input .icon.destination-point{
		margin:0 8px 8px 10px;
	}

	.poi-input:last-child{
		margin-bottom:1em;
	}

	.poi-input > .dir-selection-indicator{
		width:30px;
		margin:0 0.5em;
		-ms-flex:none;
		    flex:none;
	}

	.poi-input > .dir-icon-search{
		border-bottom-width:2px;
		border-bottom-style:solid;
		padding:4px;
		width:1.6em;
	}

	.poi-input > .global-icon-close{
		padding:4px;
		border-bottom-width:2px;
		border-bottom-style:solid;
	}

	.poi-input .extraTopPadding{
		margin-top:0.6em;
		height:30px;
	}

	.poi-input > .global-icon-back{
		border-bottom-width:2px;
		border-bottom-style:solid;
	}

	.poi-input input::-ms-clear{
		display:none;
	}

	.poi-input > label{
		-ms-flex:1 1;
		    flex:1 1;
	}

	.poi-input > label > .label{
			font-size:0.75em;
			text-transform:uppercase;
			font-weight:500;
			margin-bottom:6px;
		}

	.poi-input > label ::-webkit-input-placeholder{
			font-style:italic;
		}

	.poi-input > label :-moz-placeholder{
			font-style:italic;
		}

	.poi-input > label ::-moz-placeholder{
			font-style:italic;
		}

	.poi-input > label :-ms-input-placeholder{
			font-style:italic;
		}

	.poi-input > label input{
			border-radius:0;
			margin:0;
			border:0;
			background:transparent;
			border-bottom:2px solid;
			display:block;
			width:100%;
			-ms-flex:1 1;
			    flex:1 1;
			font-weight:300;
			line-height:1.4;
		}

	.poi-input > label input:focus{
				outline:none;
			}

	.poi-input > label input::-webkit-input-placeholder{
				color:#b1b1b1;
			}

	.poi-input > label input::-moz-placeholder{
				color:#b1b1b1;
			}

	.poi-input > label input:-ms-input-placeholder{
				color:#b1b1b1;
			}

	.poi-input > label input::placeholder{
				color:#b1b1b1;
			}

	.poi-input > .reset{
		cursor:pointer;
		padding-right:0.313em;
	}

	.poi-input .dir-selection-indicator{
		margin-left:0;
	}

	.poi-input .dir-selection-indicator .centerDot{
			visibility:hidden;
		}

	.poi-input.-active > .dir-selection-indicator .centerDot{
			visibility:visible;
			-webkit-animation:pulse-opacity 800ms infinite;
			        animation:pulse-opacity 800ms infinite;
			-webkit-animation-direction:alternate;
			        animation-direction:alternate;
		}

	.poi-input.-active > input{
			border-bottom:2px solid;
		}

	.poi-input.-confirmed > .dir-selection-indicator .centerDot{
			visibility:visible;
		}

	.poi-input.-confirmed input{
			border-bottom-width:2px;
		}

.LocusLabs.-mobile .poi-input{
	margin-bottom:10px;
	height:32px;
}
.map-container > div.loadingIndicatorWrapper > div.loading-indicator{
			width:auto;
			height:auto;
		}
	.map-container > .map-logo{
		position:absolute;
		right:0;
		bottom:0;
		margin:1em;
		width:144px;
		line-height:10px;
		text-align:left;
		color:rgba(0, 0, 0, 0.3) !important;
	}
	.map-container > .map-logo > span{
			font-size:0.6em;
			font-weight:600;
			white-space:nowrap;
		}

.-logoUpperLeft .map-logo{
	top:0;
	left:0;
	right:auto;
	bottom:auto;
}

.-logoUpperRight .map-logo{
	top:0;
	bottom:auto;
}

.-logoBottomLeft .map-logo{
	left:0;
	right:auto;
}

.LocusLabs.-mobile > .map-container > .map-logo{
	top:64px;
	right:0;
	bottom:auto;
	left:auto;
	width:75px;
}

.LocusLabs.-mobile > .map-container > .map-logo span{
		display:none;
	}

.LocusLabs .language-selector{
	list-style:none;
	margin:0;
	padding:0;
}

	.LocusLabs .language-selector > .menu-item{
		height:62px;
		padding:22px 16px;
		cursor:pointer;
		-webkit-user-select:none;
		   -moz-user-select:none;
		    -ms-user-select:none;
		        user-select:none;
	}

	.LocusLabs .language-selector > .menu-item > .icon{
			float:right;
		}
.notification-container{
	position:absolute;
	z-index:20;
	height:30px;
	min-width:240px;
	top:1em;
	left:50%;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	display:-ms-flexbox;
	display:flex;
	-webkit-box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	        box-shadow:0 0 24px 0 rgba(0, 0, 0, 0.3);
	border-radius:4px;
	padding:1.5rem 3em;
	text-align:center;
	pointer-events:none;
	text-transform:uppercase;
	font-weight:900;
	-ms-flex-align:center;
	    align-items:center;
}

	.notification-container .icon{
		display:inline-block;
		margin-right:0.5em;
		vertical-align:baseline;
		-ms-flex:none;
		    flex:none;
	}

.LocusLabs.-mobile .notification-container{
	position:absolute;
	top:75px;
	bottom:auto;
}


@font-face{
	font-family:Roboto;
	src:local('Roboto Thin'), local('Roboto-Thin'), url("//fonts.locuslabs.com/roboto/Roboto-Thin.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Thin.woff?v=2.137") format("woff");
	font-weight:100;
	font-style:normal; }

@font-face{
	font-family:Roboto;
	src:local('Roboto ThinItalic'), local('Roboto-ThinItalic'), url("//fonts.locuslabs.com/roboto/Roboto-ThinItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-ThinItalic.woff?v=2.137") format("woff");
	font-weight:100;
	font-style:italic; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Light'), local('Roboto-Light'), url("//fonts.locuslabs.com/roboto/Roboto-Light.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Light.woff?v=2.137") format("woff");
	font-weight:300;
	font-style:normal; }

@font-face{
	font-family:Roboto;
	src:local('Roboto LightItalic'), local('Roboto-LightItalic'), url("//fonts.locuslabs.com/roboto/Roboto-LightItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-LightItalic.woff?v=2.137") format("woff");
	font-weight:300;
	font-style:italic; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Regular'), local('Roboto-Regular'), url("//fonts.locuslabs.com/roboto/Roboto-Regular.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Regular.woff?v=2.137") format("woff");
	font-weight:400;
	font-style:normal; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Regular'), local('Roboto-Regular'), url("//fonts.locuslabs.com/roboto/Roboto-Regular.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Regular.woff?v=2.137") format("woff");
	font-weight:normal;
	font-style:normal; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Italic'), local('Roboto-Italic'), url("//fonts.locuslabs.com/roboto/Roboto-Italic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Italic.woff?v=2.137") format("woff");
	font-weight:400;
	font-style:italic; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Italic'), local('Roboto-Italic'), url("//fonts.locuslabs.com/roboto/Roboto-Italic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Italic.woff?v=2.137") format("woff");
	font-weight:normal;
	font-style:italic; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Medium'), local('Roboto-Medium'), url("//fonts.locuslabs.com/roboto/Roboto-Medium.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Medium.woff?v=2.137") format("woff");
	font-weight:500;
	font-style:normal; }

@font-face{
	font-family:Roboto;
	src:local('Roboto MediumItalic'), local('Roboto-MediumItalic'), url("//fonts.locuslabs.com/roboto/Roboto-MediumItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-MediumItalic.woff?v=2.137") format("woff");
	font-weight:500;
	font-style:italic; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Bold'), local('Roboto-Bold'), url("//fonts.locuslabs.com/roboto/Roboto-Bold.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Bold.woff?v=2.137") format("woff");
	font-weight:700;
	font-style:normal; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Bold'), local('Roboto-Bold'), url("//fonts.locuslabs.com/roboto/Roboto-Bold.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Bold.woff?v=2.137") format("woff");
	font-weight:bold;
	font-style:normal; }

@font-face{
	font-family:Roboto;
	src:local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url("//fonts.locuslabs.com/roboto/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-BoldItalic.woff?v=2.137") format("woff");
	font-weight:700;
	font-style:italic; }

@font-face{
	font-family:Roboto;
	src:local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url("//fonts.locuslabs.com/roboto/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-BoldItalic.woff?v=2.137") format("woff");
	font-weight:bold;
	font-style:italic; }

@font-face{
	font-family:Roboto;
	src:local('Roboto Black'), local('Roboto-Black'), url("//fonts.locuslabs.com/roboto/Roboto-Black.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Black.woff?v=2.137") format("woff");
	font-weight:900;
	font-style:normal; }

@font-face{
	font-family:Roboto;
	src:local('Roboto BlackItalic'), local('Roboto-BlackItalic'), url("//fonts.locuslabs.com/roboto/Roboto-BlackItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-BlackItalic.woff?v=2.137") format("woff");
	font-weight:900;
	font-style:italic; }

:root{
}

:root .LocusLabs .text-success{ color:#8dd119; }

:root .LocusLabs .text-warning{ color:#E93232; }

:root .LocusLabs .text-danger{ color:#E93232; }

:root .LocusLabs .bg-success{ background-color:#8dd119; }

:root .LocusLabs .bg-warning{ background-color:#E93232; }

:root .LocusLabs .bg-danger{ background-color:#E93232; }

:root .LocusLabs .border-success{ border-color:#8dd119; }

:root .LocusLabs .border-warning{ border-color:#E93232; }

:root .LocusLabs .border-danger{ border-color:#E93232; }

.LocusLabs.text-primary{ color:#333; }

.LocusLabs .text-primary{ color:#333; }

.LocusLabs .text-primary-button{ color:white; }

.LocusLabs .text-primary-container{ color:white; }

.LocusLabs .text-primary-expandable-list-item{ color:white; }

.LocusLabs .text-secondary{ color:white; }

.LocusLabs .text-secondary-button{ color:white; }

.LocusLabs .text-alt{ color:white; }

.LocusLabs .text-info{ color:#4E9EFF; }

.LocusLabs .text-accent{ color:#4E9EFF; }

.LocusLabs .text-accent2{ color:#4E9EFF; }

.LocusLabs .text-accent-hover:hover{ color:#4E9EFF; }

.LocusLabs .text-muted{ color:#808080; }

.LocusLabs .text-subdued{ color:#666; }

.LocusLabs .text-active-expandable-list-item{ color:white; }

.LocusLabs .text-active-button{ color:white; }

.LocusLabs .text-disabled-button{ color:#f6f6f6; }

.LocusLabs .text-for-emphasizedBG{ color:#999999; }

.LocusLabs .text-important{ color:white; }

.LocusLabs .placeholderCurrentColor::-webkit-input-placeholder{ color:currentColor }

.LocusLabs .placeholderCurrentColor::-moz-placeholder{ color:currentColor }

.LocusLabs .placeholderCurrentColor:-ms-input-placeholder{ color:currentColor }

.LocusLabs .placeholderCurrentColor::placeholder{ color:currentColor }

.LocusLabs .placeholderCurrentColor::-webkit-input-placeholder{ color:currentColor }

.LocusLabs .placeholderCurrentColor:-ms-input-placeholder{ color:currentColor }

.LocusLabs .stroke-primary{ stroke:#bbdaff; }

.LocusLabs .stroke-secondary{ stroke:#4E9EFF; }

.LocusLabs .fill-primary{ fill:#bbdaff; }

.LocusLabs .fill-secondary{ fill:#4E9EFF; }

.LocusLabs .bg-primary{ background-color:white; }

.LocusLabs .bg-primary-button{ background-color:#808080; }

.LocusLabs .bg-primary-container{ background-color:rgba(51, 51, 51, 0.96); }

.LocusLabs .bg-primary-expandable-list-item{ background-color:rgba(83, 83, 83, 0.98); }

.LocusLabs .bg-secondary{ background-color:#333; }

.LocusLabs .bg-secondary-button{ background-color:#333; }

.LocusLabs .bg-secondary-container{ background-color:white; }

.LocusLabs .bg-secondary-expandable-list-item{ background-color:#333; }

.LocusLabs .bg-alt{ background-color:rgba(0, 0, 0, 0.75); }

.LocusLabs .bg-alt-hover:hover{ background-color:rgba(0, 0, 0, 0.75); }

.LocusLabs .bg-info{ background-color:#4E9EFF; }

.LocusLabs .bg-accent{ background-color:#4E9EFF; }

.LocusLabs .bg-accent2{ background-color:#4E9EFF; }

.LocusLabs .bg-accent-hover:hover{ background-color:#4E9EFF; }

.LocusLabs .bg-ghosted{ background-color:#f6f6f6; }

.LocusLabs .bg-faded{ background-color:#ebebeb; }

.LocusLabs .bg-faded-hover:hover{ background-color:#ebebeb; }

.LocusLabs .bg-muted{ background-color:#808080; }

.LocusLabs .bg-subdued{ background-color:#666; }

.LocusLabs .bg-active{ background-color:#4E9EFF; }

.LocusLabs .bg-active-expandable-list-item{ background-color:#333; }

.LocusLabs .bg-active-button{ background-color:#4E9EFF; }

.LocusLabs .bg-disabled{ background-color:#ebebeb; }

.LocusLabs .bg-disabled-button{ background-color:#ebebeb; }

.LocusLabs .bg-mobile-header{ background-color:#4E9EFF; }

.LocusLabs .bg-emphasized{ background-color:#535353; }

.LocusLabs .bg-important{ background-color:#E93232; }

.LocusLabs .bg-status{ background-color:#3FC25E; }

.LocusLabs .bg-green{ background-color:#3FC25E }

.LocusLabs .bg-gray{ background-color:#666 }

.LocusLabs .bg-orange{ background-color:#FA8709 }

.LocusLabs .bg-red{ background-color:#E93232 }

.LocusLabs .border-primary{ border-color:#ebebeb; }

.LocusLabs .border-alt{ border-color:rgba(0, 0, 0, 0.75); }

.LocusLabs .border-info{ border-color:#4E9EFF; }

.LocusLabs .border-accent{ border-color:#4E9EFF; }

.LocusLabs .border-accent2{ border-color:#4E9EFF; }

.LocusLabs .border-inverse{ border-color:#ebebeb; }

.LocusLabs .border-faded{ border-color:#ebebeb; }

.LocusLabs .border-ghosted{ border-color:#f6f6f6; }

.LocusLabs .border-muted{ border-color:#808080; }

.LocusLabs .border-active{ border-color:#4E9EFF; }

.LocusLabs .border-disabled{ border-color:#ebebeb; }

.LocusLabs .border-important{ border-color:white; }

.LocusLabs .list-item{
				background-color:white;
				border-bottom:1px solid #ebebeb;
			}

.LocusLabs .list-item:hover{ background-color:#ebebeb; }

.LocusLabs .list-item.-current{
					background-color:#f6f6f6;
					border-left:2px solid #4E9EFF;
				}

.LocusLabs .divider{
		color:#cccccc;
		border:#cccccc;
	}

.LocusLabs .dir-pin-start{
		fill:#243e82;
	}

.LocusLabs .dir-pin-end{
		fill:#8dd119;
	}

@-webkit-keyframes buttonToTitle{
	0%{
		margin:0 1rem 1rem 1em;
		height:3em;
		width:calc(100% - 2rem);
	}
	100%{
		margin:0;
		height:1.5em;
		width:100%;
	}
}

@keyframes buttonToTitle{
	0%{
		margin:0 1rem 1rem 1em;
		height:3em;
		width:calc(100% - 2rem);
	}
	100%{
		margin:0;
		height:1.5em;
		width:100%;
	}
}

@-webkit-keyframes titleToButton{
	0%{
		margin:0;
		height:1.5em;
		width:100%;
	}
	100%{
		margin:0 1rem 1rem 1em;
		height:3em;
		width:calc(100% - 2rem);
	}
}

@keyframes titleToButton{
	0%{
		margin:0;
		height:1.5em;
		width:100%;
	}
	100%{
		margin:0 1rem 1rem 1em;
		height:3em;
		width:calc(100% - 2rem);
	}
}

@-webkit-keyframes ripple-animation{
	from{ -webkit-transform:scale(0.25); transform:scale(0.25); opacity:0.3; }
	to{ -webkit-transform:scale(100); transform:scale(100); opacity:0; }
}

@keyframes ripple-animation{
	from{ -webkit-transform:scale(0.25); transform:scale(0.25); opacity:0.3; }
	to{ -webkit-transform:scale(100); transform:scale(100); opacity:0; }
}

@-webkit-keyframes pulse{
	0%{ opacity:0; }
	10%{ opacity:1; }
	90%{ opacity:0.25; }
	100%{ opacity:0; }
}

@keyframes pulse{
	0%{ opacity:0; }
	10%{ opacity:1; }
	90%{ opacity:0.25; }
	100%{ opacity:0; }
}

@-webkit-keyframes pulse-opacity{
	from{ opacity:0; }
	to{ opacity:1; }
}

@keyframes pulse-opacity{
	from{ opacity:0; }
	to{ opacity:1; }
}

@-webkit-keyframes blink{
	0%{ opacity:0; }
	50%{ opacity:1;}
	100%{ opacity:0; }
}

@keyframes blink{
	0%{ opacity:0; }
	50%{ opacity:1;}
	100%{ opacity:0; }
}

@-webkit-keyframes outer-most-loading-indicator{
	0%{
		-webkit-transform:translate(60px, 60px) rotateZ(0deg);
		        transform:translate(60px, 60px) rotateZ(0deg)
	}
	10%{
		-webkit-transform:translate(57px, 55px) rotateZ(36deg);
		        transform:translate(57px, 55px) rotateZ(36deg)
	}
	20%{
		-webkit-transform:translate(58px, 48px) rotateZ(72deg);
		        transform:translate(58px, 48px) rotateZ(72deg)
	}
	30%{
		-webkit-transform:translate(62.5px, 44px) rotateZ(108deg);
		        transform:translate(62.5px, 44px) rotateZ(108deg)
	}
	40%{
		-webkit-transform:translate(69px, 43px) rotateZ(144deg);
		        transform:translate(69px, 43px) rotateZ(144deg)
	}
	50%{
		-webkit-transform:translate(74px, 46px) rotateZ(180deg);
		        transform:translate(74px, 46px) rotateZ(180deg)
	}
	60%{
		-webkit-transform:translate(77px, 51px) rotateZ(216deg);
		        transform:translate(77px, 51px) rotateZ(216deg)
	}
	70%{
		-webkit-transform:translate(76px, 57px) rotateZ(252deg);
		        transform:translate(76px, 57px) rotateZ(252deg)
	}
	80%{
		-webkit-transform:translate(72px, 62px) rotateZ(288deg);
		        transform:translate(72px, 62px) rotateZ(288deg)
	}
	90%{
		-webkit-transform:translate(65.5px, 63px) rotateZ(324deg);
		        transform:translate(65.5px, 63px) rotateZ(324deg)
	}
	100%{
		-webkit-transform:translate(60px, 60px) rotateZ(360deg);
		        transform:translate(60px, 60px) rotateZ(360deg)
	}
}

@keyframes outer-most-loading-indicator{
	0%{
		-webkit-transform:translate(60px, 60px) rotateZ(0deg);
		        transform:translate(60px, 60px) rotateZ(0deg)
	}
	10%{
		-webkit-transform:translate(57px, 55px) rotateZ(36deg);
		        transform:translate(57px, 55px) rotateZ(36deg)
	}
	20%{
		-webkit-transform:translate(58px, 48px) rotateZ(72deg);
		        transform:translate(58px, 48px) rotateZ(72deg)
	}
	30%{
		-webkit-transform:translate(62.5px, 44px) rotateZ(108deg);
		        transform:translate(62.5px, 44px) rotateZ(108deg)
	}
	40%{
		-webkit-transform:translate(69px, 43px) rotateZ(144deg);
		        transform:translate(69px, 43px) rotateZ(144deg)
	}
	50%{
		-webkit-transform:translate(74px, 46px) rotateZ(180deg);
		        transform:translate(74px, 46px) rotateZ(180deg)
	}
	60%{
		-webkit-transform:translate(77px, 51px) rotateZ(216deg);
		        transform:translate(77px, 51px) rotateZ(216deg)
	}
	70%{
		-webkit-transform:translate(76px, 57px) rotateZ(252deg);
		        transform:translate(76px, 57px) rotateZ(252deg)
	}
	80%{
		-webkit-transform:translate(72px, 62px) rotateZ(288deg);
		        transform:translate(72px, 62px) rotateZ(288deg)
	}
	90%{
		-webkit-transform:translate(65.5px, 63px) rotateZ(324deg);
		        transform:translate(65.5px, 63px) rotateZ(324deg)
	}
	100%{
		-webkit-transform:translate(60px, 60px) rotateZ(360deg);
		        transform:translate(60px, 60px) rotateZ(360deg)
	}
}

@-webkit-keyframes inner-loading-indicator{
	0%{ stroke-dashoffset:187; }
	25%{ stroke-dashoffset:80; }
	100%{ stroke-dashoffset:187; -webkit-transform:rotate(360deg); transform:rotate(360deg); }
}

@keyframes inner-loading-indicator{
	0%{ stroke-dashoffset:187; }
	25%{ stroke-dashoffset:80; }
	100%{ stroke-dashoffset:187; -webkit-transform:rotate(360deg); transform:rotate(360deg); }
}

@-webkit-keyframes outer-loading-indicator{
	0%{ stroke-dashoffset:312; -webkit-transform:rotate(70deg); transform:rotate(70deg); }
	60%{ stroke-dashoffset:-312; }
	100%{ stroke-dashoffset:-312; -webkit-transform:rotate(450deg); transform:rotate(450deg); }
}

@keyframes outer-loading-indicator{
	0%{ stroke-dashoffset:312; -webkit-transform:rotate(70deg); transform:rotate(70deg); }
	60%{ stroke-dashoffset:-312; }
	100%{ stroke-dashoffset:-312; -webkit-transform:rotate(450deg); transform:rotate(450deg); }
}

@-webkit-keyframes translated-circle-expansion{
	0%{
		height:6px;
		width:6px;
		margin:0 0 0 -3px;
		opacity:1
	}
	80%{
		opacity:1;
	}
	100%{
		height:6px;
		width:6px;
		margin:35px 0 0 -3px;
		opacity:0
		}
}

@keyframes translated-circle-expansion{
	0%{
		height:6px;
		width:6px;
		margin:0 0 0 -3px;
		opacity:1
	}
	80%{
		opacity:1;
	}
	100%{
		height:6px;
		width:6px;
		margin:35px 0 0 -3px;
		opacity:0
		}
}

@-webkit-keyframes overlay-animation{
	0%{
		opacity:0;
		-webkit-transform:scale(0.04) translateY(300%);
		transform:scale(0.04) translateY(300%);
	}

	40%{
		-webkit-transform:scale(0.04) translateY(0);
		transform:scale(0.04) translateY(0);
		-webkit-transition:ease-out;
		-o-transition:ease-out;
		transition:ease-out;
	}

	40%{
		-webkit-transform:scale(0.04) translateY(0);
		transform:scale(0.04) translateY(0);
	}

	50%{
		-webkit-transform:scale(0.025) translateY(0);
		transform:scale(0.025) translateY(0);
	}

	55%{
		-webkit-transform:scale(0.04) translateY(0);
		transform:scale(0.04) translateY(0);
	}

	60%{
		opacity:1;
		-webkit-transform:scale(0.035) translateY(0);
		transform:scale(0.035) translateY(0);
	}

	65%{
		-webkit-transform:scale(0.04);
		transform:scale(0.04);
	}

	99.9%{
		height:0;
		padding-bottom:100%;
		border-radius:100%;
	}

	100%{
		-webkit-transform:scale(2);
		transform:scale(2);
		height:100%;
		padding-bottom:0;
		border-radius:0;
	}
}

@keyframes overlay-animation{
	0%{
		opacity:0;
		-webkit-transform:scale(0.04) translateY(300%);
		transform:scale(0.04) translateY(300%);
	}

	40%{
		-webkit-transform:scale(0.04) translateY(0);
		transform:scale(0.04) translateY(0);
		-webkit-transition:ease-out;
		-o-transition:ease-out;
		transition:ease-out;
	}

	40%{
		-webkit-transform:scale(0.04) translateY(0);
		transform:scale(0.04) translateY(0);
	}

	50%{
		-webkit-transform:scale(0.025) translateY(0);
		transform:scale(0.025) translateY(0);
	}

	55%{
		-webkit-transform:scale(0.04) translateY(0);
		transform:scale(0.04) translateY(0);
	}

	60%{
		opacity:1;
		-webkit-transform:scale(0.035) translateY(0);
		transform:scale(0.035) translateY(0);
	}

	65%{
		-webkit-transform:scale(0.04);
		transform:scale(0.04);
	}

	99.9%{
		height:0;
		padding-bottom:100%;
		border-radius:100%;
	}

	100%{
		-webkit-transform:scale(2);
		transform:scale(2);
		height:100%;
		padding-bottom:0;
		border-radius:0;
	}
}

@-webkit-keyframes fade-in-animation{

	0%{
		opacity:0;
	}

	50%{
		opacity:0.5;
	}

	100%{
		opacity:1;
	}
}

@keyframes fade-in-animation{

	0%{
		opacity:0;
	}

	50%{
		opacity:0.5;
	}

	100%{
		opacity:1;
	}
}

@-webkit-keyframes spin{
	from{
		-webkit-transform:rotate(0deg);
		        transform:rotate(0deg);
	}

	to{
		-webkit-transform:rotate(360deg);
		        transform:rotate(360deg);
	}
}

@keyframes spin{
	from{
		-webkit-transform:rotate(0deg);
		        transform:rotate(0deg);
	}

	to{
		-webkit-transform:rotate(360deg);
		        transform:rotate(360deg);
	}
}

.LocusLabs *{
	-webkit-box-sizing:border-box;
	        box-sizing:border-box;
}

.LocusLabs,
#map,
.map-container,
.wholeMapOverlay{
	width:100%;
	height:100%;
	font-size:16px;
	overflow:hidden;
	min-height:200px;
	text-rendering:geometricPrecision;
}

.wholeMapOverlay{
	position:absolute;
	z-index:2;
	pointer-events:none;
}

.wholeMapOverlay .noMouse{
		pointer-events:none;
	}

.wholeMapOverlay .centered{
		position:absolute;
		left:50%;
		top:50%;
		margin-left:-50px;
		margin-top:-50px;
	}

.wholeMapOverlay .info{
		background:white;
		color:#333333;
		text-align:center;
		border:1px solid gray;
		padding:4px;
		bottom:0;
		position:absolute;
		left:0;
		pointer-events:all;
	}

.wholeMapOverlay .info > *{
			display:-ms-flexbox;
			display:flex;
		}

.wholeMapOverlay .info > * > *{
				margin:0 10px;
				width:80px;
			}

.wholeMapOverlay .info > * > *.-wider{
					width:200px;
				}

.LocusLabs input{
	font-size:1em;
}

.LocusLabs.-designMode div:nth-child(even){
		outline:1px dotted red;
	}

.LocusLabs.-designMode div:nth-child(odd){
		outline:1px dotted blue;
	}

#map{
	cursor:move;
}

.LocusLabs{
	position:relative;
	font-family:Roboto, sans-serif;
	font-weight:300;
}

.LocusLabs .-floatingContainer{
		position:absolute;
		z-index:10;
		-webkit-box-shadow:0 0 1em rgba(0, 0, 0, 0.3);
		        box-shadow:0 0 1em rgba(0, 0, 0, 0.3);
		border-radius:2px;
	}

.LocusLabs .-floatingContainer.-animateDown{
			-webkit-transform:translateY(-50px);
			        transform:translateY(-50px);
			opacity:0;
			-webkit-transition:all 100ms;
			-o-transition:all 100ms;
			transition:all 100ms;
			pointer-events:none;
		}

.LocusLabs .-floatingContainer.-animateUp{
			-webkit-transform:translateY(110px);
			        transform:translateY(110px);
			opacity:0;
			-webkit-transition:all 100ms;
			-o-transition:all 100ms;
			transition:all 100ms;
			pointer-events:none;
		}

.LocusLabs .-floatingContainer.-show{
			-webkit-transform:translateY(0);
			        transform:translateY(0);
			opacity:1;
			pointer-events:all;
		}

.LocusLabs .-mainDialog{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-direction:column;
		    flex-direction:column;
		width:19.375em;
		max-height:calc(100% - 100px);
		left:24px;
		top:24px;
	}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){

.LocusLabs .-mainDialog{
			max-height:none
	}
		}

.LocusLabs .-mainDialog.-extraWide{
			width:376px;
		}

.LocusLabs .-titletext{
		font-size:1.125em;
		font-weight:400;
	}

.LocusLabs .-rippleList > *{
		position:relative;
		overflow:hidden;
		-webkit-transition:background 2s;
		-o-transition:background 2s;
		transition:background 2s;
	}

.LocusLabs .-ripple{
		position:absolute;
		width:10px;
		height:10px;
		border-radius:50%;
		pointer-events:none;
		-webkit-animation:ripple-animation 1200ms forwards;
		        animation:ripple-animation 1200ms forwards;
	}

.LocusLabs button{
		padding:initial;
		margin:initial;
		border:initial;
	}

.LocusLabs .button{
		display:-ms-flexbox;
		display:flex;
		font-weight:500;
		font-size:0.875em;
		text-transform:uppercase;
		text-align:center;
		padding:0.5em;
		position:relative;
		cursor:pointer;
		overflow:hidden;
		-webkit-user-select:none;
		-ms-flex-pack:center;
		    justify-content:center;
		-ms-flex-align:center;
		    align-items:center;
		border-radius:2px;
		height:42px;
	}

.LocusLabs .button > .icon{
			margin-right:0.75em;
		}

.LocusLabs .button.-full{
			width:100%;
			padding:1em;
		}

.LocusLabs .liveDot{
		margin-right:0.5em;
	}

.LocusLabs .liveDot.-roundLabelStarter{
		margin-left:-4px;
	}

.LocusLabs .liveDot.-inline{
		display:inline-block;
	}

.LocusLabs .liveDot circle:nth-child(1){
		-webkit-animation:pulse 2s infinite;
		        animation:pulse 2s infinite;
	}

.LocusLabs .liveDot circle:nth-child(2){
		-webkit-animation:pulse 2s infinite 1s;
		        animation:pulse 2s infinite 1s;
	}

@-webkit-keyframes pulseCircle{
		0%{
			-webkit-transform:scale(0.25);
			        transform:scale(0.25);
			opacity:1;
		}

		100%{
			-webkit-transform:scale(1);
			        transform:scale(1);
			opacity:0.25;
		}
	}

@keyframes pulseCircle{
		0%{
			-webkit-transform:scale(0.25);
			        transform:scale(0.25);
			opacity:1;
		}

		100%{
			-webkit-transform:scale(1);
			        transform:scale(1);
			opacity:0.25;
		}
	}

.LocusLabs .dialog-mask{
		position:fixed;
		background:rgba(0, 0, 0, 0.3);
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:800;
	}

.LocusLabs .dialog-box{
		position:fixed;
		top:20%;
		left:50%;
		margin-left:-200px;
		-webkit-box-shadow:2px 2px 10px rgba(0, 0, 0, 0.3);
		        box-shadow:2px 2px 10px rgba(0, 0, 0, 0.3);
		width:400px;
		z-index:900;
		padding:1em;
		border-radius:2px;
	}

.LocusLabs .dialog-box.-wide{
			width:600px;
			margin-left:-300px;
		}

.LocusLabs .dialog-close{
		position:absolute;
		right:-16px;
		top:-17px;
		padding:0.75em 1em;
		font-size:0.75em;
		border-radius:99px;
		cursor:pointer;
	}

.LocusLabs ._textOneLine{
		white-space:nowrap;
	}

.LocusLabs .-bm1em{
		margin-bottom:1em;
	}

.LocusLabs .-bmp5em{
		margin-bottom:0.5em;
	}

.LocusLabs.-mobile{
}

.LocusLabs.-mobile .-mobileFull{
		position:absolute;
		top:0 !important;
		left:0 !important;
		width:100% !important;
		height:100% !important;
		max-height:none;
		overflow:auto;
		z-index:10;
	}

body.-LLfullpage{
	height:100%;
	overflow:hidden;
	padding:0;
	margin:0;
}

body.-LLfullpage .locusmaps{
		height:100%;
		width:100%;
	}

body.-LLfullpage .LocusLabs.-mobile{
		position:fixed;
		top:0;
	}

body.-LLfullpage .LocusLabs.-mobile .-mobileFull{
			position:fixed;
		}

@media screen and (max-width: 900px){
		body.-LLfullpage header{
			visibility:hidden !important;
			height:50px !important;
			border:none !important;
		}

		body.-LLfullpage .locusmaps{
			height:calc(100% - 50px) !important;
		}
}


.browser-not-supported{
	width:100%;
	height:100%;
	background-color:#333333;
	background-size:cover;
}

.accepted-browsers{
	width:413px;
	height:360px;
	border-radius:0.125em;
	background-color:#ffffff;
	margin:auto;
	position:relative;
	top:50%;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
}

.warning-icon{
	display:table;
	margin:0 auto;
	padding-top:40px;
}

.title-notSupported{
	margin-top:24px;
	margin-bottom:24px;
	font-size:22px;
	font-weight:500;
	text-align:center;
	color:#333333;
}

.textbody{
	display:table;
	margin:0 auto;
	width:333px;
	height:133px;
	font-size:16px;
	text-align:center;
	color:#535353;
}

.legacyLink{
	display:table;
	margin:1em auto;
	width:333px;
	height:133px;
	font-size:14px;
	text-align:center;
	color:#535353;
}

a{
	color:#139deb;
}
