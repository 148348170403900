.LocusLabs .suggested-locations
{
	width: 100%;
	flex: 1 1 auto;  /* Required to convince PostCSS not to convert to flex: 1 1 0% which */
	display: flex;
	flex-direction: column;

	> .waitIndicator
	{
		padding: 1em 1em 1em calc(1em + 30px);
	}

	> .title
	{
		width: 100%;
		font-size: 0.75em;
		text-transform: uppercase;
		padding: 0.5em 0;
		padding-left: calc(2em + 30px);
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
	}

	> .sorryMsg
	{
		display: flex;
		margin: 1.5em;
		align-items: center;

		> .icon
		{
			margin-right: 1em;
		}

		> .textLabel
		{
			line-height: 1.5em;

			> .title
			{
				font-size: 1.125em;
			}

			> .desc
			{
				font-size: 0.875em;
			}
		}
	}

	> .suggestionsList
	{
		flex: 1 1 auto;  /* Required to convince PostCSS not to convert to flex: 1 1 0% which */
		overflow-y: hidden;
		cursor: pointer;

		> .suggestion
		{
			display: flex;
			align-items: center;
			padding: 1em;
			border-bottom-width: 1px;
			border-bottom-style: solid;

			&:last-child
			{
				border: none;
			}

			> div.icon
			{
				margin-right: 1em;
				width: 30px;
			}

			> .textLabel > .title
			{
				font-weight: 500;
			}

			> .textLabel > .desc
			{
				font-size: 0.875em;
			}
		}
	}
}

.LocusLabs.-mobile .suggested-locations > .title
{
	font-size: 0.75em;
	/*animation: buttonToTitle 200ms;*/
}
