.navigation-segments
{
	flex: 1 1 auto;  /* Required to convince PostCSS not to convert to flex: 1 1 0% which */
	overflow: auto;

	> .segment
	{
		padding: 0.875em;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:last-child
		{
			border: none;
		}

		&.-current
		{
			> .count
			{
				opacity: 1;
				transform: scale(1);
			}
		}

		> .icon
		{
			width: 30px;
		}

		> .textLabel
		{
			flex: 1;
			margin-left: 1.14em;
			margin-right: 0.5em;

			> .secondaryText
			{
				font-size: 0.875em;
			}

			> .waypoint
			{
				font-weight: 500;
			}
		}

		> .count
		{
			opacity: 0;
			transform: scale(0.5);
			font-size: 0.875em;
			transition: transform 140ms;
		}
	}
}

.security-time
{
	font-size: 11px;
	font-weight: 400;
	text-align: center;
	color: #ffffff;
	border-radius: 100px;
	background-color: #ea3232;
	display: inline-block;
	padding: 1px 8px;
	margin-right: 5px;
	text-transform: uppercase;
}

.LocusLabs.-mobile
{
	.count
	{
		font-weight: bold;
		padding: 3px;
	}
}