/* fonts.locuslabs.com => d8gx5usz774ft.cloudfront.net */

@font-face {
	font-family: Roboto;
	src: local('Roboto Thin'), local('Roboto-Thin'), url("//fonts.locuslabs.com/roboto/Roboto-Thin.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Thin.woff?v=2.137") format("woff");
	font-weight: 100;
	font-style: normal; }
@font-face {
	font-family: Roboto;
	src: local('Roboto ThinItalic'), local('Roboto-ThinItalic'), url("//fonts.locuslabs.com/roboto/Roboto-ThinItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-ThinItalic.woff?v=2.137") format("woff");
	font-weight: 100;
	font-style: italic; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Light'), local('Roboto-Light'), url("//fonts.locuslabs.com/roboto/Roboto-Light.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Light.woff?v=2.137") format("woff");
	font-weight: 300;
	font-style: normal; }
@font-face {
	font-family: Roboto;
	src: local('Roboto LightItalic'), local('Roboto-LightItalic'), url("//fonts.locuslabs.com/roboto/Roboto-LightItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-LightItalic.woff?v=2.137") format("woff");
	font-weight: 300;
	font-style: italic; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Regular'), local('Roboto-Regular'), url("//fonts.locuslabs.com/roboto/Roboto-Regular.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Regular.woff?v=2.137") format("woff");
	font-weight: 400;
	font-style: normal; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Regular'), local('Roboto-Regular'), url("//fonts.locuslabs.com/roboto/Roboto-Regular.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Regular.woff?v=2.137") format("woff");
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Italic'), local('Roboto-Italic'), url("//fonts.locuslabs.com/roboto/Roboto-Italic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Italic.woff?v=2.137") format("woff");
	font-weight: 400;
	font-style: italic; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Italic'), local('Roboto-Italic'), url("//fonts.locuslabs.com/roboto/Roboto-Italic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Italic.woff?v=2.137") format("woff");
	font-weight: normal;
	font-style: italic; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Medium'), local('Roboto-Medium'), url("//fonts.locuslabs.com/roboto/Roboto-Medium.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Medium.woff?v=2.137") format("woff");
	font-weight: 500;
	font-style: normal; }
@font-face {
	font-family: Roboto;
	src: local('Roboto MediumItalic'), local('Roboto-MediumItalic'), url("//fonts.locuslabs.com/roboto/Roboto-MediumItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-MediumItalic.woff?v=2.137") format("woff");
	font-weight: 500;
	font-style: italic; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Bold'), local('Roboto-Bold'), url("//fonts.locuslabs.com/roboto/Roboto-Bold.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Bold.woff?v=2.137") format("woff");
	font-weight: 700;
	font-style: normal; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Bold'), local('Roboto-Bold'), url("//fonts.locuslabs.com/roboto/Roboto-Bold.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Bold.woff?v=2.137") format("woff");
	font-weight: bold;
	font-style: normal; }
@font-face {
	font-family: Roboto;
	src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url("//fonts.locuslabs.com/roboto/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-BoldItalic.woff?v=2.137") format("woff");
	font-weight: 700;
	font-style: italic; }
@font-face {
	font-family: Roboto;
	src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url("//fonts.locuslabs.com/roboto/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-BoldItalic.woff?v=2.137") format("woff");
	font-weight: bold;
	font-style: italic; }
@font-face {
	font-family: Roboto;
	src: local('Roboto Black'), local('Roboto-Black'), url("//fonts.locuslabs.com/roboto/Roboto-Black.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-Black.woff?v=2.137") format("woff");
	font-weight: 900;
	font-style: normal; }
@font-face {
	font-family: Roboto;
	src: local('Roboto BlackItalic'), local('Roboto-BlackItalic'), url("//fonts.locuslabs.com/roboto/Roboto-BlackItalic.woff2?v=2.137") format("woff2"), url("//fonts.locuslabs.com/roboto/Roboto-BlackItalic.woff?v=2.137") format("woff");
	font-weight: 900;
	font-style: italic; }