.context-menu
{
	position: absolute;
	width: 210px;
	z-index: 100;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
	border-radius: 2px;

	> .menuItem
	{
		display: flex;
		font-size: 0.875em;
		padding: 1em;
		align-items: center;

		> .icon
		{
			padding: 0 1em 0 0;
		}

		&:first-child
		{
			border-radius: 2px;
		}

		&:last-child
		{
			border-radius: 2px;
			border: none;
		}
	}

	&.-zoombarTrigger
	{
		left: calc(50% - 200px);
		bottom: calc(2em + 40px + 12px);

		&::after
		{
			position: absolute;
			content: "";
			bottom: -4px;
			left: 80px;
			width: 30px;
			height: 30px;
			background: white;
			z-index: -10;
			transform: rotate(45deg);
		}
	}

	&.-mobileSubmenu
	{
		position: absolute;
		top: 50px;
		right: 0;

		&::after
		{
			position: absolute;
			content: "";
			top: -4px;
			right: 8px;
			width: 30px;
			height: 30px;
			background: white;
			z-index: -10;
			transform: rotate(45deg);
		}
	}

	.copyMenuFooter
	{
		font-size: 0.6875em;
		text-align: center;
		padding: 0.5em;
		border-radius: 0 0 2px 2px;
	}
}

.LocusLabs .fullMask
{
	z-index: 90;
	outline: red;
	position: fixed;
	left: 0;
	width: 100%;
	top: 0;
	height: 100vh;
}

.LocusLabs.-mobile
{
	.context-menu
	{
		> .menuItem
		{
			&:nth-last-of-type(2)
			{
				border: none;
			}

			&:last-child
			{
				border-radius: 0;
			}
		}
	}
}