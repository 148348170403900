.LocusLabs
{
	.icon
	{
		text-align: center;
		line-height: 0;
		display: flex;
		align-items: center;
		justify-content: space-around;

		&.inline
		{
			height: 1.5em;

			> svg
			{
				height: 100%;
			}
		}
	}
}