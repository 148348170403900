.round-label
{
	border-radius: 100px;
	font-size: 0.875em;
	font-weight: 600;
	text-align: center;
	padding: 6px 12px;
	position: relative;
	display: inline-flex;
	margin-top: 4px;
	text-transform: uppercase;
}
