:root
{
	.LocusLabs
	{
		/* ----------------------*/
		/* Non-themable app classes
		/* ----------------------*/
		.text-success                      { color: var(--neonGreen); }
		.text-warning                      { color: var(--pureRed); }
		.text-danger                       { color: var(--pureRed); }

		.bg-success                        { background-color: var(--neonGreen); }
		.bg-warning                        { background-color: var(--pureRed); }
		.bg-danger                         { background-color: var(--pureRed); }

		.border-success                    { border-color: var(--neonGreen) ; }
		.border-warning                    { border-color: var(--pureRed) ; }
		.border-danger                     { border-color: var(--pureRed) ; }
	}
}
