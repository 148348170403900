.LocusLabs
{
	.tooltip
	{
		font-size: 12px;
		text-align: center;
		opacity: 0.85;
		border-radius: 2px;
		color: rgb(255, 255, 255);
		background-color: rgb(0, 0, 0);
		padding: 7px 10px;
	}
}
