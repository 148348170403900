/**/
@keyframes buttonToTitle {
	0% {
		margin: 0 1rem 1rem 1em;
		height: 3em;
		width: calc(100% - 2rem);
	}
	100% {
		margin: 0;
		height: 1.5em;
		width: 100%;
	}
}

/**/
@keyframes titleToButton {
	0% {
		margin: 0;
		height: 1.5em;
		width: 100%;
	}
	100% {
		margin: 0 1rem 1rem 1em;
		height: 3em;
		width: calc(100% - 2rem);
	}
}

/**/
@keyframes ripple-animation
{
	from { transform: scale(0.25); opacity: 0.3; }
	to { transform: scale(100); opacity: 0; }
}

/**/
@keyframes pulse
{
	0% { opacity: 0; }
	10% { opacity: 1; }
	90% { opacity: 0.25; }
	100% { opacity: 0; }
}

/**/
@keyframes pulse-opacity
{
	from { opacity: 0; }
	to { opacity: 1; }
}

/**/
@keyframes blink
{
	0% { opacity: 0; }
	50% { opacity: 1;}
	100% { opacity: 0; }
}

/**/
@keyframes outer-most-loading-indicator
{
	0% {
		transform: translate(60px, 60px) rotateZ(0deg)
	}
	10% {
		transform: translate(57px, 55px) rotateZ(36deg)
	}
	20% {
		transform: translate(58px, 48px) rotateZ(72deg)
	}
	30% {
		transform: translate(62.5px, 44px) rotateZ(108deg)
	}
	40% {
		transform: translate(69px, 43px) rotateZ(144deg)
	}
	50% {
		transform: translate(74px, 46px) rotateZ(180deg)
	}
	60% {
		transform: translate(77px, 51px) rotateZ(216deg)
	}
	70% {
		transform: translate(76px, 57px) rotateZ(252deg)
	}
	80% {
		transform: translate(72px, 62px) rotateZ(288deg)
	}
	90% {
		transform: translate(65.5px, 63px) rotateZ(324deg)
	}
	100% {
		transform: translate(60px, 60px) rotateZ(360deg)
	}
}

/**/
@keyframes inner-loading-indicator
{
	0% { stroke-dashoffset: 187; }
	25% { stroke-dashoffset: 80; }
	100% { stroke-dashoffset: 187; transform: rotate(360deg); }
}

/**/
@keyframes outer-loading-indicator
{
	0% { stroke-dashoffset: 312; transform: rotate(70deg); }
	60% { stroke-dashoffset: -312; }
	100% { stroke-dashoffset: -312; transform: rotate(450deg); }
}



/**/
@keyframes translated-circle-expansion
{
	0% {
		height: 6px;
		width: 6px;
		margin: 0 0 0 -3px;
		opacity: 1
	}
	80% {
		opacity: 1;
	}
	100% {
		height: 6px;
		width: 6px;
		margin: 35px 0 0 -3px;
		opacity: 0
		}
}

@keyframes overlay-animation
{
	0%
	{
		opacity: 0;
		-webkit-transform: scale(0.04) translateY(300%);
		transform: scale(0.04) translateY(300%);
	}

	40%
	{
		-webkit-transform: scale(0.04) translateY(0);
		transform: scale(0.04) translateY(0);
		-webkit-transition: ease-out;
		transition: ease-out;
	}

	40%
	{
		-webkit-transform: scale(0.04) translateY(0);
		transform: scale(0.04) translateY(0);
	}

	50%
	{
		-webkit-transform: scale(0.025) translateY(0);
		transform: scale(0.025) translateY(0);
	}

	55%
	{
		-webkit-transform: scale(0.04) translateY(0);
		transform: scale(0.04) translateY(0);
	}

	60%
	{
		opacity: 1;
		-webkit-transform: scale(0.035) translateY(0);
		transform: scale(0.035) translateY(0);
	}

	65%
	{
		-webkit-transform: scale(0.04);
		transform: scale(0.04);
	}

	99.9%
	{
		height: 0;
		padding-bottom: 100%;
		border-radius: 100%;
	}

	100%
	{
		-webkit-transform: scale(2);
		transform: scale(2);
		height: 100%;
		padding-bottom: 0;
		border-radius: 0;
	}
}

@keyframes fade-in-animation {

	0%
	{
		opacity: 0;
	}

	50%
	{
		opacity: 0.5;
	}

	100%
	{
		opacity: 1;
	}
}


@keyframes spin
{
	from
	{
		transform: rotate(0deg);
	}

	to
	{
		transform: rotate(360deg);
	}
}