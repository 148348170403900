.LocusLabs .flight-status-container
{
	.flightsList
	{
		background-color: #ebebeb;
	}

	.separator-box
	{
		position: relative;
		height: 16px;
		margin: 15px 0;
	}

	.separator-box::before
	{
		content: "";
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 0;
		right: 0;
		height: 2px;
		background: #f5f5f5;
	}

	.statusFlight
	{
		font-size: 11px;
		font-weight: 600;
		background: #008000;
		border-radius: 20px;
		padding: 2px 7px;
		color: #ffffff;
		display: inline-block;
		text-align: center;
		text-transform: uppercase;

		&.-delayed, &.-early
		{
			background: #bc6300;
		}

		&.-cancelled
		{
			background: #dc2a2a;
		}
		&.-departed, &.-arrived, &.-unknown
		{
			background: #666666;
		}
	}

	.hourDeparture
	{
		font-size: 16px;
		color: #333333;
		font-weight: normal;
	}

	.scrolling-container
	{
		overflow: auto;
		width: inherit;
		border-radius: 2px;
	}

	.search-entry .inner .no-border
	{
		border-right-width: 0px;
	}

	.getDirections
	{
		margin-right: 8px;
	}

	.delayed
	{
		color: #f04a40;
	}

	.Xcanceled
	{
		opacity: 0.2;
	}

	.hidden-block
	{
		display: none;
	}

	.flight-item .line-through time
	{
		text-decoration: line-through;
		color: #aaa;
		font-size: 16px;
		font-weight: normal;
	}

	.line-through time
	{
		text-decoration: line-through;
		font-weight: normal;
	}

	.bagclaimnum {
		font-size: 12px;
		font-weight: 400;
		margin-top: 6px;

		.icon {
			float: left;
			transform: scale(0.65);
			position: relative;
			top: -8px;
		}
	}

	@keyframes spin
	{
		from
		{
			transform: rotate(0deg);
		}

		to
		{
			transform: rotate(360deg);
		}
	}

	@keyframes counterspin
	{
		from
		{
			transform: rotate(0deg) scale(0.75);
		}

		to
		{
			transform: rotate(-360deg) scale(0.75);
		}
	}

	.placeholder
	{
		height: 124px;
		position: relative;

		&:before,
		&:after
		{
			content: "";
			display: block;
			width: 30px;
			height: 30px;
			border: 2px solid #4c6389;
			border-radius: 100%;
			position: absolute;
			left: calc(50% - 15px);
			top: 47px;
		}

		&:before
		{
			border-bottom-color: transparent;
			animation: spin 2s infinite linear;
		}

		&:after
		{
			border-right-color: transparent;
			border-width: 1px;
			animation: counterspin 2s infinite linear;
		}
	}

	.details-content .placeholder
	{
		height: 262px;
		position: relative;

		&:before,
		&:after
		{
			content: "";
			display: block;
			width: 30px;
			height: 30px;
			border: 2px solid #4c6389;
			border-radius: 100%;
			position: absolute;
			left: calc(50% - 15px);
			top: 116px;
		}

		&:before
		{
			border-bottom-color: transparent;
			animation: spin 2s infinite linear;
		}

		&:after
		{
			border-right-color: transparent;
			border-width: 1px;
			animation: counterspin 2s infinite linear;
		}
	}

	.container-height
	{
		transition: max-height 0.5s;
		max-height: 100vh;
	}

	.hidden-container
	{
		max-height: 0px;
	}

	.LocusLabs.-mobile .mobile-flight-status
	{
		position: fixed;
		top: 0 !important;
		left: 0 !important;
		width: 100% !important;
		min-width: 100% !important;
		height: 100% !important;
		max-height: none;
		overflow: auto;
		z-index: 10;
	}

	.button-pointer
	{
		cursor: pointer;
	}

	.top-links,
	.flight-meta
	{
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.clearfix:after
	{
		content: "";
		display: block;
		clear: both;
	}

	.ellipsis
	{
		white-space: nowrap; /* 1 */
		text-overflow: ellipsis; /* 2 */
		overflow: hidden;
	}

	html
	{
		box-sizing: border-box;
	}

	*,
	*:before,
	*:after
	{
		box-sizing: inherit;
	}

	*
	{
		max-height: 1000000px;
	}

	body
	{
		color: #000000;
		background: #ffffff;
		font: 16px/1.2 "Roboto", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
		min-width: 320px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	img
	{
		max-width: 100%;
		height: auto;
	}

	.gm-style img
	{
		max-width: none;
	}

	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6,
	.h
	{
		font-family: inherit;
		font-weight: bold;
		margin: 0 0 0.5em;
		color: inherit;
	}

	h1,
	.h1
	{
		font-size: 30px;
	}

	h2,
	.h2
	{
		font-size: 27px;
	}

	h3,
	.h3
	{
		font-size: 24px;
	}

	h4,
	.h4
	{
		font-size: 21px;
	}

	h5,
	.h5
	{
		font-size: 17px;
	}

	h6,
	.h6
	{
		font-size: 15px;
	}

	p
	{
		margin: 0 0 1em;
	}

	a
	{
		color: #3399ff;
		text-decoration: none;
	}

	a:hover,
	a:focus
	{
		color: #000000;
	}

	input,
	fieldset
	{
		margin: 0;
		padding: 0;
		border-style: none;
	}

	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="search"],
	input[type="password"],
	textarea
	{
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		box-sizing: border-box;
		border: none;
		padding: 0.4em 0.7em;
	}

	input[type="text"]:focus,
	input[type="tel"]:focus,
	input[type="email"]:focus,
	input[type="search"]:focus,
	input[type="password"]:focus,
	textarea:focus
	{
		border-color: #000000;
	}

	input[type="text"]::-webkit-input-placeholder,
	input[type="tel"]::-webkit-input-placeholder,
	input[type="email"]::-webkit-input-placeholder,
	input[type="search"]::-webkit-input-placeholder,
	input[type="password"]::-webkit-input-placeholder,
	textarea::-webkit-input-placeholder
	{
		color: #999999;
	}

	input[type="text"]::-moz-placeholder,
	input[type="tel"]::-moz-placeholder,
	input[type="email"]::-moz-placeholder,
	input[type="search"]::-moz-placeholder,
	input[type="password"]::-moz-placeholder,
	textarea::-moz-placeholder
	{
		opacity: 1;
		color: #999999;
	}

	input[type="text"]:-moz-placeholder,
	input[type="tel"]:-moz-placeholder,
	input[type="email"]:-moz-placeholder,
	input[type="search"]:-moz-placeholder,
	input[type="password"]:-moz-placeholder,
	textarea:-moz-placeholder
	{
		color: #999999;
	}

	input[type="text"]:-ms-input-placeholder,
	input[type="tel"]:-ms-input-placeholder,
	input[type="email"]:-ms-input-placeholder,
	input[type="search"]:-ms-input-placeholder,
	input[type="password"]:-ms-input-placeholder,
	textarea:-ms-input-placeholder
	{
		color: #999999;
	}

	input[type="text"].placeholder,
	input[type="tel"].placeholder,
	input[type="email"].placeholder,
	input[type="search"].placeholder,
	input[type="password"].placeholder,
	textarea.placeholder
	{
		color: #999999;
	}

	select
	{
		-webkit-border-radius: 0;
	}

	textarea
	{
		resize: vertical;
		vertical-align: top;
	}

	button,
	input[type="button"],
	input[type="reset"],
	input[type="file"],
	input[type="submit"]
	{
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		cursor: pointer;
	}

	#wrapper
	{
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	.widget-holder
	{
		-webkit-transition: width 0.3s ease-in-out;
		transition: width 0.3s ease-in-out;
		position: relative;
		width: 310px;
		margin: 20px auto 65px;
		background: #f0f0f0;
	}

	.widget-frame
	{
		overflow: hidden;
		border-radius: 2px;
	}

	.box-shadow
	{
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
	}

	.flightlist-head
	{
		z-index: 1;

		> .flightlist-topline
		{
			display: flex;
			padding: 16px;
			justify-content: space-between;

			> .title
			{
				font-size: 20px;
				font-weight: 500;
				line-height: 40px;
				flex: 1;
				margin: 0 16px;
			}
		}
	}

	.form-box
	{
		display: flex;
		padding: 16px;
		justify-content: space-between;

		> input {
			flex: 1;
			padding: 0;
			margin: 0 16px;
		}
	}

	.js-tab-hidden
	{
		display: block !important;
		left: -9999px !important;
		position: absolute !important;
		top: -9999px !important;
	}

	.tooltip-hover
	{
		-webkit-transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
		transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
		-webkit-transition-delay: 0.25s;
		transition-delay: 0.25s;
		position: absolute;
		z-index: 9999;
		top: 100%;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		margin-top: 7px;
		opacity: 0;
		visibility: hidden;
	}

	.tooltip-hover.alt-position
	{
		margin-top: 34px;
	}

	.tooltip-hover .tooltip-text
	{
		position: relative;
		display: inline-block;
		font-size: 12px;
		line-height: 1;
		font-weight: 400;
		background: #232323;
		color: #ffffff;
		border-radius: 2px;
		padding: 7px 10px 4px;
		box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35);
	}

	.tooltip-hover .tooltip-text:before
	{
		width: 14px;
		height: 14px;
		content: "";
		position: absolute;
		z-index: 1;
		top: -6px;
		left: 50%;
		margin-left: -7px;
		background: #232323;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		border-radius: 2px;
		box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35);
	}

	.btn-back
	{
		position: relative;
		display: block;
		width: 50px;
		height: 30px;
		font-size: 16px;
		line-height: 30px;
		color: #a0a0a0;
		text-align: center;
	}

	.btn-back:hover
	{
		color: #a0a0a0;
	}

	.btn-back:hover .tooltip-hover
	{
		opacity: 1;
		visibility: visible;
		-webkit-transition-delay: 0.5s;
		transition-delay: 0.5s;
	}

	.minimize-button:hover
	{
		color: #a0a0a0;
	}

	.minimize-button:hover .tooltip-hover
	{
		opacity: 1;
		visibility: visible;
		-webkit-transition-delay: 0.5s;
		transition-delay: 0.5s;
	}

	.search-form
	{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: calc(100% - 50px);
		height: 30px;
	}

	.search-form.typing-off .icon-close
	{
		display: inline-block;
		vertical-align: middle;
	}

	.search-form.typing-off .icon-search
	{
		display: none;
	}

	.search-form.typing-off .tooltip-hover
	{
		display: none;
	}

	.search-form input[type="search"],
	.search-form input[type="text"]
	{
		display: block;
		width: 100%;
		height: 30px;
		border: 0;
		outline: 0;
		background: none;
		font-size: 16px;
		line-height: 1;
		font-weight: 400;
		color: #333333;
		padding: 0 6px;
	}

	.search-form input[type="search"]::-webkit-input-placeholder,
	.search-form input[type="text"]::-webkit-input-placeholder
	{
		font-style: italic;
		color: #999999;
	}

	.search-form input[type="search"]::-moz-placeholder,
	.search-form input[type="text"]::-moz-placeholder
	{
		opacity: 1;
		font-style: italic;
		color: #999999;
	}

	.search-form input[type="search"]:-moz-placeholder,
	.search-form input[type="text"]:-moz-placeholder
	{
		font-style: italic;
		color: #999999;
	}

	.search-form input[type="search"]:-ms-input-placeholder,
	.search-form input[type="text"]:-ms-input-placeholder
	{
		font-style: italic;
		color: #999999;
	}

	.search-form input[type="search"].placeholder,
	.search-form input[type="text"].placeholder
	{
		font-style: italic;
		color: #999999;
	}

	.search-form button
	{
		-webkit-transition: color 0.3s ease-in-out;
		transition: color 0.3s ease-in-out;
		position: relative;
		display: block;
		width: 50px;
		height: 30px;
		font-size: 16px;
		line-height: 28px;
		border: 0;
		outline: 0;
		background: none;
		text-align: center;
		color: #333333;
	}

	.search-form button:hover
	{
		color: #333333;
	}

	.search-form button:hover .tooltip-hover
	{
		opacity: 1;
		visibility: visible;
		-webkit-transition-delay: 0.5s;
		transition-delay: 0.5s;
	}

	.search-form button .icon-close
	{
		display: none;
		font-size: 12px;
		line-height: 1;
		margin-top: -1px;
	}

	.search-form button .icon-search
	{
		display: inline-block;
		vertical-align: middle;
	}

	.top-links
	{
		font-size: 0;
		line-height: 0;
		padding: 0 9px;
	}

	.top-links li
	{
		border: 1px solid #1e405a;
		cursor: pointer;
		display: inline-block;
		vertical-align: top;
		width: 50%;
		font-size: 12px;
		line-height: 1.1;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		border-radius: 2px 0 0 2px;
		overflow: hidden;
	}

	.top-links li:last-child
	{
		border-radius: 0 2px 2px 0;
	}

	.top-links li.active div
	{
		background: #1e405a;
		color: #ffffff;
	}

	.top-links div
	{
		-webkit-transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
		transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
		display: block;
		color: #1e405a;
		padding: 9px 5px 8px;
		background: #fff;
	}

	.top-links div:hover
	{

		}

	.text-show
	{
		display: none;
	}

	.panel-intro
	{
		position: relative;
	}

	.panel-intro.child-active .child-opener:after
	{
		content: "\e906";
	}

	.panel-intro.child-active .child-opener:hover + .tooltip-hover
	{
		opacity: 1;
		visibility: visible;
		-webkit-transition-delay: 0.5s;
		transition-delay: 0.5s;
	}

	.panel-intro.child-active .child-opener:hover + .tooltip-hover .text-show
	{
		display: none;
	}

	.panel-intro.child-active .child-opener:hover + .tooltip-hover .text-hide
	{
		display: inline;
	}

	.panel-intro.child-active .panel-top-box
	{
		max-height: 300px;
		transition: max-height 0.5s;
	}

	.panel-intro.child-active .panel-top-box.hidden-container
	{
		max-height: 0px;
	}

	.category-title
	{
		display: block;
		font-size: 12px;
		line-height: 1.1;
		font-weight: 400;
		text-transform: uppercase;
		background: #333333;
		color: #ffffff;
		padding: 7px 25px 6px;
		position: relative;
		z-index: 1;
	}

	.category-title.fixed
	{
		position: fixed;
		top: 100;
		width: inherit;
		box-sizing: border-box;
		z-index: 0;
	}

	.category-title.fixed.absolute
	{
		position: absolute;
	}

	.flight-box
	{
		padding: 8px 10px;
		/*max-height: 672px;
		overflow-y: auto;*/
	}

	.flight-item
	{
		-webkit-transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
		transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
		display: block;
		background: #ffffff;
		border-radius: 2px;
		padding: 14px 12px 12px 14px;
		margin-bottom: 8px;
		color: #424242;
	}

	.flight-item:hover,
	.flight-item:focus
	{
		background: #f9f9f9;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
	}

	.flight-item .row
	{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.flight-item .row:first-child
	{
		margin-bottom: 8px;
	}

	.flight-item .left-col
	{
		width: 100%;
	}

	.flight-item .right-col
	{
		width: 20%;
		text-align: right;
		padding-left: 10px;
	}

	.flight-item .flight-title
	{
		display: block;
		font-size: 16px;
		line-height: 1.1;
		font-weight: 500;
		color: #424242;
		margin-bottom: 3px;
	}

	.flight-item .flight-title mark
	{
		font-weight: 400;
		background: none;
		color: #999999;
		margin-right: 0.5ex;
	}

	.flight-item .flight-num
	{
		display: block;
		font-size: 14px;
		line-height: 1.1;
		font-weight: 400;
		color: #424242;
		margin-bottom: 2px;
	}

	.flight-item .col-title
	{
		display: block;
		font-size: 11px;
		line-height: 1.1;
		font-weight: 400;
		color: #333;
		text-transform: uppercase;
		margin-bottom: 3px;
	}

	.flight-item time,
	.flight-item .gate-num
	{
		font-size: 16px;
		line-height: 1.1;
		font-weight: 400;
		color: #333333;
		text-transform: uppercase;
	}

	.flight-item time s,
	.flight-item .gate-num s
	{
		color: #cccccc;
	}

	.flight-item .img-box img
	{
		display: inline-block;
		vertical-align: top;
		max-width: 100%;
		height: auto;
	}

	.on-time .flight-status
	{
		background: #8dd119;
	}

	.on-time .flight-status .on-time-text
	{
		display: block;
	}

	.on-time .flight-status .delay-text,
	.on-time .flight-status .cancel-text
	{
		display: none;
	}

	.delayed .flight-status
	{
		background: #e93232;
	}

	.delayed .flight-status .delay-text
	{
		display: block;
	}

	.delayed .flight-status .cancel-text,
	.delayed .flight-status .on-time-text
	{
		display: none;
	}

	.delayed .info-time
	{
		color: #f04a40;
	}

	.cancelled .flight-status
	{
		background: #e93232;
	}

	.cancelled .flight-status .cancel-text
	{
		display: block;
	}

	.cancelled .flight-status .delay-text,
	.cancelled .flight-status .on-time-text
	{
		display: none;
	}

	.cancelled .info-time
	{
		color: #f04a40;
	}

	.cancelled .time-item,
	.cancelled .gate-num
	{
		color: rgba(51, 51, 51, 0.3);
	}

	.cancelled .two-cols
	{
		opacity: 0.5;
	}

	.flight-status
	{
		display: inline-block;
		vertical-align: top;
		font-size: 11px;
		line-height: 1.1;
		font-weight: 400;
		color: #ffffff;
		padding: 3px 9px 2px;
		text-align: center;
		border-radius: 25px;
	}

	.flight-status.cancelled
	{
		background: #e93232;
	}

	.flight-status.cancelled .cancel-text
	{
		display: block;
	}

	.flight-status.cancelled .delay-text,
	.flight-status.cancelled .on-time-text
	{
		display: none;
	}

	.popup-holder.popup-active .popup
	{
		display: block;
	}

	.popup-holder .popup
	{
		position: absolute;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		display: none;
	}

	.panel-head
	{
		background: #ffffff;
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 15px;
		border-radius: 2px;
	}

	.panel-head .left-col
	{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: calc(100% - 30px);
	}

	.panel-head .ico-holder
	{
		width: 30px;
		height: 30px;
		display: block;
		border-radius: 2px;
		color: #ffffff;
		text-align: center;
		font-size: 16px;
		line-height: 32px;

		& > .icon { height: 100%; }
	}

	.panel-head .panel-title
	{
		display: block;
		color: #424242;
		font-size: 18px;
		line-height: 1.1;
		font-weight: 500;
		padding-left: 16px;
	}

	.panel-head .popup-close
	{
		position: relative;
		display: block;
		width: 30px;
		height: 30px;
		text-align: center;
		font-size: 12px;
		line-height: 30px;
		color: #999999;
	}

	.panel-head .popup-close:hover .tooltip-hover
	{
		opacity: 1;
		visibility: visible;
		-webkit-transition-delay: 0.5s;
		transition-delay: 0.5s;
	}

	.panel-body
	{
		background: #ebebeb;
		padding: 0px 10px 10px;
		border-radius: 2px;
	}

	.mobile-flight-status .panel-body
	{
		width: 100% !important;
		min-width: 100% !important;
		height: 100% !important;
		max-height: none;
		overflow: hidden;
		z-index: 10;
	}

	.details-top
	{
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 10px 10px;
		background: #ebebeb;
	}

	.details-top .left-col
	{
		width: 85%;
	}

	.details-top .right-col
	{
		width: 15%;
		text-align: right;
	}

	.details-top .right-col img
	{
		display: inline-block;
		vertical-align: top;
		max-width: 100%;
		height: auto;
	}

	.details-top .flight-num
	{
		display: block;
		color: #424242;
		font-size: 18px;
		line-height: 1.1;
		font-weight: 500;
		margin-bottom: 3px;
	}

	.details-top .centered-box
	{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.details-content
	{
		overflow: hidden;
	}

	.update-info
	{
		display: block;
		color: #808080;
		font-size: 12px;
		line-height: 1;
		padding-left: 8px;
		font-weight: normal;
	}

	.details-inner
	{
		background: #ffffff;
		border-radius: 2px;
		padding: 15px;
		margin-bottom: 10px;
	}

	.flight-meta
	{
		font-size: 0;
		line-height: 0;
		color: #424242;
		margin-bottom: 16px;
	}

	.flight-meta li
	{
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		line-height: 1.1;
		font-weight: 400;
	}

	.flight-meta li + li:before
	{
		content: "•";
		display: inline-block;
		vertical-align: top;
		margin: 0 7px 0 8px;
	}

	.flight-meta mark
	{
		background: none;
		color: #999999;
	}

	.two-cols
	{
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.two-cols .left-col
	{
		width: 50%;
		padding-right: 10px;
	}

	.two-cols .right-col
	{
		width: 50%;
	}

	.sm-title
	{
		display: block;
		font-size: 11px;
		line-height: 1.1;
		font-weight: 400;
		text-transform: uppercase;
		color: #333;
		margin-bottom: 5px;
	}

	&.-canceled
	{
		.sm-title, .info-time, .info-num, .terminal, .bagclaimnum
		{
			opacity: 0.2;
		}
	}

	.md-title
	{
		display: block;
		font-size: 14px;
		line-height: 1.1;
		font-weight: 400;
		color: #a9a9a9;
		margin-bottom: 5px;
	}

	.info-num
	{
		display: block;
		font-size: 28px;
		line-height: 1.1;
		font-weight: 400;
		color: #424242;
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	.separator-box
	{
		position: relative;
		height: 16px;
		margin: 15px 0;
	}

	.separator-box:before
	{
		content: "";
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 0;
		right: 0;
		height: 2px;
		background: #f5f5f5;
	}

	.separator-box i
	{
		position: absolute;
		z-index: 2;
		top: 0;
		left: 50%;
		margin-left: -15px;
		color: #999999;
		font-size: 16px;
		line-height: 1;
		background: #ffffff;
		width: 30px;
		height: 16px;
		text-align: center;
	}

	.panel-top-box
	{
		-webkit-transition: max-height 0.4s ease;
		transition: max-height 0.4s ease;
		background: #333333;
		color: #ffffff;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		width: 100%;
		max-height: 0;
		overflow: hidden;
	}

	.panel-top-box .col
	{
		padding: 18px 0;
		width: 31%;
	}

	.panel-top-box .col:first-child
	{
		padding-left: 25px;
	}

	.panel-top-box .col.center-col
	{
		text-align: center;
		padding: 18px 10px;
	}

	.panel-top-box .col:last-child
	{
		width: 38%;
		padding-left: 10px;
		padding-right: 5px;
	}

	.panel-top-box .title-row
	{
		display: block;
		font-size: 11px;
		line-height: 1.1;
		font-weight: 400;
		color: #a9a9a9;
		margin-bottom: 5px;
	}

	.panel-top-box .lg-title
	{
		display: block;
		font-size: 40px;
		line-height: 1;
		font-weight: 400;
	}

	.panel-top-box .img-box
	{
		position: relative;
		overflow: hidden;
	}

	.panel-top-box .img-box:before
	{
		content: "";
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		top: 50%;
		height: 40px;
		border: 1px dashed #fff;
		border-radius: 120px / 50px;
	}

	.panel-top-box .img-box i
	{
		display: block;
		position: relative;
		z-index: 2;
		font-size: 28px;
		line-height: 1;
	}
}

/* Exntensions by JMC after changing from icomoon to svg */

.icon.flight-icon-location.icon-location
{
	display: inline;
}

.icon.flight-icon-downarrow.icon-arrow-down > svg
{
	z-index: 2;
}

.icon.flight-icon-close.icon-close
{
	margin: 9px;
}

.icon.flight-icon-airplaneup.icon-plane-up > svg
{
	margin: 7px;
}