.LocusLabs .language-selector
{
	list-style: none;
	margin: 0;
	padding: 0;

	> .menu-item
	{
		height: 62px;
		padding: 22px 16px;
		cursor: pointer;
		user-select: none;

		> .icon
		{
			float: right;
		}
	}
}