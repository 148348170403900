.LocusLabs
{
	.tag
	{
		display: inline-block;
		border: 1px solid;
		margin: 0.25em 0.5em 0.25em 0;
		font-size: 0.75em;
		padding: 2px 8px;
		border-radius: 6px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
	}
}
