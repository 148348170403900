.poi-images
{
	transition: all 200ms;
	width: 100%;
	height: 13.21em; /* gives us a 16:9 */
	display: flex;
	align-items: center;
	text-align: center;
	font-weight: 500;
	flex: none;
	background-size: cover;

	> div
	{
		height: 100%;
		width: 100%;
		position: relative;

		> div.poiImage
		{
			position: absolute;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 100%;
			width: 100%;
		}

		img
		{
			display: none;
		}
	}

	div.notAvailable
	{
		height: auto;
	}
}
