.LocusLabs .overlay-container
{
	/* Styles for Container */
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.5);

	/* Styles for Content */
	.overlay-content
	{
		width: 440px;
		height: auto;
		border-radius: 2px;
		position: absolute;
		top: 25%;
		left: 0;
		right: 0;
		opacity: 0;
		margin: auto;
		font-weight: 500;
		animation: fade-in-animation 0.5s ease-out forwards;
		animation-delay: 0.1s;

		&.-narrow
		{
			width: 288px;
		}

		> .muchPadding
		{
			padding: 2em 2em 1.5rem 2em;
		}

		> .overlay-title
		{
			height: 54px;
			padding: 18px 0;
			border-radius: 2px 2px 0 0;
			vertical-align: middle;
			text-align: center;
		}

		.dialogQuestion
		{
			font-weight: 900;
			font-size: 1.5em;
		}

		.buttons
		{
			display: flex;
			margin-top: 1em;

			> .rightSide
			{
				margin-left: auto;
			}
		}

		.button
		{
			height: 48px;
			padding: 16px;
			border-radius: 2px;
			border-width: 1px;
			border-style: solid;
			border-color: #ebebeb;
			font-weight: bold;
			min-width: 150px;

			&.-destructive
			{
				background: #dc4747;
				color: white;
			}
		}
	}
}