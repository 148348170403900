.landing-list
{
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0 1em;
	width: 100%;

	> div
	{
		flex: 1 0 30%;
		margin: 0.750em 0em 0.750em 0em;
		cursor: pointer;

		> div.caticon
		{
			margin-left: 0.6em;
			margin-right: 0.6em;
		}

		.title
		{
			text-align: center;
			margin-top: 0.500em;
			font-size: 0.875em;
			font-weight: 400;
		}
	}
}

.LocusLabs.-mobile .full-screen
{
	height: 80vh;
	overflow: hidden;

	.landing-list
	{
		margin-top: 28px;
	}
}
