.LocusLabs .search-container
{
	.search
	{
		border-radius: 0.125em;
		box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		transition: all 200ms;
	}

	.scrolling-container
	{
		overflow: auto;
		flex-grow: 1;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
		{
			max-height: 70vh;
		} /* IE10+ */
	}
}

.LocusLabs .bluedot-dialog
{
	width: 300px;
	margin-left: -150px;
}

.about-dialog
{
	max-height: 400px;
	display: flex;
	flex-direction: column;
	overflow: visible !important; /* to display close widget */

	> h1
	{
		margin: 0;
		padding: 0.25em 0.5em;
		border-radius: 0.25em;
		font-size: 1.25em;
		font-weight: 400;
	}

	> div
	{
		overflow: auto;

		> h2
		{
			font-size: 1.1em;
			margin-left: 0.5em;
			color: #232d4f;
		}

		table
		{
			padding: 1em;
			width: 100%;

			tr > td:first-child
			{
				font-weight: bold;
			}
		}
	}

	.error
	{
		color: red;
	}
}

.LocusLabs.-mobile .search-container
{
	left: 0;
	top: 0;
	width: calc(100%);
	max-height: 100%;

	.button
	{
		height: 42px; /* a calc depends on this being 42px */
		border-radius: 0;
		padding: 1.000em;
	}

	.scrolling-container
	{
		max-height: 100%;

		&.-hasTerm
		{
			padding-bottom: 42px; /* to ensure the "click for map" doesn't obscure results */
		}

		.scroll-hidden
		{
			overflow: hidden;
		}
	}

	.show-results-map-button
	{
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

.LocusLabs 	.mobileLevelIndicator
{
	position: absolute;
	width: 100%;
	font-size: 0.75em;
	text-align: center;
	padding: 0.375em;
}

body.-LLfullpage .LocusLabs.-mobile
{
	.search-container .show-results-map-button,
	.mobileLevelIndicator
	{
		position: fixed;
	}
}
