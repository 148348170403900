.message
{
	width: 100%;
	flex: 1;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	margin-top: 0.1em;

	> .waitIndicator
	{
		margin: 1em;
	}

	> .sorryMsg
	{
		display: flex;
		margin: 1.5em;
		align-items: center;

		> .icon
		{
			margin-right: 1em;
		}

		> .textLabel
		{
			line-height: 1.5em;

			> .title
			{
				font-size: 1.125em;
			}

			> .desc
			{
				font-size: 0.875em;
			}
		}
	}
}
