.LocusLabs .mobileSearch-Header
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	> .topHeader
	{
		display: flex;
		justify-content: flex-end;

		> .global-icon-logo
		{
			max-width: 150px;
			height: 40px;
			margin: 5px auto 5px 0.5em;

			> svg
			{
				height: 40px;
				width: auto;
			}
		}

		> input
		{
			border-right: 0;
		}

		> .icon
		{
			width: 1.875em;
			height: 1.875em;
			margin: 0.5em;

			&.global-icon-logo
			{
				width: 150px;
				height: 40px;
				margin: 5px auto 5px 0.5em;
			}
		}
	}
}

body.-LLfullpage
{
	.LocusLabs .mobileSearch-Header
	{
		position: fixed;
	}
}