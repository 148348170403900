.map-container
{
	> div.loadingIndicatorWrapper
	{
		> div.loading-indicator
		{
			width: auto;
			height: auto;
		}
	}

	/* Locking the color on the company logo from modifications. */
	> .map-logo
	{
		position: absolute;
		right: 0;
		bottom: 0;
		margin: 1em;
		width: 144px;
		line-height: 10px;
		text-align: left;
		color: rgba(0, 0, 0, 0.3) !important;

		> span
		{
			font-size: 0.6em;
			font-weight: 600;
			white-space: nowrap;
		}
	}
}

.-logoUpperLeft .map-logo
{
	top: 0;
	left: 0;
	right: auto;
	bottom: auto;
}

.-logoUpperRight .map-logo
{
	top: 0;
	bottom: auto;
}

.-logoBottomLeft .map-logo
{
	left: 0;
	right: auto;
}

.LocusLabs.-mobile > .map-container > .map-logo
{
	top: 64px;
	right: 0;
	bottom: auto;
	left: auto;
	width: 75px;

	span
	{
		display: none;
	}
}
