.poi-input
{
	margin: 10px 0 30px 0;
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 42px;

	> .icon.starting-point,
	.icon.destination-point
	{
		margin: 0 8px 8px 10px;
	}

	&:last-child
	{
		margin-bottom: 1em;
	}

	> .dir-selection-indicator
	{
		width: 30px;
		margin: 0 0.5em;
		flex: none;
	}

	> .dir-icon-search
	{
		border-bottom-width: 2px;
		border-bottom-style: solid;
		padding: 4px;
		width: 1.6em;
	}

	> .global-icon-close
	{
		padding: 4px;
		border-bottom-width: 2px;
		border-bottom-style: solid;
	}

	.extraTopPadding
	{
		margin-top: 0.6em;
		height: 30px;
	}

	> .global-icon-back
	{
		border-bottom-width: 2px;
		border-bottom-style: solid;
	}

	input::-ms-clear
	{
		display: none;
	}

	> label
	{
		flex: 1;

		> .label
		{
			font-size: 0.75em;
			text-transform: uppercase;
			font-weight: 500;
			margin-bottom: 6px;
		}

		::-webkit-input-placeholder
		{
			font-style: italic;
		}

		:-moz-placeholder
		{
			font-style: italic;
		}

		::-moz-placeholder
		{
			font-style: italic;
		}

		:-ms-input-placeholder
		{
			font-style: italic;
		}

		input
		{
			border-radius: 0;
			margin: 0;
			border: 0;
			background: transparent;
			border-bottom: 2px solid;
			display: block;
			width: 100%;
			flex: 1;
			font-weight: 300;
			line-height: 1.4;

			&:focus
			{
				outline: none;
			}

			&::placeholder
			{
				color: #b1b1b1;
			}
		}
	}

	> .reset
	{
		cursor: pointer;
		padding-right: 0.313em;
	}

	.dir-selection-indicator
	{
		margin-left: 0;

		& .centerDot
		{
			visibility: hidden;
		}
	}

	&.-active
	{
		> .dir-selection-indicator .centerDot
		{
			visibility: visible;
			animation: pulse-opacity 800ms infinite;
			animation-direction: alternate;
		}

		> input
		{
			border-bottom: 2px solid;
		}
	}

	&.-confirmed
	{
		> .dir-selection-indicator .centerDot
		{
			visibility: visible;
		}

		input
		{
			border-bottom-width: 2px;
		}
	}
}

.LocusLabs.-mobile .poi-input
{
	margin-bottom: 10px;
	height: 32px;
}