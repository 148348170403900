.LocusLabs .poi-container
{
	align-items: center;
	border-radius: 0.125em;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: all 200ms;
}

/* .LocusLabs.-mobile.poi-container
{
	height: 100%;
}

.LocusLabs.-mobile .poi-container
{
	.button.-full
	{
		height: 42px;
	}
}*/

/* This is outside the poi-container because when it shows it is in place of the poi-container */
.LocusLabs .poi-showmap-return-header
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}