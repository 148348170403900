.LocusLabs
{
	.minimizeButton
	{
		color: white;
		width: 60px;
		height: 23px;
		padding: 0.5em;
		border-radius: 0 0 2px 2px;
		box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.5);
		position: absolute;
		left: calc(100% / 2 - 30px);
		bottom: -23px;

		> .icon
		{
			margin: 0;
		}
	}
}

.LocusLabs.-mobile
{
	.minimizeButton
	{
		display: none;
	}
}
