.LocusLabs .qrcode-dialog
{
	display: flex;
	flex-direction: column;

	> p
	{
		margin: 1em;
		text-align: center;

		&.secondaryText
		{
			font-weight: 300;
		}

		&.url
		{
			font-size: 0.8em;
			margin-bottom: 0;
		}
	}

	> .codeImage
	{
		position: relative;
		height: 160px;
		text-align: center;

		> .qrcode
		{
			background-blend-mode: lighten;
			background-repeat-x: no-repeat;
			background-repeat-y: no-repeat;
			width: 160px;
			height: 160px;
			margin: auto;
		}

		> .error
		{
			font-weight: normal;
			margin-top: 60px;
		}
	}
}