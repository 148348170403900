.browser-not-supported
{
	width: 100%;
	height: 100%;
	background-color: #333333;
	background-size: cover;
}

.accepted-browsers
{
	width: 413px;
	height: 360px;
	border-radius: 0.125em;
	background-color: #ffffff;
	margin: auto;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.warning-icon
{
	display: table;
	margin: 0 auto;
	padding-top: 40px;
}

.title-notSupported
{
	margin-top: 24px;
	margin-bottom: 24px;
	font-size: 22px;
	font-weight: 500;
	text-align: center;
	color: #333333;
}

.textbody
{
	display: table;
	margin: 0 auto;
	width: 333px;
	height: 133px;
	font-size: 16px;
	text-align: center;
	color: #535353;
}

.legacyLink
{
	display: table;
	margin: 1em auto;
	width: 333px;
	height: 133px;
	font-size: 14px;
	text-align: center;
	color: #535353;
}

a
{
	color: #139deb;
}