.search-categories
{
	width: 100%;
	flex: none;
	display: flex;
	overflow: hidden;
	flex-direction: column;

	.title
	{
		width: 100%;
		font-size: 0.875em;
		text-transform: uppercase;
		padding: 0.5em 0;
		padding-left: calc(2em + 30px);
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
	}

	.suggestionsList
	{
		flex: none;
		overflow-y: auto;
	}

	.suggestion
	{
		display: flex;
		align-items: center;
		padding: 1em;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		cursor: pointer;

		&:last-child
		{
			border: none;
		}

		> div.icon
		{
			margin-right: 1em;
			width: 30px;
		}
	}
}

.LocusLabs.-mobile .search-categories
{
	display: none;
}