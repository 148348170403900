.LocusLabs .search-entry
{
	padding: 0.5em;
	width: 100%;
	transition: all 250ms;
	flex: none;

	&.-active
	{
		border-bottom: 1px solid;
	}

	.searchInputField
	{
		border-width: 0;
		margin: 0.25em;
		outline: none;
		border-right-width: 1px;
		border-right-style: solid;
		border-radius: 0;
		flex: 1;

		&::placeholder
		{
			color: #808080; /* TODO: Make Themeable */
			font-style: italic;
		}
	}

	> .inner
	{
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 0.125em;

		> input
		{
			border-width: 0;
			margin: 0.25em;
			outline: none;
			border-right-width: 1px;
			border-right-style: solid;
			border-radius: 0;
			flex: 1;

			&::placeholder
			{
				color: #808080; /* TODO: Make Themeable */
				font-style: italic;
			}

			&::-ms-clear
			{
				display: none;
			} /* IE10+  */
		}

		> .icon
		{
			width: 1.875em;
			height: 1.875em;
			margin: 0.5em;
		}

		> .icon.getDirections
		{
			cursor: pointer;
		}
	}
}

.LocusLabs.-mobile .search-entry
{
	padding: 0;
	height: 50px;

	> .inner
	{
		justify-content: flex-end;

		> input
		{
			border-right: 0;
		}
	}
}

