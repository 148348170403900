.zoom-selectorContainer
{
	height: 40px;
	border-radius: 2px;
	position: absolute;
	bottom: 2em;
	left: calc(50% - 258px / 2);
	display: inline-flex;
	z-index: 10;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);

	> .icon
	{
		flex: none;
		width: 47px;
	}

	.zoom-selector
	{
		height: 40px;
		display: inline-flex;
		width: 164px;

		input
		{
			-webkit-appearance: none;
			height: 4px;
			width: 100%;
			margin: auto;

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
			{
				width: 110px;
				padding: 10px;
				height: 40px;
			}
		}

		input:focus
		{
			outline: none;
		}

		/* Webkit */

		input[type=range]::-webkit-slider-thumb
		{
			border: none;
			height: 16px;
			width: 16px;
			margin-top: -5.333px;
			border-radius: 100%;
			background: #333333;
			-webkit-appearance: none;
		}

		input::-webkit-slider-runnable-track
		{
			border-radius: 0;
			height: 4px;
			cursor: pointer;
			background: #cccccc;
		}

		/* MOZ */

		input[type=range]::-moz-range-thumb
		{
			border: none;
			height: 16px;
			width: 16px;
			margin-top: -5.333px;
			border-radius: 100%;
			background: #333333;
		}

		input[type=range]::-moz-range-track
		{
			border-radius: 0;
			height: 4px;
			cursor: pointer;
			background: #cccccc;
		}

		input[type=range]::-moz-focus-outer
		{
			border: 0;
		}

		/* MSIE */

		input[type=range]::-ms-thumb
		{
			border: none;
			height: 16px;
			width: 16px;
			margin-top: 0;
			border-radius: 100%;
			background: #333333;
		}

		input[type=range]::-ms-track
		{
			border-radius: 0;
			height: 4px;
			cursor: pointer;
			width: 300px;
			border: 0;
			background: transparent;
			color: transparent;
		}

		input[type=range]::-ms-fill-lower
		{
			background: #999999;
			border-radius: 10px;
		}

		input[type=range]::-ms-fill-upper
		{
			background: #999999;
			border-radius: 10px;
		}

		input[type=range]::-ms-tooltip
		{
			display: none;
		}

		> .icon
		{
			margin: 4px 2px 0;
			cursor: pointer;
			flex: none;
		}
	}
}

.LocusLabs .zoom-related-mobile-icons
{
	> .reposition
	{
		/* midpoint should match .level-selectorTrigger_mobile (33px) */
		width: 50px;
		height: 50px;
		right: 8px;
		position: absolute;
		bottom: 80px;
		border-radius: 99px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);

		> svg
		{
			width: 24px;
			height: 24px;
			margin: auto;
		}
	}

	> .control-icon-compassMobile
	{
		position: absolute;
		bottom: 16px;
		left: 8px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
		border-radius: 15px;
	}
}

.move-icon-up
{
	bottom: 8em;
}

.LocusLabs .control-icon-compass
{
	height: 47px;
	width: 47px;
	margin: 0;
	top: -3px;
	position: relative;
}

body.-LLfullpage .LocusLabs .zoom-related-mobile-icons
{
	> .reposition,
	> .control-icon-compassMobile
	{
		position: fixed;
	}
}