.LocusLabs .onsite-admin
{
	.-floatingContainer.-dialog
	{
		left: 50%;
		top: 20%;
		margin-left: -200px;
		width: 400px;
		padding: 3em;
	}

	.dialog-entry-field
	{
		label
		{
			text-transform: uppercase;
			font-size: 0.65em;
			font-weight: 500;
			display: block;
			margin-bottom: 0.25em;
		}

		.input-wrapper
		{
			display: flex;
			justify-content: flex-start;
			border-width: 1px;
			border-style: solid;
			border-radius: 2px;

			.icon
			{
				padding: 4px 6px;
			}

			.keyboard-input
			{
				display: flex;
				width: 100%;
			}

			input
			{
				border: none;
				width: 100%;
				background: transparent;
				outline: none;
				padding: 4px 8px;
			}
		}
	}

	.exit-button,
	.back-button
	{
		position: absolute;
		bottom: 1em;
		left: 1em;
		z-index: 10;
	}

	.button
	{
		display: flex;
		margin-top: 1em;
		padding: 0.5em 1em;
		height: 48px;
		font-size: 1em; /* Override .LocusLabs .button */
		font-weight: 700;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
		align-items: center;
		text-rendering: geometricPrecision;
	}

	.editDeleteButtons
	{
		position: absolute;
		bottom: 1em;
		right: 1em;
		z-index: 10;
		display: flex;

		> div.delete-button
		{
			margin-left: 1em;
			background: #dc4747;
		}
	}

	.directions
	{
		top: 1em;
		left: 1em;
		width: 400px;
		padding: 1.5em;

		.title
		{
			font-size: 1.5em;
			font-weight: 900;
			margin-bottom: 0.75em;
		}

		.body
		{
			line-height: 1.7;
		}
	}

	/* .mapMask
	{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 0, 0, 0.2);
		pointer-events: auto;
		z-index: 5;
	} */

	.kioskPin
	{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: calc(225px / -2);
		margin-top: -104px;
		z-index: 20;
		height: 44px;

		> .icon
		{
			pointer-events: none;
		}
	}
}