.LocusLabs .navigation-dialog
{
	display: flex;
	flex-direction: column;
	padding-bottom: 4px;

	.use-current-location-div
	{
		.use-current-location
		{
			display: flex;
			align-items: center;
			margin: 1em;
			justify-content: center;

			.icon
			{
				padding: 0.375em;
				width: 30px;
				height: 30px;
			}

			.text-label
			{
				font-size: 0.750em;
			}
		}
	}

	.icon.global-icon-close
	{
		padding-right: 0.5em;
	}

	.navigation-title
	{
		width: 100%;
		display: flex;
		flex-direction: row;
		padding: 1em;
		align-items: center;
		flex: none;

		> .title
		{
			flex: 1;
			font-size: 1.125em;
			font-weight: 500;
			margin-left: 1em;
		}

		> .icon
		{
			&:first-child
			{
				border-radius: 0.125em;

				svg path
				{
					fill: var(--white);
				}
			}
		}
	}

	.scrolling-container
	{
		flex-grow: 1;
		overflow: auto;
		max-height: 40vh;
		/* Wish we could uncomment the following line, as it gives us nice momentum scrolling */
		/* but it acts badly when you overscroll... :-(  */
		/* -webkit-overflow-scrolling: touch; */
	}

	.routeSelection
	{
		display: flex;
		flex: none;

		> div
		{
			flex: 1;
			margin: 1em;
			padding: 0.75em;
			border-radius: 999px;
			display: flex;
			justify-content: center;

			> .icon
			{
				margin-right: 0.5em;
			}

			.textLabel
			{
				position: relative;
				top: 0.2em;
			}
		}
	}

	.summary
	{
		padding: 1em;
		display: flex;
		align-items: center;
		flex: none;

		> .icon
		{
			width: 30px;
		}

		> .textLabel
		{
			margin-left: 1.14em;
			font-size: 0.875em;

			> .timeAndFloors
			{
				font-style: italic;

				> .time
				{
					font-style: normal;
					font-size: 1.143em;	/* return to 16px */
					font-weight: 500;
					margin-right: 0.5em;
				}
			}
		}
	}

	.prevNext
	{
		display: flex;
		flex: none;
		align-items: center;
		text-transform: uppercase;
		border-radius: 0 !important;
		padding: 0 !important;

		> div
		{
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.25em;
			cursor: pointer;

			&:first-child
			{
				border-right: 1px solid;
			}

			> .icon
			{
				margin: 0.5em;
			}

			&.-ghosted
			{
				cursor: default;
			}
		}
	}

	.icon.dir-vertical-line svg
	{
		width: 4px;
		height: 10px;
	}

	.resetSearch
	{
		padding: 0em 2em 1.5em 1em; /* This helps center the icon/text combo */
	}

	.pois-selected
	{
		flex: none;

		> .poi
		{
			display: flex;
			align-items: center;
			padding: 1em;

			> div.icon
			{
				margin-right: 1em;
				width: 30px;

				&._pull-right
				{
					margin-right: 0;
				}
			}

			> .textLabel > .title
			{
				font-weight: 500;
				font-size: 1em;
			}

			> .textLabel > .desc
			{
				font-size: 0.85em;
			}
		}
	}

	.computingNotification
	{
		position: relative;
		height: 180px;
		display: flex;
		flex-direction: column;

		> .loadingIndicatorWrapper
		{
			flex-direction: column;
			position: static;
			background-color: inherit;

			> .icon
			{
				margin-bottom: 0;
			}

			> .textMessage
			{
				background-color: inherit;
				text-align: center;
				padding: 20px 0 40px;
				font-size: 0.75em;
				flex: 1;
				width: auto;
			}
		}
	}

	/* Helper classes - see http://rscss.io/helpers.html */
	._pull-right
	{
		margin-left: auto;
	}

	._pull-left
	{
		margin-left: 4.000em;
		justify-content: space-between !important;
	}
}

.LocusLabs.-mobile .navigation-dialog
{
	padding: 0;

	&.-mobileFull
	{
		pointer-events: none;

		> .navigation-stepByStepMobile,
		> .navigation-entry
		{
			pointer-events: all;
		}
	}

	.summary
	{
		top: 0;
		position: absolute;
		width: 100%;
		padding: 0;

		> .icon
		{
			flex: none;
			margin: 10px 16px;
		}

		> .summary-info
		{
			flex: 1;
			overflow: hidden;

			> span
			{
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.title
		{
			display: block;
			font-weight: 500;
		}

		.subtitle
		{
			display: block;
			font-size: 0.75em;
			font-style: italic;
			color: #666666;
		}
	}
}

/* Note: move this to its own file! */
.LocusLabs .navigation-stepByStep
{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;

	> .transferButton
	{
		padding: 0.5em;
	}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
	{
		max-height: 50vh;
	}
}