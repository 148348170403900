.LocusLabs .navigation-entry
{
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
	{
		height: 80vh;
	} /* IE10+ */

	.dividerLine
	{
		position: absolute;
		width: 1px;
		height: 52px;
		border-right: 1px solid currentColor;
		top: 46px;
		left: 15px;
	}

	> .navInputs-section
	{
		padding: 12px;
		flex: none;

		> .navInputs-top
		{
			display: flex;
			align-items: center;
			position: relative;

			> div
			{
				flex: 1;
			}

			.navInputs-swap
			{
				flex: none;
				margin: 0 0.5em;
			}
		}
	}
}

.LocusLabs.-mobile .navigation-entry
{
	overflow: visible;
	flex: 1;

	> .navInputs-section
	{
		padding: 0 0 8px 0;

		.dividerLine
		{
			top: 36px;
			height: 22px;
		}

		.navInputs-swap
		{
			> .icon
			{
				padding-bottom: 20px;
			}

			> .dir-icon-swap
			{
				padding-bottom: 10px;
			}
		}
	}
}