.LocusLabs .poi-details
{
	transition: all 200ms;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	flex-grow: 1;
	width: 100%;

	/* IE only */
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
	{
		max-height: 70vh;
	}

	.poi-header-layout
	{
		margin-left: calc(2em + 30px);

		> * { margin: 0.25em 0; }
		> .iconInfo > .icon { width: 14px; margin-right: 0.5em; }
		> .iconInfo > .icon.poi-icon-walking, > .iconInfo > .icon.poi-icon-bus /* These were sized wrong? */
			 { transform: scale(1.5); }
	}

	.iconInfo
	{
		display: flex;
		align-items: center;

		> .info
		{
			flex: 1;
			font-weight: normal;
			overflow: hidden;
			text-overflow: ellipsis;

			> div
			{
				display: flex;
				transition-property: opacity, max-height, visibility;
				transition-duration: 0.2s;
			}

			> div:first-child
			{
				max-height: 100vh;
				opacity: 1;
				visibility: visible;
			}

			> div:last-child
			{
				max-height: 0;
				opacity: 0;
				visibility: hidden;
			}

			&.-active
			{
				& > div:first-child
				{
					max-height: 0;
					opacity: 0;
					visibility: hidden;
					position: absolute;
				}

				& > div:last-child
				{
					max-height: 100vh;
					opacity: 1;
					visibility: visible;
				}
			}

			& ul
			{
				width: 100%;
				list-style: none;
				padding: 0;
				margin: 0;

				& li
				{
					font-size: 0.875em;
					padding: 6px 0;

					&.-current
					{
						font-weight: bold;
					}
				}
			}

			& .operating-hours-list-view
			{
				display: flex;

				.icon.poi-icon-openinghours
				{
					width: 30px;
					text-align: left;
					margin-right: 1em;
					vertical-align: top;
					height: 30px;
				}

				& span:nth-child(even)
				{
					float: right;
					font-size: 1.144em;
				}

				& .operating-hours-list-view > ul
				{
					flex: 15;
					padding: 0;
					margin: 0;
				}
			}

			& .operating-hours-header
			{
				display: flex;

				div:last-child
				{
					width: 0;
					height: 0;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 8px solid;
					margin: auto;
				}

				& > .icon.poi-icon-openinghours
				{
					width: 30px;
					text-align: left;
					margin-right: 1em;
					vertical-align: top;
					height: 30px;
				}

				& span
				{
					flex: 3;
					line-height: 1.875;
				}
			}
		}

		> .icon
		{
			width: 30px;
			text-align: left;
			margin-right: 1em;
		}

		&:first-child
		{
			margin-top: 0;
		}
	}

	> .primaryInfo
	{
		padding: 0.5rem 1em;

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
		{
			width: 376px;
		}

		> div
		{
			width: 100%;
			margin: 0.5rem 0;
		} /* All flex items within the POI first level should be full width */

		> .iconInfoGroup
		{
			font-size: 0.875em;

			> .iconInfo
			{
				padding: 0.5em 0;
			}
		}

		> .-secondary
		{
			border-width: 1px;
			border-style: solid;
		}

		> .description
		{
			white-space: pre-wrap;
			line-height: 1.5;
		}

		> .amenitiesWrapper
		{
			padding: 1em;

			> .amenities
			{
				border-radius: 0.375em;
				padding: 1em;

				> .title
				{
					text-transform: uppercase;
					font-size: 0.875em;
					font-weight: 500;
				}

				.icon svg
				{
					width: 1.4em;
					height: 1.4em;
				}
			}
		}
	}

	> .information
	{
		> .informationTitle
		{
			font-size: 0.750em;
			padding: 0.42em 0 0.42em calc(2rem + 30px);
			text-transform: uppercase;
			letter-spacing: 0.4px;
		}

		> .iconInfo
		{
			border-bottom-style: solid;
			border-bottom-width: 1px;
			padding: 1em;

			&:last-child
			{
				border: none;
			}

			a
			{
				text-decoration: none;

				&:hover
				{
					text-decoration: underline;
				}
			}
		}
	}

	.live-container
	{
		width: 100%;
		height: 40px;
		color: white;
		font-weight: 500;
		letter-spacing: 0.1px;
		text-align: center;
		padding: 10px 0;
		line-height: 1.2;
		text-transform: uppercase;
		display: flex;
	}

	.bg-transfer-button
	{
		background-color: rgba(0, 33, 87, 0.7);
		box-shadow: 0 2px 24px 0 rgba(255, 255, 255, 0.5);
		border-radius: 2px;
		display: flex;
		justify-content: center;
		height: 42px;
		text-transform: uppercase;
	}

	.bg-transfer-button:hover
	{
		color: #ffffff;
		background: #0d263a;
	}

	.transferButton
	{
		padding: 0 1em 0.5rem 1em;

		.text-label
		{
			padding-top: 13px;
			padding-left: 5px;
			font-weight: 500;
			font-size: 0.875em;
			text-align: center;
			color: #ffffff;
			text-transform: uppercase;
		}

		> .button
		{
			padding: 0.550em 2em 0.950em 1em; /* This helps center the icon/text combo */

			> .icon
			{
				display: inline-block;
				margin-right: 0.5em;
				position: relative;
			}
		}
	}

	.security-time
	{
		font-size: 0.6875em;
		font-weight: 400;
		text-align: center;
		color: #ffffff;
		border-radius: 100px;
		background-color: #ea3232;
		display: inline-block;
		padding: 1px 8px;
		margin-right: 5px;
	}

	.subtitle-security-mobile
	{
		font-size: 0.75em;
		font-style: italic;
		color: #666666;
		display: inline-block;
		margin-right: 5px;
	}
}

.LocusLabs.-mobile .poi-details > div.primaryInfo > div.description
{
	font-size: 1.125em;
}