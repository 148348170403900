.LocusLabs .level-selector
{
	position: absolute;
	right: 16px;
	top: 85px;
	max-height: calc(100% - 110px - 24px);
	width: 180px;
	display: flex;
	flex-direction: column;
	backdrop-filter: blur(10px);
	overflow: hidden; /* This gives us or border-radius.. */

	> .areaGroups
	{
		overflow-y: auto;

		> .areaGroupWrapper
		{
			display: block;
			border-bottom-width: 1px;
			border-bottom-style: solid;

			> .areaGroup
			{
				display: block;
				align-items: center;
				padding: 8px 8px 8px 12px;
				width: 100%;
				cursor: pointer;

				> .icon
				{
					margin-right: 1em;
				}

				> .textLabel
				{
					flex: 1;
					min-width: 0; /* Allows widths to shrink less than text. see https://css-tricks.com/flexbox-truncated-text/ */
					> .name
					{
						font-weight: 400;
						line-height: 1.4;
					}

					> div
					{
						line-height: 1.2;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				@media screen and (max-width: 900px)
				{
					display: flex;
				}
			}

			&:last-child
			{
				border-bottom: none;
			}

			> .area
			{
				opacity: 0.95;
				align-items: center;
				justify-content: space-between;
				padding: 8px 8px 8px 12px;
				width: 100%;
				display: none;
				cursor: pointer;

				> .textLabel
				{
					> .name
					{
						font-weight: 400;
						margin-bottom: 2px;
					}

					> .title
					{
						font-size: 0.875em;
						font-weight: 300;
					}

					> div
					{
						line-height: 1.2;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				@media screen and (max-width: 900px)
				{
					display: flex !important;
				}
			}
		}

		> .areaGroupWrapper.-selected > .area
		{
			display: block;
		}
	}
}

.LocusLabs.-mobile .level-selector
{
	position: absolute;
	right: auto;
	left: 0;
	top: auto;
	bottom: 0;
	width: 100%;
	max-height: 100%;
	border-radius: 0;

	> .mask-mobile
	{
		height: 100vh;
		width: 100vw;
		opacity: 0.65;
		background-color: #333333;
		top: -100vh;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: 1;
		margin: 0;
	}

	> .closeMobile
	{
		padding: 5.5px;
	}

	> .areaGroups > .areaGroupWrapper > .area,
	> .areaGroups > .areaGroupWrapper > .areaGroup
	{
		height: 62px;
	}

	> .areaGroups > .areaGroupWrapper > .area > .textLabel > .name
	{
		line-height: 1.286;
	}
}

body.-LLfullpage .LocusLabs.-mobile
{
	.level-selector
	{
		position: fixed;

		> .mask-mobile
		{
			position: fixed;
		}
	}
}

