.poi-title
{
	transition: all 200ms;
	display: flex;
	flex: none;
	width: 100%;
	align-items: center;
	height: 50px;

	> .title
	{
		flex: 1;
		font-weight: 500;
	}

	> .icon
	{
		padding: 1em;
	}
}
